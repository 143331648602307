import React from 'react';
import styles from './Input.module.css';
import clsx from 'clsx';
const Input = (props: React.InputHTMLAttributes<HTMLInputElement>) => {
	return (
		<input
			{...props}
			className={clsx(styles.input, props.className)}
		/>
	);
};

export default Input;
