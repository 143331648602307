import React from 'react';
import { useState, useEffect } from 'react';
import { useLoaderData, useLocation } from 'react-router-dom';
import { API } from '../../config/config.tsx';

import AdminLyout from '../../layout/_admin/AdminLayout.tsx';
import { fn_axios } from '../../common/common.tsx';

function CodeManageDetail() {
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const codeMCd = queryParams.get('codeMCd');

	const [codeLList, setCodeLList] = useState([]);
	const [selectCode, setSelectCode] = useState(null);
	const [pageNum, setPageNum] = useState(() => {
		const page = queryParams.get('pageNum');
		return page ? parseInt(page) : 1;
	});
	const [pageCnt] = useState(10);
	const [pageMaxNum, setPageMaxNum] = useState(0);
	const [paging, setPaging] = useState([]);
	const [codeCnt, setCodeCnt] = useState(null);

	const getCodeDetail = async (num: number) => {
		const params = {
			method: 'GET',
			url: `${API.BACKEND}/${API.VERSION}/admin/getCodeDetail`,
			params: { pageNum: num, selectCode: codeMCd },
		};
		fn_axios(params, callBackGetCodeDtail, null);
	};

	const callBackGetCodeDtail = (res) => {
		setCodeLList(res.data.object);
		setCodeCnt(res.data.map.codeLCnt);
		var tempInt = parseInt(res.data.map.codeLCnt % pageCnt);
		if (tempInt != 0) {
			tempInt = parseInt(res.data.map.codeLCnt / pageCnt) + 1;
		} else {
			tempInt = parseInt(res.data.map.codeLCnt / pageCnt);
		}
		settingPasing(tempInt);
	};
	const settingPasing = (maxNum) => {
		setPageMaxNum(parseInt(maxNum));
		// var start = (pageNum > 2 ? ( (maxNum - pageNum > 4) ? pageNum - 2 : maxNum - pageNum) : 1);
		var start =
			maxNum <= 5 || pageNum <= 2
				? 1
				: pageNum - (maxNum - pageNum <= 2 ? 4 - (maxNum - pageNum) : 2);
		var end =
			maxNum <= 5 || maxNum - pageNum <= 2
				? maxNum
				: (pageNum < 3 ? 3 : pageNum) + 2;
		var tempList = [];

		if (maxNum != 0) {
			for (var i = start; i <= end; i++) {
				tempList.push(i);
			}
		}
		setPaging(tempList);
	};

	const handleCheck = (code) => {
		console.log(code);
		if (selectCode === code) {
			setSelectCode(null);
		} else {
			setSelectCode(code);
		}
	};

	const handleUpdateClick = () => {
		if (!selectCode) {
			alert('수정할 코드를 선택해주세요.');
			return;
		}
		window.location.href = `/admin/codeMngDetailUpdate?codeMCd=${codeMCd}&codeLCd=${selectCode.codeLCd}`;
	};
	const handleInsertClick = () => {
		window.location.href = `/admin/codeMngDetailInsert?codeMCd=${codeMCd}`;
	};

	const backMain = () => {
		window.location.href = '/admin/codeMng';
	};

	useEffect(() => {
		getCodeDetail(pageNum);
	}, [pageNum]);

	return (
		<AdminLyout>
			<div>
				<h1 className="admin_title">코드상세</h1>
				<section className="admin_list">
					<div className="admin_bar">
						<div className="admin_total">
							{' '}
							총 <b>{codeCnt == null ? '0' : codeCnt}</b> 건{' '}
						</div>
					</div>
					<div
						className="admin_table type1"
						style={{ overflow: 'auto' }}
					>
						<table
							style={{
								borderCollapse: 'collapse',
								width: '100%',
								tableLayout: 'auto',
							}}
						>
							<thead>
								<tr>
									<th>선택</th>
									<th>코드내역</th>
									<th>코드내역명</th>
									<th>코드내역설명</th>
									<th>등록일시</th>
									<th>수정일시</th>
								</tr>
							</thead>
							<tbody>
								{codeLList.length === 0 ? (
									<tr>
										<td
											style={{ textAlign: 'center' }}
											colSpan={6}
										>
											검색된 결과가 없습니다.
										</td>
									</tr>
								) : (
									codeLList.map((item, index) => (
										<tr
											style={{ cursor: 'pointer' }}
											key={index}
										>
											<td>
												<input
													type="checkbox"
													checked={selectCode === item}
													onChange={() => handleCheck(item)}
												/>
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.codeLCd}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.codeLNm}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.codeLDesc}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.regrDtm}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.updateDtm}
											</td>
										</tr>
									))
								)}
							</tbody>
						</table>
					</div>
					<div className="paging">
						{paging.map((num) => (
							<a
								key={num}
								onClick={() => setPageNum(num)}
							>
								{num}
							</a>
						))}
					</div>
				</section>
				<div className="admin_table_btn">
					<button
						className="admin_back_btn"
						onClick={backMain}
					>
						목록으로
					</button>
					<button
						className="admin_update_btn"
						onClick={handleUpdateClick}
					>
						수정
					</button>
					<button
						className="admin_insert_btn"
						onClick={handleInsertClick}
					>
						등록
					</button>
				</div>
			</div>
		</AdminLyout>
	);
}

export default CodeManageDetail;
