import React from 'react';
import Layout from '../../layout/Layout.tsx';
import Slider from '../../layout/Slider.tsx';
import { fn_axios, getCookieUserId, logout } from '../../common/common.tsx';
import { API } from '../../config/config.tsx';

import style from './UserWithdraw.module.css';
import Button from '../../common/Button/Button.tsx';
import ArrowRight from '../../common/Icons/ArrowRight.tsx';

function UserWithdraw() {

    // "예" 누를 시
    const memberStateUpdate = () => {
        if (window.confirm("정말 탈퇴하시겠습니까?")) {
            console.log("탈퇴 진행 중...");

            const param = {
                method: 'POST',
                url: `${API.BACKEND}/${API.VERSION}/user/userStateDivsCdUpdate`,
                params: {
                    userId: getCookieUserId() // 탈퇴 하려는 사용자ID 세션에서 가져오기
                }
            }
            fn_axios(param, callBackUserStateDivsUpdate, (err) => {
                alert("회원 탈퇴 중 에러가 발생했습니다.");
                console.error("회원 탈퇴 실패 : ", err);
            });
        }
    }
    const callBackUserStateDivsUpdate = (res) => {
        if (res.data.success) {
            alert("회원탈퇴가 완료되었습니다.");
            logout();
        } else {
            alert("회원 탈퇴 중 문제가 발생했습니다. 다시 시도해주세요.");
            console.error("회원탈퇴 실패 응답 : ", res);
        }
    }

    // "아니요" 누를 시 마이페이지로 이동
    const backPage = () => {
        window.location.href = '/user/mypage';
    }

    return (
        <Layout>
                <section className={style.container}>
                    <div className={style.content}>
                        <div className={style.text_wrapper}>
                            <h1 className={style.title}>정말 회원 탈퇴 하시겠습니까?</h1>
                            <p className={style.description}>
                                회원 탈퇴 후에는 우리그라운드 서비스 이용이 제한되며,
                                같은 아이디로 재가입할 수 없습니다.
                            </p>
                        </div>
                        <div className={style.button_container}>
                            <Button 
                                variant="black"
                                onClick={memberStateUpdate}
                                suffixIcon={<ArrowRight className={style.button_icon} />}
                                weight="bold"
                            >
                                예
                            </Button>
                            <Button
                                variant="white"
                                onClick={backPage}
                                suffixIcon={<ArrowRight className={style.button_icon} />}
                                weight="bold"
                            >
                                아니요
                            </Button>
                        </div>
                    </div>
                </section>
        </Layout>
    )
}

export default UserWithdraw;