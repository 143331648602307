import React, { useEffect, useState } from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import styles from './MngMain.module.css';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';
import moment from "moment";

import dummy from './dummy.json';
import { API } from '../../../config/config.tsx';
import { fn_axios, getCookieUserId } from '../../../common/common.tsx';
import { useLocation } from 'react-router-dom';
const MngMain = ({ propsCmptLId }) => {
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const [cmptLId, setCmptLId] = useState(queryParams.get('cmptLId') ? queryParams.get('cmptLId') : propsCmptLId);

	const [reqRsltCdList, setReqRsltCdList] = useState([]);

	const [cmptGameInfo, setCmptGameInfo] = useState(null);
	const [cmptManageList, setCmptManageList] = useState([]);
	const [cntVo, setCntVo] = useState(null);

	const [pageNum, setPageNum] = useState((queryParams.get('pageNum') == null || queryParams.get('pageNum') == "") ? 1 : parseInt(queryParams.get('pageNum')));
	const [pageMaxNum, setPageMaxNum] = useState(0);
	const [pageCnt, setPageCnt] = useState(10);
	const [paging, setPaging] = useState([]);

	const [selectedReqRsltCd, setSelectedReqRsltCd] = useState('');
	const [selectedOrdColumn, setSelectedOrdColumn] = useState('');

	// 검색 조건
	const handleReqRsltCd = (e) => {
		setSelectedReqRsltCd(e.target.value);
	}

	// 코드 조회
  const getCode = () => {
	const param = {
	  method: 'GET',
	  url: `${API.BACKEND}/${API.VERSION}/code/getCode`,
	  params: {
		codeMCd: "REQ_RSLT_CD" // 체육관 종류 코드
		, orderColum: "code_l_nm"
	  }
	};
	fn_axios(param, callbackGetCode, null);
  }
  const callbackGetCode = (res) => {
	setReqRsltCdList(res.data.object);
  }

	// 대회관리자 대회 기본 정보
	const getCmptManageGame = (lId) => {
		const param = {
			method: 'GET',
			url: `${API.BACKEND}/${API.VERSION}/mng/getCmptMangeGame`,
			params: {
				cmptLId: lId
			}
		};
		fn_axios(param, callBackGetCmptManageGame, null);
	}
	const callBackGetCmptManageGame = (res) => {
		setCmptGameInfo(res.data.object);
		console.log(res);
	}

	// 대회 참가명단 관리
	const getCmptManagePaging = (num, lId) => {
		const param = {
			method: 'GET',
			url: `${API.BACKEND}/${API.VERSION}/mng/getCmptManagePaging`,
			params: {
				pageNum: num
				, cmptLId: lId
			}
		};
		fn_axios(param, callBackGetCmptManagePaging, null);
	}
	const callBackGetCmptManagePaging = (res) => {
		setCmptManageList(res.data.object);
		setCntVo(res.data.map.cntVo);
		console.log(res.data);
		var tempInt = parseInt(res.data.map.cntVo.cnt % pageCnt);
		if (tempInt != 0) {
		  tempInt = parseInt(res.data.map.cntVo.cnt / pageCnt) + 1;
		} else {
		  tempInt = parseInt(res.data.map.cntVo.cnt / pageCnt);
		}
		settingPasing(tempInt);
	  }

	const settingPasing = (maxNum) => {
		setPageMaxNum(parseInt(maxNum));
		var start = (maxNum <= 5 || pageNum <= 2) ? 1 : pageNum - (maxNum - pageNum <= 2 ? (4 - (maxNum - pageNum)) : 2);
		var end = (maxNum <= 5 || maxNum - pageNum <= 2) ? maxNum : (pageNum < 3 ? 3 : pageNum) + 2;
		var tempList = [];
	
		if (maxNum != 0) {
		  for (var i = start; i <= end; i++) {
			tempList.push(i);
		  }
		}
		setPaging(tempList);
	  }
	  const onClickPaging = (e, page) => {
		e.preventDefault(); // 페이지 번호 클릭시 refresh 방지
		setPageNum(parseInt(e.target.text));
		return null;
	  }

	  const buildDetails = (item) => {
		let result = [];
	
		for (let i = 0; i < 10; i++) {
			const name = item[`gameDivsNm${i}`];
			if (!name || name === '티셔츠 사이즈') continue;
			const value =
				item[`cmptReqGameDivsValue${i}`] ??
				((item[`gameClsfKindCd${i}`] === 'GAME_CLSF_KIND_CD_05' || item[`gameClsfKindCd${i}`] === 'GAME_CLSF_KIND_CD_06')
					? ''
					: item[`gameDivsLValue${i}`]);
			if (value) result.push(`${name}: ${value}`);
		}
	
		return result.join(' / ');
	};

	useEffect(() => {
		if (cmptLId) {
			getCmptManageGame(cmptLId);
			getCmptManagePaging(pageNum,cmptLId);
		} else {
			if (getCookieUserId() === 21 || getCookieUserId() === 2207 || getCookieUserId() === 301) {
				setCmptLId('240926_GAME_KIND_0075_000001_0001');
				getCmptManageGame('240215_GAME_KIND_0020_000001_0001');
			}
			if (getCookieUserId() === 21 || getCookieUserId() === 912 || getCookieUserId() === 322 || getCookieUserId() === 301) {
				// setCmptLId('240318_GAME_KIND_0004_000001_0001');
				setCmptLId('240821_GAME_KIND_0016_000001_0001');
				// setCmptLId('240318_GAME_KIND_0004_000001_0001');
			} else if (getCookieUserId() === 21 || getCookieUserId() === 308 || getCookieUserId() === 301) {
				// setCmptLId('240318_GAME_KIND_0004_000001_0001');
				setCmptLId('240215_GAME_KIND_0020_000002_0118');
				// setCmptLId('240318_GAME_KIND_0004_000001_0001');
			} else if (getCookieUserId() === 300 || getCookieUserId() === 301) {
				setCmptLId('240321_GAME_KIND_0004_000002_0001');
			}
		}
	}, [pageNum]);

	return (
		<div className={styles.content}>
			<MyCompetitionHeader title="내가 등록한 대회" />
			<div className={styles.competition_detail}>
				<img
					src={
						'https://s3-alpha-sig.figma.com/img/af8a/0b79/8d2343e80b525d974c4815d0420c061a?Expires=1740355200&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=HOKeXpr8tt7fiL01M3LHdh0JQohkG6Wmvbpj2En8b64GFPHMBJWALePAS82IuejP216HQmZ0GmFN68ftNHiZrP0GP-8VBlVTj~pJQyI4Tp9Fls2WvX18zhm9VkqC0LJZpJaHBce~C-uwKWRirmjzI2UMtJABPVVbQGn3DRGBFN~HyftbnmZpDUmbKJ4M~4yfL~8yazyRxsmr8ykz15BWB-gs0zqPv0kyCEPWv8l7vrQJqfzcBnYCcIYtGQ5sTkjz9L0fZu8-Jg1WFX5B1CsY57HbphzuHQf7hTtb0oaFl5Ed0~OJd01gJqmQ9e7cH4achKrro6kjAmvZZttF11W0tA__'
					}
					alt=""
					className={styles.competition_thumbnail}
				/>
				<div className={styles.competition_info}>
					<div className={styles.competition_info_title_box}>
						<p className={styles.competition_info_title}>
							{/* 제31회 국민생활체육전국배드민턴대회 */}
							{cmptGameInfo ? cmptGameInfo.cmptLNm : "대회 정보 불러오는 중..."}
						</p>
						<button className={styles.action_button}>+대회내역 관리</button>
					</div>
					<div className={styles.competition_info_list}>
						<div className={styles.competition_info_item_box}>
							<p className={styles.competition_info_item_key}>일자 :</p>
							<p className={styles.competition_info_item_value}>
								{/* 2024년 3월 14일 ~ 2024년 3월 15일 */}
								{cmptGameInfo ? `${cmptGameInfo.cmptStrDt} ~ ${cmptGameInfo.cmptEndDt}` : "정보 없음"}
							</p>
						</div>
						<div className={styles.competition_info_item_box}>
							<p className={styles.competition_info_item_key}>장소 :</p>
							<p className={styles.competition_info_item_value}>
								{/* 경상북도 경산시 일원 */}
								{cmptGameInfo ? `${cmptGameInfo.spSignguNm} ${cmptGameInfo.signguNm}` : "정보 없음"}
							</p>
						</div>
						<div className={styles.competition_info_item_box}>
							<p className={styles.competition_info_item_key}>기간 :</p>
							<p className={styles.competition_info_item_value}>
								{/* 2024년 2월 3일 ~ 2024년 3월 3일 (D-31) */}
								{cmptGameInfo ? `${cmptGameInfo.cmptReqStrDtm} ~ ${cmptGameInfo.cmptReqEndDtm}` : "정보 없음"}
								(D-{cmptGameInfo ? `${cmptGameInfo.cmptReqDays}` : "계산 중"})
							</p>
						</div>
						<div className={styles.competition_info_item_box}>
							<p className={styles.competition_info_item_key}>접수 :</p>
							<p className={styles.competition_info_item_value}>
								{/* 2024년 2월 3일 ~ 2024년 3월 3일 (D-21) */}
								{cmptGameInfo ? `${cmptGameInfo.cmptReqStrDtm} ~ ${cmptGameInfo.cmptReqEndDtm}` : "정보 없음"}
								(D-{cmptGameInfo ? `${cmptGameInfo.cmptReqDays}` : "계산 중"})
							</p>
						</div>
						<div className={styles.competition_info_item_box}>
							<p className={styles.competition_info_item_key}>금액 :</p>
							<p className={styles.competition_info_item_value}>
								{cmptGameInfo ? Number(cmptGameInfo.allPrzMoney).toLocaleString() : "정보 없음"} 원
							</p>
						</div>
						<div className={styles.competition_info_item_box}>
							<p className={styles.competition_info_item_key}>인원 :</p>
							<p className={styles.competition_info_item_value}>
								{/* 신청인원 1,092 / 1,000 명 / 참가인원 1,000 명(500팀) */}
								신청인원 {cmptGameInfo ? `${cmptGameInfo.enterUser}` : "정보 없음"}
								/ {cmptGameInfo ? `${cmptGameInfo.maxEnterPerson} 명` : "정보 없음"}
								/ 참가인원 {cmptGameInfo ? `${cmptGameInfo.maxEnterPerson} 명` : "정보 없음"}
								({cmptGameInfo ? `${cmptGameInfo.maxEnterTeamNum}팀` : "정보없음"})
							</p>
						</div>
						<div className={styles.competition_info_item_box}>
							<p className={styles.competition_info_item_key}>결재 :</p>
							<p className={styles.competition_info_item_value}>
								{/* 결재완료 546명 - 결재취소 46명 = 최종 결재확인 500명 */}
								결제 완료 {cmptGameInfo ? `${cmptGameInfo.payCount} 명` : "정보 없음"}
								- 결제 취소 {cmptGameInfo ? `${cmptGameInfo.payCnclCount} 명` : "정보 없음"}
								= 최종 결제 확인 {cmptGameInfo ? `${Math.max(0, cmptGameInfo.payCount - cmptGameInfo.payCnclCount)} 명` : "정보 없음"}
							</p>
						</div>
						<div className={styles.competition_info_item_box}>
							<p className={styles.competition_info_item_key}>참여 :</p>
							<p className={styles.competition_info_item_value}>
								{/* 조회수 1,891 / 좋아요 812 */}
								조회수 {cmptGameInfo ? Number(cmptGameInfo.slctNum).toLocaleString() : "정보 없음"}
								/ 좋아요 812
							</p>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div className={styles.entry_table_header}>
					<div className={styles.title_box}>
						<p className={styles.title}>참가명단</p>
						<button className={styles.action_button}>+ 참가명단 관리</button>
					</div>
					<div className={styles.entry_table_select_list}>
						<select
							className={styles.select_box}
							placeholder="연령"
						>
							<option value="">전체</option>
							{/* 연령대 코드 조회 */}
							<option value="20">20대</option>
							<option value="30">30대</option>
							<option value="40">40대</option>
							<option value="50">50대</option>
							<option value="60">60대</option>
						</select>
						<select
							className={styles.entry_table_select_box}
							placeholder="상태"
						>
							<option value="">전체</option>
							<option value="pending">신청중</option>
							<option value="completed">결제완료</option>
							<option value="canceled">취소됨</option>
						</select>
					</div>
				</div>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead className={styles.entry_table_no_head}>No.</TableHead>
							<TableHead className={styles.entry_table_name_head}>
								이름
							</TableHead>
							<TableHead className={styles.entry_table_phone_head}>
								전화번호
							</TableHead>
							<TableHead className={styles.entry_table_email_head}>
								이메일
							</TableHead>
							<TableHead className={styles.entry_table_details_head}>
								신청 세부종목
							</TableHead>
							<TableHead className={styles.entry_table_status_head}>
								상태
							</TableHead>
							<TableHead className={styles.entry_table_date_head}>
								결제 / 취소일시
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody className={styles.entry_table_table_body}>
						{/* {dummy.entry.map((item) => ( */}
						{cmptManageList.map((item, index) => (
							<TableRow key={index}>
								<TableCell className={styles.entry_table_no_head}>
									{item.no}
								</TableCell>
								<TableCell className={styles.entry_table_name_head}>
									{item.userNm}
								</TableCell>
								<TableCell className={styles.entry_table_phone_head}>
									{item.userPhone}
								</TableCell>
								<TableCell
									className={styles.entry_table_email_head}
									align="left"
								>
									{item.userEmail}
								</TableCell>
								<TableCell className={styles.entry_table_details_head}>
									{buildDetails(item)}
								</TableCell>
								<TableCell className={styles.entry_table_status_head}>
									{item.reqRsltNm}
								</TableCell>
								<TableCell className={styles.entry_table_date_head}>
									{moment(item.cmptReqDtm).format('YYYY-MM-DD HH:mm')}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
          {/* <!-- paging --> */}
          {cmptManageList == null ? null :
            <div className="paging">
			{paging.map((item) => (
				<a
				href="#"
				key={item}
				onClick={(e) => onClickPaging(e, item)}
				className={pageNum === item ? styles.page_on : ""}
				>
				{item}
				</a>
  ))}
            </div>
          }
			<div>
				<div className={styles.disciplines_header}>
					<div className={styles.title_box}>
						<p className={styles.title}>세부종목</p>
						<button className={styles.action_button}>+ 세부종목 관리</button>
					</div>
					<div className={styles.disciplines_select_list}>
						<select className={styles.disciplines_select_box}>
							<option value="all">전체</option>
							<option value="men">남자복식</option>
							<option value="women">여자복식</option>
							<option value="mixed">혼합복식</option>
						</select>
						<select className={styles.select_box}>
							<option value="all">전체</option>
							<option value="20">20대</option>
							<option value="30">30대</option>
							<option value="40">40대</option>
							<option value="50">50대</option>
							<option value="60">60대</option>
						</select>
						<select className={styles.disciplines_select_box}>
							<option value="all">전체</option>
							<option value="pending">A</option>
							<option value="completed">B</option>
							<option value="canceled">C</option>
						</select>
					</div>
				</div>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead className={styles.disciplines_no_head}>No.</TableHead>
							<TableHead className={styles.disciplines_group_head}>
								종목
							</TableHead>
							<TableHead className={styles.disciplines_age_head}>
								연령
							</TableHead>
							<TableHead className={styles.disciplines_grade_head}>
								등급
							</TableHead>
							<TableHead className={styles.disciplines_applicants_head}>
								신청 세부종목
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody className={styles.disciplines_table_body}>
						{dummy.disciplines.map((item) => (
							<TableRow key={item.id}>
								<TableCell className={styles.disciplines_no_head}>
									{item.id}
								</TableCell>
								<TableCell className={styles.disciplines_group_head}>
									{item.group}
								</TableCell>
								<TableCell className={styles.disciplines_age_head}>
									{item.age}
								</TableCell>
								<TableCell className={styles.disciplines_grade_head}>
									{item.grade}
								</TableCell>
								<TableCell className={styles.disciplines_applicants_head}>
									{item.applicants}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
			<div className={styles.table_row}>
				<div className={styles.simple_table_box}>
					<div className={styles.title_box}>
						<p className={styles.title}>공지사항</p>
						<button className={styles.action_button}>+ 더보기</button>
					</div>
					<div>
						<p className={styles.simple_table_date_head}>등록일</p>
						<p className={styles.simple_table_views_head}>조회수</p>
						<table className={styles.simple_table}>
							<tbody>
								{dummy.notices.map((item, idx) => (
									<tr
										key={item.title + idx}
										className={styles.simple_table_row}
									>
										<td className={styles.simple_table_title}>{item.title}</td>
										<td className={styles.simple_table_date}>{item.date}</td>
										<td className={styles.simple_table_views}>{item.views}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<div className={styles.simple_table_box}>
					<div className={styles.title_box}>
						<p className={styles.title}>1:1 문의</p>
						<button className={styles.action_button}>+ 더보기</button>
					</div>
					<div>
						<p className={styles.simple_table_date_head}>등록일</p>
						<p className={styles.simple_table_views_head}>조회수</p>
						<table className={styles.simple_table}>
							<tbody>
								{dummy.inquiries.map((item, idx) => (
									<tr
										key={item.title + idx}
										className={styles.simple_table_row}
									>
										<td className={styles.simple_table_title}>{item.title}</td>
										<td className={styles.simple_table_date}>{item.date}</td>
										<td className={styles.simple_table_views}>{item.views}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MngMain;
