import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminHeader from './AdminHeader.tsx';
import styles from './AdminLayout.module.css';
import Footer from '../Footer.tsx';
const AdminLayout = () => {
	return (
		<div className={styles.admin_layout}>
			<AdminHeader />
			<div className={styles.admin_layout_inner}>
				<Outlet />
			</div>
			<Footer />
		</div>
	);
};

export default AdminLayout;
