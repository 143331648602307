import React, { useState } from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import styles from './MngCmptLRegPay.module.css';
import Input from '../../../common/Input/Input.tsx';
import SportRegistrationStep from '../../../common/SportRegistrationStep/SportRegistrationStep.tsx';
import clsx from 'clsx';
import Checkbox from '../../../common/Checkbox/Checkbox.tsx';

import HTMLEditor from '../../../common/HTMLEditor/HTMLEditor.tsx';
import FileInput from '../../../common/FileInput/FileInput.tsx';
import Button from '../../../common/Button/Button.tsx';
import ArrowRight from '../../../common/Icons/ArrowRight.tsx';
import Modal from '../../../common/Modal/Modal.tsx';
import ArrowLeft from '../../../common/Icons/ArrowLeft.tsx';

const years = Array.from({ length: 2025 - 1900 + 1 }, (_, i) =>
	(1900 + i).toString(),
);

const MngCmptLRegPay = () => {
	const [paymentType, setPaymentType] = useState<
		'card' | 'naver' | 'kakao' | 'toss'
	>('card');

	return (
		<div>
			<MyCompetitionHeader title="대회마스터" />
			<div className={styles.container}>
				<div className={styles.left_section}>
					<div className={styles.sub_title}>
						<h1>신규대회 등록</h1>
						<p>
							아래의 순서에 따라
							<br />
							개최하고 싶은 대회의
							<br />
							정보를 입력하시면
							<br />
							해당 대회를 등록하실 수 있습니다.
						</p>
					</div>
					<SportRegistrationStep step={2} />
				</div>
				<div className={styles.right_section}>
					<div className={styles.title}>
						<h1>5. 등록비용 결제</h1>
					</div>
					<p className={styles.description}>
						등록비는 원하시는 대회를 공유하고 참가신청을 받기 위해 발생하는
						비용입니다.
						<br />
						참기인원 모집 및 광고를 통한 결제금액과는 별개의 요금체계이므로
						환불되지 않음을 미리 알려드립니다.
					</p>
					<div className={styles.divider}></div>
					<div className={styles.prize_type_container}>
						<div className={styles.field_row}>
							<div className={styles.field_label}>결제방식</div>
							<div className={styles.field_value}>
								<span className={styles.price}>10,000</span>
								<span className={styles.price_unit}>원</span>
								<p className={styles.price_description}>
									= 대회등록비 100,000 원 + 배너광고비 100,000 원
								</p>
							</div>
						</div>
						<div className={styles.divider}></div>
						<div className={styles.field_row}>
							<div className={styles.field_label}>결제수단 선택</div>
							<div className={styles.field_value}>
								<div className={styles.payment_type_container}>
									<button
										className={clsx(
											styles.payment_type,
											paymentType === 'card' && styles.payment_type_selected,
										)}
										onClick={() => setPaymentType('card')}
									>
										신용카드
									</button>
									<button
										className={clsx(
											styles.payment_type,
											paymentType === 'naver' && styles.payment_type_selected,
										)}
										onClick={() => setPaymentType('naver')}
									>
										네이버페이
									</button>
									<button
										className={clsx(
											styles.payment_type,
											paymentType === 'kakao' && styles.payment_type_selected,
										)}
										onClick={() => setPaymentType('kakao')}
									>
										카카오페이
									</button>
									<button
										className={clsx(
											styles.payment_type,
											paymentType === 'toss' && styles.payment_type_selected,
										)}
										onClick={() => setPaymentType('toss')}
									>
										토스페이
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.divider} />
					<div className={styles.agreement_container}>
						<Checkbox id="agreement" />
						<label htmlFor="agreement">
							결제수단을 확인하였으며, 개인정보 이용에 동의합니다.
						</label>
					</div>
					<div className={styles.table_footer}>
						<span className={styles.pagination_button}>
							<ArrowLeft
								width={24}
								height={24}
							/>
							이전단계로 이동
						</span>
						<div className={styles.pagination}>
							<div className={styles.footer_progress}>
								<strong>3</strong>
								<span> / </span>
								<span>5</span>
							</div>
						</div>
						<Button
							weight="bold"
							suffixIcon={<ArrowRight />}
						>
							저장 및 다음단계로 이동
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MngCmptLRegPay;
