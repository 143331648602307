import React from 'react';
import { useState, useEffect } from 'react';
import { getAccessToken, getCookieUserId } from "../../common/common.tsx";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { fn_axios, logout } from '../../common/common.tsx';
import { API } from '../../config/config.tsx';


import Layout from '../../layout/Layout.tsx';
import Slider from "../../layout/Slider.tsx";


function Mypage() {
    const [userName, setUserName] = useState("");
    const [point, setPoint] = useState("");
    const [actvieIndex, setActvieIndex] = useState(0);
    const [userGameItems, setUserGameItems] = useState([]);
    const [profileImage, setProfileImage] = useState("");
    const [reqsCompletCmpt, setReqsCompletCmpt] = useState([]);
    const [reqsCmpt, setReqsCmpt] = useState([]);
    const [cmptItrstList, setCmptItrstList] = useState([]);

    const getCmptItrst = () => {
        const param = {
            method: 'GET',
            url: `${API.BACKEND}/${API.VERSION}/cmpt/getCmptItrst`,
            params: {
                pageNum: 1
                , pageCnt: 3
                , userId: getCookieUserId()
            }
        }
        fn_axios(param, callbackGetCmptItrst, null);
    }

    const callbackGetCmptItrst = (res) => {
        setCmptItrstList(res.data.object);
    }



    const getUser = () => {
        const param = {
            method: 'GET',
            url: `${API.BACKEND}/v1/user/getUser`,
            params: { userId: null }
        }
        fn_axios(param, callbackGetUser, null);
    };

    const callbackGetUser = (res) => {
        setUserName(res.data.object.userNm);
        setPoint(res.data.object.point);
        setProfileImage(res.data.object.userProfileFileUrl);
    }
    const getUserItrstItem = () => {
        const param = {
            method: 'GET',
            url: `${API.BACKEND}/v1/user/getUserItrstItem`,
            params: { userId: null }
        }
        fn_axios(param, callbackGetUserItrstItem, null);
    };

    const callbackGetUserItrstItem = (res) => {
        console.log(res);
        setUserGameItems(res.data.object);
    }

    const handleGameItme = (index) => {
        setActvieIndex(index);
    }

    const moveUpdateUser = () => {
        window.location.href = "/user/updateuser";
    }
    const movePoint = () => {
        window.location.href = "/user/point";
    }

    const getCompletCmptPasing = () => {
        const param = {
            method: 'GET',
            url: `${API.BACKEND}/${API.VERSION}/cmpt/getCmptPaging`,
            params: {
                pageNum: 1
                , pageCnt: 3
                , enterUserId: getCookieUserId()
                , reqRsltCd: 'REQ_RSLT_CD_02'

            }
        }
        fn_axios(param, callbackGetCmptPasing, null);
    }

    const callbackGetCmptPasing = (res) => {
        setReqsCompletCmpt(res.data.object);
        console.log(res.data);
    }
    const getReqsCmptPasing = () => {
        const param = {
            method: 'GET',
            url: `${API.BACKEND}/${API.VERSION}/cmpt/getCmptPaging`,
            params: {
                pageNum: 1
                , pageCnt: 3
                , reqUserId: getCookieUserId()
                , reqRsltCd: 'REQ_RSLT_CD_01'

            }
        }
        fn_axios(param, callbackGetReqsCmptPasing, null);
    }

    const callbackGetReqsCmptPasing = (res) => {
        setReqsCmpt(res.data.object);
    }


    // 탈퇴 확인 페이지 이동
    const withdraw = () => {
        window.location.href = "/user/withdraw";
    }

    useEffect(() => {

        //로그인중이면 홈화면으로 이동.
        if (getAccessToken() == null) {
            window.location.href = "/";
        } else {
            getUser();
            getUserItrstItem();
            getCompletCmptPasing();
            getReqsCmptPasing();
            getCmptItrst();
        }
    }, [])


    return (
        <Layout>
            <title>Mypage Information</title>
            <div className="contents mypage">
                {/* <!-- my page info --> */}
                <section className="my_profile">
                    {/* <!-- mobile id --> */}
                    <div className="my_id mo">
                        <span>{userName}</span> <b><a href="#" onClick={movePoint}><span>{point}</span> point</a></b>
                    </div>
                    {/* <!-- //mobile id --> */}
                    <div className="photo">
                        <img src={profileImage} alt="profile" />
                    </div>
                    <div className="my_info">
                        <div className="title">My page</div>
                        {/* <!-- pc id --> */}
                        <div className="data pc">
                            <span>{userName}</span> <b><a href="#" onClick={movePoint}><span>{point}</span> point</a></b>
                        </div>
                        {/* <!-- //pc id --> */}
                        <div className="modify">
                            <a href="#" onClick={moveUpdateUser}>개인정보 수정</a>
                            <p>프로필 정보 및 관심종목을 변경할 수 있습니다.</p>
                        </div>
                        <div className="choice">
                            <p className="tx_tit02">원하는 대회 및 날짜 선택 </p>
                            <div className="tab">
                                <div className="tab_title">
                                    {userGameItems.map((userGameItem, index) => (
                                        <a key={index} onClick={() => handleGameItme(index)} className={index == actvieIndex ? "active" : ""}>{userGameItem.gameKindNm}</a>
                                    ))}
                                </div>
                                {userGameItems.map((userGameItem, index) => (
                                    <div key={index} className={index == actvieIndex ? "contest active" : "contest"}>
                                        <div ><span>{userGameItem.m3Nm}월대회 : </span><span style={{ cursor: "pointer" }} onClick={() => window.location.href = `/cmpt/cmptList?gameKindCd=${userGameItem.gameKindCd}&searchMonth=${userGameItem.m3Nm}`}>{userGameItem.m3Count}</span></div>
                                        <div ><span>{userGameItem.m2Nm}월 : </span><span style={{ cursor: "pointer" }} onClick={() => window.location.href = `/cmpt/cmptList?gameKindCd=${userGameItem.gameKindCd}&searchMonth=${userGameItem.m2Nm}`}>{userGameItem.m2Count}</span></div>
                                        <div ><span>{userGameItem.m1Nm}월 : </span><span style={{ cursor: "pointer" }} onClick={() => window.location.href = `/cmpt/cmptList?gameKindCd=${userGameItem.gameKindCd}&searchMonth=${userGameItem.m1Nm}`}>{userGameItem.m1Count}</span></div>
                                    </div>
                                ))}
                            </div>
                            <div className="tab_title">
                                <div style={{ "marginBottom": "1em" }} ><span><a href='#' className='active' onClick={() => window.location.href = `/user/UserReqList`}><b >신청내역</b></a></span></div>
                                {/* <div style={{"marginBottom":"1em"}} ><span><a href='#' className='active' onClick={() => window.location.href = `/user/UserPayList`}><b>결제내역</b></a></span></div> */}
                                <div style={{ "marginBottom": "1em" }} ><span><a href='#' className='active' onClick={() => logout()}><b>로그아웃</b></a></span></div>

                                {/* <div style={{ "marginBottom": "1em" }} ><span><a href='#' className='active' onClick={() => memberStateUpdate()}><b>회원탈퇴</b></a></span></div> */}
                                <div style={{ "marginBottom": "1em" }} ><span><a href='#' className='active' onClick={() => withdraw()}><b>회원탈퇴</b></a></span></div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="my_schedule">
                    <div className="my_contest">
                        <div className="list">
                            <p>
                                내가 참가신청한 대회
                                <a style={{ cursor: "pointer" }} className="btn_r_black" onClick={() => window.location.href = `/user/userReqCmptList`}>참가대회</a>
                            </p>
                            <ul>
                                {reqsCompletCmpt == null || reqsCompletCmpt.length == 0 ?
                                    <li>아직 신청한 대회가 없습니다.</li>
                                    : reqsCompletCmpt.map((item, index) => (
                                        <li style={{ cursor: "pointer" }} onClick={() => window.location.href = `/cmpt/cmptDetail?cmptLId=${item.cmptLId}`}>
                                            <b >{item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(item.cmptReqDday) >= 0 ? "D-" + Math.abs(item.cmptReqDday) : "D+" + Math.abs(item.cmptReqDday)) :
                                                (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(item.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") :
                                                    (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감"))} </b>
                                            {item.cmptLNm}</li>
                                    ))}
                            </ul>
                        </div>
                        <div className="list">
                            <p>
                                내가 관심있는 대회
                                <a style={{ cursor: "pointer" }} className="btn_r_black" onClick={() => window.location.href = `/user/userCmptItrstList`}>관심대회</a>
                            </p>
                            <ul>
                                {cmptItrstList == null || cmptItrstList.length == 0 ?
                                    <li>관심 있는 대회가 없습니다.</li>
                                    : cmptItrstList.map((item, index) => (
                                        <li style={{ cursor: "pointer" }} onClick={() => window.location.href = `/cmpt/cmptDetail?cmptLId=${item.cmptLId}`}>
                                            <b >{item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(item.cmptReqDday) >= 0 ? "D-" + Math.abs(item.cmptReqDday) : "D+" + Math.abs(item.cmptReqDday)) :
                                                (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(item.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") :
                                                    (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감"))} </b>
                                            {item.cmptLNm}</li>
                                    ))}
                            </ul>
                        </div>
                        <div className="list">
                            <p>
                                내가 참가신청 중인 대회
                            </p>
                            <ul>
                                {reqsCmpt == null || reqsCmpt.length == 0 ?
                                    <li>신청중인 대회가 없습니다.</li>
                                    : reqsCompletCmpt.map((item, index) => (
                                        <li style={{ cursor: "pointer" }} onClick={() => window.location.href = `/cmpt/cmptDetail?cmptLId=${item.cmptLId}`}>
                                            <b >{item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(item.cmptReqDday) >= 0 ? "D-" + Math.abs(item.cmptReqDday) : "D+" + Math.abs(item.cmptReqDday)) :
                                                (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(item.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") :
                                                    (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감"))} </b>
                                            {item.cmptLNm}</li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </section>
                {/* <!-- //my page info --> */}

                <Slider></Slider>

                <div className="tx_type02 pc"><b>당신이 원하는 대회를 원하는 장소에서</b>당신의 대회를 알리고 싶다면, <b>WOORI GROUND!</b> WOOG ME! CONTACT ME!</div>

            </div>

        </Layout>
    );
}

export default Mypage;