import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AdminHeader.module.css';

const AdminHeader = () => {
	const DUMMY_USER = {
		profile_img:
			'https://s3-alpha-sig.figma.com/img/7f3b/1181/2ec15e9be21433730df445b7dec5e49d?Expires=1740355200&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=XwA-gDrtK7sVZq5d-Jq83CFEIdBT~ww0idY4JMJ36GQKY5QZJsOxfqmwcSK~CJGcfV2xNj3tQkZWdxJGLIf93ETqy3LLk08OwGDyruauBs1e6gMLGZlrWOnzBOwa-iMdeV0SU0rFo9djlls1frrJmN5OCM~Fbob6Jc1cLFbvPoBgCeGftEtXhcnCn7jqLEIZJHJUlv25DOIXQ0h4CxjEdhpk0vgnNqcUD15fBBZvUnAaEQzsBBiIRF9CNENT5jqa4e-DsLRYytKP~iccby~9QEpMeZan7emZvByqi4HD9FkcoMRpkXbDo-v-FdDwIKFEo0bTye3hKc8NmzOJ91ce9A__',
		name: 'soyeonsoyeon',
	};

	return (
		<div className={styles.admin_header}>
			<div className={styles.admin_header_inner}>
				<div className={styles.admin_header_inner_left}>
					<Link to="/">
						<img
							src="/assets/images/common/logo.png"
							alt="logo"
							className={styles.logo}
						/>
					</Link>
					<select className={styles.role_select}>
						<option value="1">대회관리자</option>
					</select>
					<div className={styles.link_list}>
						<Link to="/">대회마스터</Link>
						<Link to="/">대회관리</Link>
						<Link to="/">기관/단체/업체</Link>
						<Link to="/">체육관</Link>
					</div>
				</div>
				<div className={styles.admin_header_inner_right}>
					<div className={styles.admin_profile}>
						<p className={styles.admin_profile_name}>{DUMMY_USER.name}</p>
						<img
							src={DUMMY_USER.profile_img}
							alt="profile"
							className={styles.admin_profile_img}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminHeader;
