import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import { getAuthFromToken, CONSTANTS } from './common/common.tsx';

// import useScript from './common/useScript';

import './index.css';
import './styles.css';

// import App from './App';
import Home from './pages/Home.tsx';
import Login from './pages/user/Login.tsx';
import Join from './pages/user/Join.tsx';
import KakaoToken from './pages/user/KakaoToken.tsx';
import NaverToken from './pages/user/NaverToken.tsx';
import Mypage from './pages/user/Mypage.tsx';
import Point from './pages/user/Point.tsx';
import UpdateUser from './pages/user/UpdateUser.tsx';
import UserReqCmptList from './pages/user/UserReqCmptList.tsx';
import UserPayList from './pages/user/UserPayList.tsx';
import UserReqList from './pages/user/UserReqList.tsx';
import UserCmptItrstList from './pages/user/UserCmptItrstList.tsx';
import UserReqCmptUpdate from './pages/user/UserReqCmptUpdate.tsx';
import UserPayDetail from './pages/user/UserPayDetail.tsx';
import UserReqDetail from './pages/user/UserReqDetail.tsx';
import FindId from './pages/user/FindId.tsx';
import FindPwd from './pages/user/FindPwd.tsx';
import JoinAgree from './pages/user/JoinAgree.tsx';
import AgeConfirm from './pages/user/AgeConfirm.tsx';
import PassReturn from './pages/pass/PassReturn.tsx';
import UserWithdraw from './pages/user/UserWithdraw.tsx';

import SearchList from './pages/common/SearchList.tsx';

// import PassRequest from "./pages/pass/PassRequest.tsx";

// sport_notice, notice_1, contents, prize
import CmptDetailGame from './pages/cmpt/CmptDetailGame.tsx';
import CmptDetailItem from './pages/cmpt/CmptDetailItem.tsx';
import CmptDetailMain from './pages/cmpt/CmptDetailMain.tsx';
import CmptDetailNotice from './pages/cmpt/CmptDetailNotice.tsx';
import CmptDetail from './pages/cmpt/CmptDetail.tsx';
import CmptList from './pages/cmpt/CmptList.tsx';
import CmptReqs from './pages/req/CmptReqs.tsx';
import CmptReqsPay from './pages/req/CmptReqsPay.tsx';
import CmptReqsPayComplet from './pages/req/CmptReqsPayComplet.tsx';
import CmptReqsPayFail from './pages/req/CmptReqsPayFail.tsx';
import CmptReqsPayResult from './pages/req/CmptReqsPayResult.tsx';

import GymList from './pages/gym/GymList.tsx';
import GymDetail from './pages/gym/GymDetail.tsx';

import BoardList from './pages/board/BoardList.tsx';
import EventList from './pages/event/EventList.tsx';

import CmptManage from './pages/mng/CmptManage.tsx';
import UserManage from './admin/mng/UserManage.tsx';
import UserManageDetail from './admin/mng/UserManageDetail.tsx';
import CodeManage from './admin/mng/CodeManage.tsx';
import CodeManageUpdate from './admin/mng/CodeManageUpdate.tsx';
import CodeManageInsert from './admin/mng/CodeManageInsert.tsx';
import CodeManageDetail from './admin/mng/CodeManageDetail.tsx';
import CodeManageDetailInsert from './admin/mng/CodeManageDetailInsert.tsx';
import CodeManageDetailUpdate from './admin/mng/CodeManageDetailUpdate.tsx';
import GymManage from './admin/mng/GymManage.tsx';
import GymManageDetail from './admin/mng/GymManageDetail.tsx';
import BnnrManage from './admin/mng/BnnrManage.tsx';

import NoAccess from './pages/error/NoAccessPage.tsx';
import NotFoundPage from './pages/error/NotFoundPage/NotFoundPage.tsx';

import './assets/css/common.css';
import './assets/css/main.css';
import './assets/css/contents.css';
import './assets/css/mobile.css';
import './assets/css/admin/AdminHeader.css';
import './assets/css/admin/AdminLayout.css';
import './assets/css/admin/AdminSidebar.css';
// import "./assets/js/ui.js";

//TEST 용
import APITest from './pages/test/APITest.tsx';
import AdminLayout from './layout/admin/AdminLayout.tsx';

// Admin
import MngMain from './pages/admin/main/MngMain.tsx';
import MngCmptMList from './pages/admin/master-list/MngCmptMList.tsx';
import MngCmptMReg from './pages/admin/master-registration/MngCmptMReg.tsx';
import MngCmptMContent from './pages/admin/master-contents/MngCmptMContent.tsx';
import MngCmptLList from './pages/admin/sport-list/MngCmptLList.tsx';
import MngCmptLDetail from './pages/admin/sport-content/MngCmptLDetail.tsx';
import MngCmptLGameList from './pages/admin/sport-game-list/MngCmptLGameList.tsx';
import MngCmptLNotice from './pages/admin/sport-notice-list/MngCmptLNotice.tsx';
import MngCmptLItem from './pages/admin/sport-notice-content/MngCmptLNoticeDetail.tsx';
import MngCmptLNoticeDetail from './pages/admin/sport-notice-content/MngCmptLNoticeDetail.tsx';
import MngCmptLSummary from './pages/admin/sport-summary/MngCmptLSummary.tsx';
import MngCmptLQnaList from './pages/admin/sport-qna-list/MngCmptLQnaList.tsx';
import MngCmptLReqs from './pages/admin/sport-member/MngCmptLReqs.tsx';
import MngCmptLRegAgree from './pages/admin/sport-registration/MngCmptLRegAgree.tsx';
import MngGroupList from './pages/admin/association-list/MngGroupList.tsx';
import AssociationRegistration from './pages/admin/association-registration/MngGroupRegMain.tsx';
import MngGroupRegMain from './pages/admin/association-registration/MngGroupRegMain.tsx';
import MngGroupRegComplet from './pages/admin/association-confirm/MngGroupRegComplet.tsx';
import MngGroupMain from './pages/admin/association-content/MngGroupMain.tsx';
import MngCmptLRegMain from './pages/admin/sport-registration-detail/MngCmptLRegMain.tsx';
import MngCmptLRegGame from './pages/admin/sport-registration-game/MngCmptLRegGame.tsx';
import MngCmptLRegItem from './pages/admin/sport-registration-prize/MngCmptLRegItem.tsx';
import MngCmptLRegPay from './pages/admin/sport-payment/MngCmptLRegPay.tsx';
import MngCmptLRegComplet from './pages/admin/sport-complete/MngCmptLRegComplet.tsx';
import MngGymList from './pages/admin/court-list/MngGymList.tsx';
import MngGymRegMain from './pages/admin/court-registration/MngGymRegMain.tsx';
import MngCmptLQnaDetail from './pages/admin/sport-qna-detail/MngCmptLQnaDetail.tsx';
import MngGymContent from './pages/admin/court-content/MngGymContent.tsx';
import MngCmptLRegBanner from './pages/admin/sport-registration-banner/MngCmptLRegBanner.tsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<>
		<HelmetProvider>
			<Helmet>
				<title>Wooriground</title>
				<meta
					name="apple-mobile-web-app-capable"
					content="yes"
				/>
				<meta
					name="mobile-web-app-capable"
					content="yes"
				/>
				<meta charSet={'utf-8'} />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"
				/>

				<meta
					http-equiv="Cache-Control"
					content="no-cache"
				/>
				<meta
					http-equiv="X-UA-Compatible"
					content="IE=edge,chrome=1"
				/>
				{/* <!-- PASS 관련 JS 파일 --> */}
				<script src={process.env.REACT_APP_PASS_INDEX_JS_URL}></script>

				{/* <link rel="stylesheet" type="text/css" href="/assets/css/common.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/main.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/contents.css"/> */}
				{/* <link rel="stylesheet" media="handheld, only screen and (max-width: 768px)"  href="/assets/css/mobile.css"/> */}

				{/* <script  type="text/javascript"  src="/assets/js/jquery-3.6.0.min.js" defer ></script> */}
				{/* <script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script> */}
				{/* <script type="text/javascript" src='/assets/js/keen-slider.min.js' defer ></script>  */}
				{/* <script type="text/javascript" src='/assets/js/ui.js' defer ></script>	  */}
			</Helmet>
		</HelmetProvider>
		<CookiesProvider>
			<BrowserRouter>
				<Routes>
					{/* <Route path="/" element={<App />} /> */}
					<Route
						path="/home"
						element={<Home />}
					/>
					<Route
						path="/"
						element={<Home />}
					/>
					<Route
						path="/search"
						element={<SearchList />}
					/>
					<Route
						path="*"
						element={<NotFoundPage />}
					/>

					{/* API 테스트용 */}
					<Route
						path="/test"
						element={<APITest />}
					/>

					{/* 사용자 관련 url */}
					<Route
						path="/user/login"
						element={<Login />}
					/>
					<Route
						path="/user/join"
						element={<Join />}
					/>
					<Route
						path="/user/kakaotoken"
						element={<KakaoToken />}
					/>
					<Route
						path="/user/navertoken"
						element={<NaverToken />}
					/>
					<Route
						path="/user/mypage"
						element={<Mypage />}
					/>
					<Route
						path="/user/point"
						element={<Point />}
					/>
					<Route
						path="/user/updateuser"
						element={<UpdateUser />}
					/>
					<Route
						path="/user/userReqCmptUpdate"
						element={<UserReqCmptUpdate />}
					/>
					<Route
						path="/user/userReqCmptList"
						element={<UserReqCmptList />}
					/>
					<Route
						path="/user/userPayList"
						element={<UserPayList />}
					/>
					<Route
						path="/user/userCmptItrstList"
						element={<UserCmptItrstList />}
					/>
					<Route
						path="/user/userReqList"
						element={<UserReqList />}
					/>
					<Route
						path="/user/userPayDetail"
						element={<UserPayDetail />}
					/>
					<Route
						path="/user/userReqDetail"
						element={<UserReqDetail />}
					/>
					<Route
						path="/user/findId"
						element={<FindId />}
					/>
					<Route
						path="/user/findPwd"
						element={<FindPwd />}
					/>
					<Route
						path="/user/joinAgree"
						element={<JoinAgree />}
					/>
					<Route
						path="/user/ageConfirm"
						element={<AgeConfirm />}
					/>
					<Route
						path="/user/passReturn"
						element={<PassReturn />}
					/>

					<Route
						path="/user/withdraw"
						element={<UserWithdraw />}
					/>

					{/* <Route path="/pass/passRequest" element={<PassRequest />} /> */}

					{/* 대회 url */}
					<Route
						path="/cmpt/CmptDetailGame"
						element={<CmptDetailGame />}
					/>
					<Route
						path="/cmpt/CmptDetailItem"
						element={<CmptDetailItem />}
					/>
					<Route
						path="/cmpt/CmptDetailMain"
						element={<CmptDetailMain />}
					/>
					<Route
						path="/cmpt/CmptDetailNotice"
						element={<CmptDetailNotice />}
					/>
					<Route
						path="/cmpt/CmptDetail"
						element={<CmptDetail />}
					/>
					<Route
						path="/cmpt/CmptList"
						element={<CmptList />}
					/>

					{/* 대회 신청 url */}
					<Route
						path="/req/cmptReqs"
						element={<CmptReqs />}
					/>
					<Route
						path="/req/cmptReqsPay"
						element={<CmptReqsPay />}
					/>
					<Route
						path="/req/cmptReqsPayComplet"
						element={<CmptReqsPayComplet />}
					/>
					<Route
						path="/req/cmptReqsPayFail"
						element={<CmptReqsPayFail />}
					/>
					<Route
						path="/req/cmptReqsPayResult"
						element={<CmptReqsPayResult />}
					/>

					{/* 게시판 관련(고객센터) */}
					<Route
						path="/board/boardList"
						element={<BoardList />}
					/>
					<Route
						path="/event/eventList"
						element={<EventList />}
					/>

					{/* 그라운드 */}
					<Route
						path="/gym/gymList"
						element={<GymList />}
					/>
					<Route
						path="/gym/gymDetail"
						element={<GymDetail />}
					/>

					<Route
						path="/admin/userMng"
						element={<UserManage />}
					/>

					<Route element={<AdminLayout />}>
						<Route
							element={<MngMain />}
							path="/admin"
						/>
						<Route
							element={<MngCmptMList />}
							path="/admin/master-list"
						/>
						<Route
							element={<MngCmptMReg />}
							path="/admin/master-registration"
						/>
						<Route
							element={<MngCmptMContent />}
							path="/admin/master-contents"
						/>
						<Route
							element={<MngCmptLList />}
							path="/admin/sport-list"
						/>
						<Route
							element={<MngCmptLDetail />}
							path="/admin/sport/:id/content"
						/>
						<Route
							element={<MngCmptLGameList />}
							path="/admin/sport/:id/game-list"
						/>
						<Route
							element={<MngCmptLNotice />}
							path="/admin/sport/:id/notice"
						/>
						<Route
							element={<MngCmptLNoticeDetail />}
							path="/admin/sport/:id/notice/:noticeId"
						/>
						<Route
							element={<MngCmptLItem />}
							path="/admin/sport/:id/prize"
						/>
						<Route
							element={<MngCmptLSummary />}
							path="/admin/sport/:id/summary"
						/>
						<Route
							element={<MngCmptLQnaList />}
							path="/admin/sport/:id/qna"
						/>
						<Route
							element={<MngCmptLQnaDetail />}
							path="/admin/sport/:id/qna/:qnaId"
						/>
						<Route
							element={<MngCmptLReqs />}
							path="/admin/sport/:id/member"
						/>
						<Route
							element={<MngCmptLRegAgree />}
							path="/admin/sport/:id/registration"
						/>
						<Route
							element={<MngCmptLRegMain />}
							path="/admin/sport/:id/registration-main"
						/>
						<Route
							element={<MngCmptLRegGame />}
							path="/admin/sport/:id/registration-game"
						/>
						<Route
							element={<MngCmptLRegItem />}
							path="/admin/sport/:id/registration-prize"
						/>
						<Route
							element={<MngCmptLRegBanner />}
							path="/admin/sport/:id/registration-banner"
						/>
						<Route
							element={<MngCmptLRegPay />}
							path="/admin/sport/:id/registration-payment"
						/>
						<Route
							element={<MngCmptLRegComplet />}
							path="/admin/sport/:id/registration-complete"
						/>
						<Route
							element={<MngGroupList />}
							path="/admin/association-list"
						/>
						<Route
							element={<MngGroupRegMain />}
							path="/admin/association-registration"
						/>
						<Route
							element={<MngGroupRegComplet />}
							path="/admin/association-confirm"
						/>
						<Route
							element={<MngGroupMain />}
							path="/admin/association-content"
						/>
						<Route
							element={<MngGymList />}
							path="/admin/court-list"
						/>
						<Route
							element={<MngGymRegMain />}
							path="/admin/court-registration"
						/>
						<Route
							element={<MngGymContent />}
							path="/admin/court/:id/content"
						/>
					</Route>

					{/* admin 만 접근 */}
					{CONSTANTS.ROLE_ADMIN == getAuthFromToken() ? (
						<>
							{/* 대회현황 */}
							<Route
								path="/admin/userMng"
								element={<UserManage />}
							/>
							<Route
								path="/admin/userMngDetail"
								element={<UserManageDetail />}
							/>
							<Route
								path="/admin/codeMng"
								element={<CodeManage />}
							/>
							<Route
								path="/admin/codeMngUpdate"
								element={<CodeManageUpdate />}
							/>
							<Route
								path="/admin/codeMngInsert"
								element={<CodeManageInsert />}
							/>
							<Route
								path="/admin/codeMngDetail"
								element={<CodeManageDetail />}
							/>
							<Route
								path="/admin/codeMngDetailInsert"
								element={<CodeManageDetailInsert />}
							/>
							<Route
								path="/admin/codeMngDetailUpdate"
								element={<CodeManageDetailUpdate />}
							/>
							<Route
								path="/admin/gymMng"
								element={<GymManage />}
							/>
							<Route
								path="/admin/gymMngDetail"
								element={<GymManageDetail />}
							/>
							<Route
								path="/admin/bnnrMng"
								element={<BnnrManage />}
							/>
						</>
					) : (
						// 일반회원이 관리자 페이지에 접근할 경우
						<>
							<Route
								path="/admin/*"
								element={
									<Navigate
										to="/error/noaccess"
										replace
									/>
								}
							/>
							<Route
								path="/error/noaccess"
								element={<NoAccess />}
							/>
						</>
					)}

					{/* 대회 관리자 만 접근 */}
					{CONSTANTS.ROLE_CMPT == getAuthFromToken() ||
					CONSTANTS.ROLE_ADMIN == getAuthFromToken() ? (
						<>
							<Route
								path="/mng/concert"
								element={
									<CmptManage
										propsCmptLId={'240926_GAME_KIND_0075_000001_0001'}
									/>
								}
							/>
							<Route
								path="/mng/cmptMng"
								element={<CmptManage />}
							/>
						</>
					) : null}
				</Routes>
			</BrowserRouter>
		</CookiesProvider>
	</>,
);
