import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import AdminLyout from '../../layout/_admin/AdminLayout.tsx';

import { fn_axios, getCookieUserId } from '../../common/common.tsx';
import { API } from '../../config/config.tsx';

function CodeManage() {
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);

	const [pageNum, setPageNum] = useState(() => {
		const page = queryParams.get('pageNum');
		return page ? parseInt(page) : 1;
	});
	const [pageMaxNum, setPageMaxNum] = useState(0);
	const [pageCnt] = useState(10);
	const [paging, setPaging] = useState([]);
	const [codeMCnt, setCodeMCnt] = useState(null);

	const [codeMngList, setCodeMngList] = useState([]);
	const [codeDetailInfo, setCodeDetailInfo] = useState([]);
	const [selectCode, setSelectCode] = useState(null);

	//search
	const [searchKeyword, setSearchKeyword] = useState('');
	const [selectedOrdColumn, setSelectedOrdColumn] = useState('');
	const handleSearchKeyword = (e) => {
		setSearchKeyword(e.target.value);
	};
	const onClickSearch = () => {
		if (pageNum == 1) {
			getCodePaging(pageNum);
		} else {
			setPageNum(1);
		}
	};

	useEffect(() => {
		getCodePaging(pageNum);
	}, [pageNum]);

	const getCodePaging = async (num: number) => {
		const param = {
			method: 'GET',
			url: `${API.BACKEND}/${API.VERSION}/admin/getCodeMPaging`,
			params: {
				pageNum: num,
				searchKeyword: searchKeyword,
				selectedOrdColumn: selectedOrdColumn,
			},
		};
		fn_axios(param, callBackGetCode, null);
	};
	const callBackGetCode = (res) => {
		setCodeMngList(res.data.object);
		setCodeMCnt(res.data.map.codeCnt);
		console.log(res);
		var tempInt = parseInt(res.data.map.codeCnt % pageCnt);
		if (tempInt != 0) {
			tempInt = parseInt(res.data.map.codeCnt / pageCnt) + 1;
		} else {
			tempInt = parseInt(res.data.map.codeCnt / pageCnt);
		}
		settingPasing(tempInt);
	};

	const settingPasing = (maxNum) => {
		setPageMaxNum(parseInt(maxNum));
		// var start = (pageNum > 2 ? ( (maxNum - pageNum > 4) ? pageNum - 2 : maxNum - pageNum) : 1);
		var start =
			maxNum <= 5 || pageNum <= 2
				? 1
				: pageNum - (maxNum - pageNum <= 2 ? 4 - (maxNum - pageNum) : 2);
		var end =
			maxNum <= 5 || maxNum - pageNum <= 2
				? maxNum
				: (pageNum < 3 ? 3 : pageNum) + 2;
		var tempList = [];

		if (maxNum != 0) {
			for (var i = start; i <= end; i++) {
				tempList.push(i);
			}
		}
		setPaging(tempList);
	};

	const onClickPaging = (e) => {
		setPageNum(parseInt(e.target.text));
		return null;
	};

	const handleCheck = (code) => {
		if (selectCode === code) {
			setSelectCode(null);
		} else {
			setSelectCode(code);
		}
	};

	// 버튼 이벤트
	const handleDetailClick = () => {
		if (!selectCode) {
			alert('상세 화면으로 이동할 코드를 선택해주세요.');
			return;
		}
		window.location.href = `/admin/codeMngDetail?codeMCd=${selectCode}`;
	};
	const handleUpdateClick = () => {
		if (!selectCode) {
			alert('수정할 코드를 선택해주세요.');
			return;
		}
		window.location.href = `/admin/codeMngUpdate?codeMCd=${selectCode}`;
	};
	const handleInsertClick = () => {
		window.location.href = `/admin/codeMngInsert`;
	};

	const handleOrdColumn = (e) => {
		setSelectedOrdColumn(e.target.value);
	};

	return (
		<AdminLyout>
			<div className="">
				<h1 className="admin_title">코드 관리</h1>
				<section className="admin_list">
					<div className="admin_bar">
						<div className="admin_total">
							{' '}
							총 <b>{codeMCnt == null ? '0' : codeMCnt}</b> 건{' '}
						</div>
						<div className="admin_search">
							<select
								style={{ minWidth: '100px' }}
								value={selectedOrdColumn}
								onChange={handleOrdColumn}
							>
								<option value="">정렬</option>
								<option
									key={1}
									value={'CODE_M_CD'}
								>
									코드
								</option>
								<option
									key={1}
									value={'CODE_M_NM'}
								>
									코드명
								</option>
							</select>
							<input
								type="text"
								style={{ minWidth: '90px' }}
								name="keyword"
								value={searchKeyword}
								onChange={handleSearchKeyword}
								placeholder="키워드"
							/>
							<button onClick={onClickSearch}>검색</button>
						</div>
					</div>
					<div
						className="admin_table type1"
						style={{ overflow: 'auto' }}
					>
						<table
							style={{
								borderCollapse: 'collapse',
								width: '100%',
								tableLayout: 'auto',
							}}
						>
							<thead>
								<tr>
									<th>선택</th>
									<th>코드기본CD</th>
									<th>코드기본명</th>
									<th>코드설명</th>
									<th>등록일시</th>
									<th>수정일시</th>
								</tr>
							</thead>
							<tbody>
								{codeMngList.length === 0 ? (
									<tr>
										<td
											style={{ textAlign: 'center' }}
											colSpan={6}
										>
											검색된 결과가 없습니다.
										</td>
									</tr>
								) : (
									codeMngList.map((item, index) => (
										<tr style={{ cursor: 'pointer' }}>
											<td>
												<input
													type="checkbox"
													checked={selectCode === item.codeMCd}
													onChange={() => handleCheck(item.codeMCd)}
												/>
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.codeMCd}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.codeMNm}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.codeMDesc}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.regrDtm}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.updateDtm}
											</td>
										</tr>
									))
								)}
							</tbody>
						</table>
					</div>
					<div className="paging">
						{paging.map((num) => (
							<a
								key={num}
								onClick={() => setPageNum(num)}
							>
								{num}
							</a>
						))}
					</div>
					<div className="admin_table_btn">
						<button
							onClick={handleDetailClick}
							className="admin_detail_btn"
							style={{ marginTop: '20px' }}
						>
							상세
						</button>
						<button
							onClick={handleUpdateClick}
							className="admin_update_btn"
							style={{ marginTop: '20px' }}
						>
							수정
						</button>
						<button
							onClick={handleInsertClick}
							className="admin_insert_btn"
							style={{ marginTop: '20px' }}
						>
							등록
						</button>
					</div>
				</section>
			</div>
		</AdminLyout>
	);
}

export default CodeManage;
