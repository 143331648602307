import React from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import SportHeader from '../../../common/SportHeader/SportHeader.tsx';
import data from './dummy.json';
import styles from './MngCmptLQnaDetail.module.css';
import ApplyButton from '../../../common/ApplyButton/ApplyButton.tsx';
import Button from '../../../common/Button/Button.tsx';
import ListButton from '../../../common/ListButton/ListButton.tsx';
import Pagination from '../../../common/Pagination/Pagination.tsx';
import Input from '../../../common/Input/Input.tsx';
import Checkbox from '../../../common/Checkbox/Checkbox.tsx';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '../../../common/Table/Table.tsx';
import BackToListButton from '../../../common/BackToListButton/BackToListButton.tsx';

const MngCmptLQnaDetail = () => {
	return (
		<div>
			<MyCompetitionHeader title="대회관리" />
			<SportHeader
				title={data.title}
				date={data.date}
				views={data.views}
				participants={data.participants}
				currentCategory="세부종목"
			/>
			<div className={styles.container}>
				<div className={styles.left_section}>
					<img
						src={data.poster}
						alt="thumbnail"
						className={styles.poster}
					/>
					<ApplyButton />
				</div>
				<div className={styles.right_section}>
					<div className={styles.result_container}>
						<div className={styles.result_count}>
							전체질문 39건 = 답변등록 31 - 미등록 8건
						</div>
						<div className={styles.select_list}>
							<select>
								<option value="all">답변상태</option>
							</select>
						</div>
					</div>

					<div className={styles.qna_question}>
						<strong>39. 질문 :</strong> 대회요강에 고등학교 및 대학에서
						선수등록을 하였던 회원은 자강조에만 참가해야 한다는 내용이 있던데
						작년에 선수등록했었고 지금은 아닌 경우는 일반으로 등록할 수 있는
						건가요 ?<br></br>
						<strong>2023.10.14</strong>
					</div>
					<div className={styles.qna_answer}>
						<p>
							네 ~ , 당해년도에 현역으로 등록되어 있는 선수가 아닌 경우에는 일반
							참가자로 등록하시고 참가하시시면 됩니다. 좋은 성적 기대하며 아래와
							같이 신청방법 안내드립니다 ~
						</p>
						<br />
						<h2>11. 신청방법</h2>
						<ul>
							<li>
								<strong>가.</strong> 본회가 발급한 참가신청서에 기재하여 각
								시도연합회로 참가신청함을 원칙으로 한다. 각 시도연합회에서는
								신청서를 마감일까지 전국연합회 홈페이지로 접수한다.
							</li>
							<li>
								<strong>나.</strong> 출전연령은 출생년도(주민등록상)를 기준으로
								한다.
							</li>
							<li>
								<strong>다.</strong> 출전연령은 하향 출전할 수 있으나, 급수는
								동일한다.{' '}
								<span>※ 본회 경기규정 제5조(출전신청) 4항, 5항 참고</span>
							</li>
							<li>
								<strong>라.</strong> 출전연령 및 급수가 잘못 신청되었을 경우에는
								참가자격을 상실한다.{' '}
								<span>※ 본회 경기규정 제5조(출전신청) 4항, 5항 참고</span>
							</li>
							<li>
								<strong>마.</strong> 급수구분은 다음에 의한다.
							</li>
							<ul>
								<li>
									(1) <strong>자강조</strong> - 고등학교 선수활동을 하였던
									회원은 자강조에만 참가하여야하며, 그 외 회원은 희망에 따라
									참가할 수 있다.
								</li>
								<li>
									(2) <strong>A급</strong> - 희망에 따라 참가할 수 있으며,
									동호인으로 전국급수 A급에 해당하는 회원
								</li>
								<li>
									(3) <strong>B급</strong> - A급이 아닌 회원
								</li>
								<li>
									(4) <strong>C급</strong> - A·B급이 아닌 회원
								</li>
								<li>
									(5) <strong>D급</strong> - 전국대회 입상경력이 없는
									회원(4팀이상 경기)
								</li>
							</ul>
							<li>
								<strong>바.</strong>{' '}
								<span>신청마감 : 2023년 10월 2일(월) 12:00</span>
							</li>
						</ul>
						<br />

						<h2>아. 구비서류</h2>
						<ul>
							<li>(1) 참가신청서(본회 소정양식에 의거 신청)</li>
							<li>
								(2) 참가비 : 1인당 100,000원 (참가비는 대표자 회의 전까지
								납부하여야 함) 숙식, 차량, 단체복, 게임비 포함
							</li>
							<li>
								<span>
									※ 참가연령 종목이 70대이상인 경우에는 참가비 면제 (70대팀이
									성립되지 않아 60대로 참가시 참가비 면제 안됨)
								</span>
							</li>
							<li>
								<span>
									※ 연령, 급수별 각 종목이 4팀 미만인 경우에는 경기성립이 되지
									않으므로 대표자 회의시 참가여부를 결정함.
								</span>
							</li>
						</ul>
					</div>
					<div className={styles.qna_answer_date}>2023.10.15</div>
					<div className={styles.divider}></div>
					<div className={styles.table_footer}>
						<Button weight="bold">등록 / 수정하기</Button>
						<div className={styles.pagination_container}>
							<PrevButton />
							<div className={styles.pagination_number}>
								<strong>39</strong>
								<span>/</span>
								<strong>39</strong>
							</div>
							<NextButton />
						</div>
						<BackToListButton onClick={() => {}} />
					</div>
				</div>
			</div>
		</div>
	);
};

function PrevButton() {
	return (
		<svg
			width="11"
			height="19"
			viewBox="0 0 11 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.4399 15.7837L3.99228 9.30843L10.4565 2.83317C10.6104 2.67906 10.7324 2.4961 10.8157 2.29475C10.8989 2.09339 10.9418 1.87758 10.9418 1.65963C10.9418 1.44169 10.8989 1.22587 10.8157 1.02452C10.7324 0.823162 10.6104 0.640205 10.4565 0.486094C10.3027 0.331983 10.12 0.209736 9.91901 0.126331C9.718 0.0429272 9.50255 -1.62382e-09 9.28497 0C9.0674 1.62382e-09 8.85196 0.0429272 8.65094 0.126331C8.44993 0.209736 8.26729 0.331983 8.11344 0.486094L0.485972 8.12657C0.331921 8.28057 0.209703 8.46348 0.126313 8.66486C0.0429239 8.86623 0 9.0821 0 9.3001C0 9.51811 0.0429239 9.73398 0.126313 9.93535C0.209703 10.1367 0.331921 10.3196 0.485972 10.4736L8.11344 18.1141C8.76152 18.7633 9.80843 18.7633 10.4565 18.1141C10.7616 17.8022 10.9311 17.3821 10.928 16.9455C10.9248 16.5088 10.7494 16.0912 10.4399 15.7837Z"
				fill="#CCCCCC"
			/>
		</svg>
	);
}

function NextButton() {
	return (
		<svg
			width="12"
			height="19"
			viewBox="0 0 12 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.653852 3.21631L7.10147 9.69157L0.637236 16.1668C0.483387 16.3209 0.361347 16.5039 0.278085 16.7053C0.194823 16.9066 0.151968 17.1224 0.151968 17.3404C0.151968 17.5583 0.194823 17.7741 0.278085 17.9755C0.361347 18.1768 0.483387 18.3598 0.637236 18.5139C0.791084 18.668 0.973727 18.7903 1.17474 18.8737C1.37575 18.9571 1.5912 19 1.80878 19C2.02635 19 2.24179 18.9571 2.44281 18.8737C2.64382 18.7903 2.82646 18.668 2.98031 18.5139L10.6078 10.8734C10.7618 10.7194 10.884 10.5365 10.9674 10.3351C11.0508 10.1338 11.0938 9.9179 11.0938 9.6999C11.0938 9.48189 11.0508 9.26602 10.9674 9.06465C10.884 8.86328 10.7618 8.68036 10.6078 8.52636L2.98031 0.885885C2.33223 0.236694 1.28532 0.236693 0.637237 0.885884C0.332164 1.19777 0.162676 1.6179 0.165789 2.05453C0.168903 2.49116 0.344362 2.90883 0.653852 3.21631Z"
				fill="#555555"
			/>
		</svg>
	);
}

export default MngCmptLQnaDetail;
