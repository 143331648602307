import React from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import SportHeader from '../../../common/SportHeader/SportHeader.tsx';
import data from './dummy.json';
import styles from './MngCmptLGameList.module.css';
import ApplyButton from '../../../common/ApplyButton/ApplyButton.tsx';
import Button from '../../../common/Button/Button.tsx';
import ListButton from '../../../common/ListButton/ListButton.tsx';
import Pagination from '../../../common/Pagination/Pagination.tsx';

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '../../../common/Table/Table.tsx';

const MngCmptLGameList = () => {
	return (
		<div>
			<MyCompetitionHeader title="대회관리" />
			<SportHeader
				title={data.title}
				date={data.date}
				views={data.views}
				participants={data.participants}
				currentCategory="세부종목"
			/>
			<div className={styles.container}>
				<div className={styles.left_section}>
					<img
						src={data.poster}
						alt="thumbnail"
						className={styles.poster}
					/>
					<ApplyButton />
				</div>
				<div className={styles.right_section}>
					<div className={styles.select_group}>
						<select
							name=""
							id=""
						>
							<option value="">구분</option>
						</select>
						<select
							name=""
							id=""
						>
							<option value="">연령</option>
						</select>
						<select
							name=""
							id=""
						>
							<option value="">급수</option>
						</select>
					</div>
					<Table>
						<TableHeader>
							<TableRow>
								<TableHead className={styles.table_head}>No.</TableHead>
								<TableHead className={styles.table_head}>구분</TableHead>
								<TableHead className={styles.table_head}>연령</TableHead>
								<TableHead className={styles.table_head}>급수</TableHead>
								<TableHead className={styles.table_head}>신청인원</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{data.map.map((item) => (
								<TableRow key={item.id}>
									<TableCell className={styles.table_head}>{item.id}</TableCell>
									<TableCell className={styles.table_head}>
										{item.type}
									</TableCell>
									<TableCell className={styles.table_head}>
										{item.age_group}
									</TableCell>
									<TableCell className={styles.table_head}>
										{item.grade}
									</TableCell>
									<TableCell className={styles.table_head}>
										{item.participants}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<div className={styles.table_footer}>
						<Button weight="bold">수정하기</Button>
						<Pagination />
						<ListButton />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MngCmptLGameList;
