import React, { useState, useEffect, useRef } from 'react';
import AdminLayout from '../../layout/_admin/AdminLayout.tsx';
import { useLocation } from 'react-router-dom';
import { API } from '../../config/config.tsx';
import { fn_axios, getCookieUserId } from '../../common/common.tsx';

function CodeManageDetailInsert() {
	const userId = getCookieUserId();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const codeMId = searchParams.get('codeMCd');

	// form
	const [codeLCd, setCodeLCd] = useState('');
	const [codeLNm, setCodeLNm] = useState('');
	const [codeLDesc, setCodeLDesc] = useState('');

	// input form
	const handleCodeLCd = (e) => {
		setCodeLCd(e.target.value);
	};
	const handleCodeLNm = (e) => {
		setCodeLNm(e.target.value);
	};
	const handleCodeLDesc = (e) => {
		setCodeLDesc(e.target.value);
	};

	// button event
	const handleBack = () => {
		window.location.href = `/admin/codeMngDetail?codeMCd=${codeMId}`;
	};
	const codeCreate = () => {
		if (codeLCd == null || codeLCd == '') {
			alert('코드내역CD를 입력해주세요.');
			return;
		}
		if (codeLNm == null || codeLNm == '') {
			alert('코드내역명을 입력해주세요.');
			return;
		}
		if (codeLDesc == null || codeLDesc == '') {
			alert('코드내역설명을 입력해주세요.');
			return;
		}

		const param = {
			method: 'POST',
			url: `${API.BACKEND}/${API.VERSION}/admin/getCreateCodeL`,
			params: {
				codeMCd: codeMId,
				codeLCd: codeLCd,
				codeLNm: codeLNm,
				codeLDesc: codeLDesc,
				userId: userId,
			},
		};
		fn_axios(param, callBackCreateCodeL, null);
		console.log(param);
	};
	const callBackCreateCodeL = (res) => {
		if (res.status === 200) {
			alert('정상적으로 등록되었습니다.');
			window.location.href = `/admin/codeMngDetail?codeMCd=${codeMId}`;
			return;
		}
		alert('코드 내역 등록에 실패하였습니다.');
		return;
	};

	return (
		<AdminLayout>
			<div className="admin_detail_contents">
				<h1>코드 내역 등록</h1>
				<section className="admin_user_info">
					<div className="admin_user_info">
						<div className="admin_form">
							<span>코드기본CD</span>
							<input
								type="text"
								value={codeMId || ''}
							/>
							<span>코드내역CD</span>
							<input
								type="text"
								value={codeLCd}
								onChange={handleCodeLCd}
							/>
							<span>코드내역명</span>
							<input
								type="text"
								value={codeLNm}
								onChange={handleCodeLNm}
							/>
							<span>코드내역설명</span>
							<input
								type="text"
								value={codeLDesc}
								onChange={handleCodeLDesc}
							/>
						</div>
					</div>
				</section>
				<div className="admin_detail_buttons">
					<button
						className="btn back"
						onClick={handleBack}
					>
						목록으로
					</button>
					<button
						className="btn update"
						onClick={codeCreate}
					>
						코드 내역 등록
					</button>
				</div>
			</div>
		</AdminLayout>
	);
}
export default CodeManageDetailInsert;
