import React, { useState, useEffect, useRef } from 'react';
import AdminLayout from '../../layout/_admin/AdminLayout.tsx';
import { useLocation } from 'react-router-dom';
import { API } from '../../config/config.tsx';
import { fn_axios, getCookieUserId } from '../../common/common.tsx';

function CodeManageInsert() {
	const userId = getCookieUserId();
	const [codeMCd, setCodeMCd] = useState('');
	const [codeMNm, setCodeMNm] = useState('');
	const [codeMDesc, setCodeMDesc] = useState('');

	// 입력 값
	const handleCodeMCd = (e) => {
		setCodeMCd(e.target.value);
	};
	const handleCodeMNm = (e) => {
		setCodeMNm(e.target.value);
	};
	const handleCodeMDesc = (e) => {
		setCodeMDesc(e.target.value);
	};

	const codeCreate = () => {
		const param = {
			method: 'POST',
			url: `${API.BACKEND}/${API.VERSION}/admin/getCreateCodeM`,
			params: {
				codeMCd: codeMCd,
				codeMNm: codeMNm,
				codeMDesc: codeMDesc,
				userId: userId,
			},
		};
		console.log(param);
		fn_axios(param, callBackCreateCodeM, null);
	};
	const callBackCreateCodeM = (res) => {
		if (res.status === 200) {
			alert('정상적으로 등록되었습니다.');
			window.location.href = '/admin/codeMng';
			return;
		} else {
			alert('코드 등록에 실패하였습니다.');
			return;
		}
	};

	const backMain = () => {
		window.location.href = '/admin/codeMng';
	};

	return (
		<AdminLayout>
			<div className="admin_detail_contents">
				<h1>코드 기본 등록</h1>
				<section className="admin_user_info">
					<div className="admin_user_info">
						<div className="admin_form">
							<span>코드기본CD </span>
							<input
								type="text"
								value={codeMCd}
								onChange={handleCodeMCd}
							/>
							<span>코드기본명 </span>
							<input
								type="text"
								value={codeMNm}
								onChange={handleCodeMNm}
							/>
							<span>코드기본설명 </span>
							<input
								type="text"
								value={codeMDesc}
								onChange={handleCodeMDesc}
							/>
						</div>
					</div>
				</section>
				<div className="admin_detail_buttons">
					<button
						className="btn back"
						onClick={backMain}
					>
						목록으로
					</button>
					<button
						className="btn update"
						onClick={codeCreate}
					>
						기본 코드 등록
					</button>
				</div>
			</div>
		</AdminLayout>
	);
}

export default CodeManageInsert;
