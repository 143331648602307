import React from 'react';

import styles from './Radio.module.css';
import clsx from 'clsx';

const Radio = (props: React.InputHTMLAttributes<HTMLInputElement>) => {
	return (
		<input
			type="radio"
			className={clsx(styles.radio, props.className)}
			{...props}
		/>
	);
};

export default Radio;
