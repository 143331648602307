import React from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import SportHeader from '../../../common/SportHeader/SportHeader.tsx';
import data from './dummy.json';
import styles from './MngCmptLQnaList.module.css';
import ApplyButton from '../../../common/ApplyButton/ApplyButton.tsx';
import Button from '../../../common/Button/Button.tsx';
import ListButton from '../../../common/ListButton/ListButton.tsx';
import Pagination from '../../../common/Pagination/Pagination.tsx';
import Input from '../../../common/Input/Input.tsx';
import Checkbox from '../../../common/Checkbox/Checkbox.tsx';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '../../../common/Table/Table.tsx';

const MngCmptLQnaList = () => {
	return (
		<div>
			<MyCompetitionHeader title="대회관리" />
			<SportHeader
				title={data.title}
				date={data.date}
				views={data.views}
				participants={data.participants}
				currentCategory="세부종목"
			/>
			<div className={styles.container}>
				<div className={styles.left_section}>
					<img
						src={data.poster}
						alt="thumbnail"
						className={styles.poster}
					/>
					<ApplyButton />
				</div>
				<div className={styles.right_section}>
					<div className={styles.result_container}>
						<div className={styles.result_count}>
							전체질문 39건 = 답변등록 31 - 미등록 8건
						</div>
						<div className={styles.select_list}>
							<select>
								<option value="all">답변상태</option>
							</select>
						</div>
					</div>
					<Table className={styles.table}>
						<TableHeader>
							<TableRow>
								<TableHead className={styles.no}>No.</TableHead>
								<TableHead className={styles.content}>내용</TableHead>
								<TableHead className={styles.nickname}>닉네임</TableHead>
								<TableHead className={styles.date}>등록일</TableHead>
								<TableHead className={styles.status}>상태</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody className={styles.table_body}>
							{data.map.map((item) => (
								<TableRow className={styles.table_row}>
									<TableCell className={styles.no}>
										<strong>{item.id}</strong>
									</TableCell>
									<TableCell className={styles.content}>
										<strong>질문: </strong>
										{item.title}
									</TableCell>
									<TableCell className={styles.nickname}>
										{item.nickname}
									</TableCell>
									<TableCell className={styles.date}>{item.date}</TableCell>
									<TableCell className={styles.status}>{item.status}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<div className={styles.table_footer}>
						<Pagination />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MngCmptLQnaList;
