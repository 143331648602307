import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import AdminLyout from '../../layout/_admin/AdminLayout.tsx';

import { fn_axios, getCookieUserId } from '../../common/common.tsx';
import { API } from '../../config/config.tsx';

function GymManage() {
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);

	const [pageNum, setPageNum] = useState(
		queryParams.get('pageNum') == null || queryParams.get('pageNum') == 'null'
			? 1
			: parseInt(queryParams.get('pageNum')),
	);
	const [pageMaxNum, setPageMaxNum] = useState(0);
	const [pageCnt] = useState(10);
	const [paging, setPaging] = useState([]);
	const [gymCnt, setGymCnt] = useState(null);
	const [searchKeyword, setSearchKeyword] = useState(
		queryParams.get('searchKeyword') == null ||
			queryParams.get('searchKeyword') == 'null'
			? ''
			: queryParams.get('searchKeyword'),
	);
	const [sidoList, setSidoList] = useState([]);
	const [signguList, setSignguList] = useState([]);
	const [signguDataList, setSignguDataList] = useState([]);
	const [selectedSido, setSelectedSido] = useState(
		queryParams.get('spSignguCd') == null ||
			queryParams.get('spSignguCd') == 'null'
			? ''
			: queryParams.get('spSignguCd'),
	);
	const [selectedSigngu, setSelectedSigngu] = useState(
		queryParams.get('lareaAddrId') == null ||
			queryParams.get('lareaAddrId') == 'null'
			? ''
			: queryParams.get('lareaAddrId'),
	);

	const [gymTypeCdList, setGymTypeCdList] = useState([]);
	const [selectedGymTypeCd, setSelectedGymTypeCd] = useState(
		queryParams.get('gymTypeCd') == null ||
			queryParams.get('gymTypeCd') == 'null'
			? ''
			: queryParams.get('gymTypeCd'),
	);

	const [gymList, setGymList] = useState([]);
	const [selectGym, setSelectGym] = useState(null);

	const onClickSearch = () => {
		if (pageNum == 1) {
			getGymPaging(pageNum);
		} else {
			setPageNum(1);
		}
	};

	// 지역조회
	const getLareAddr = () => {
		const param = {
			method: 'GET',
			url: `${API.BACKEND}/${API.VERSION}/lareaAddr/getLareaAddr`,
			params: null,
		};
		fn_axios(param, callBackGetLareaAddr, null);
	};
	const callBackGetLareaAddr = (res) => {
		const resList = res.data.object;
		const tempList = resList.filter(
			(item) => (item.signguNm || '').trim() === '',
		);
		setSignguDataList(resList);
		setSidoList(tempList);

		if (queryParams.get('spSignguCd')) {
			setSignguList(
				resList.filter(
					(item) =>
						item.spSignguCd === queryParams.get('spSignguCd') &&
						(item.signguNm || '').trim() !== '',
				),
			);
		}
	};

	// 체육관 코드 조회
	const getCode = () => {
		const param = {
			method: 'GET',
			url: `${API.BACKEND}/${API.VERSION}/code/getCode`,
			params: {
				codeMCd: 'GYM_TYPE_CD', // 체육관 종류 코드
				orderColum: 'code_l_nm',
			},
		};
		fn_axios(param, callBackGetCcode, null);
	};
	const callBackGetCcode = (res) => {
		console.log(res);
		setGymTypeCdList(res.data.object);
	};

	const handleSidoChange = (e) => {
		setSelectedSido(e.target.value);
		setSelectedSigngu('');
		setSignguList(
			signguDataList.filter(
				(item) =>
					item.spSignguCd === e.target.value &&
					(item.signguNm || '').trim() !== '',
			),
		);
		return;
	};
	const handleSignguChange = (e) => {
		setSelectedSigngu(e.target.value);
	};
	const handleGymTypeCd = (e) => {
		setSelectedGymTypeCd(e.target.value);
	};
	const handleSearchKeyword = (e) => {
		setSearchKeyword(e.target.value);
	};

	const getGymPaging = async (num: number) => {
		const param = {
			method: 'GEt',
			url: `${API.BACKEND}/${API.VERSION}/admin/getGymPaging`,
			params: {
				pageNum: num,
				searchKeyword: searchKeyword,
				lareaAddrId: selectedSigngu,
				spSignguCd: selectedSido,
				gymTypeCd: selectedGymTypeCd,
			},
		};
		fn_axios(param, callBackGym, null);
	};
	const callBackGym = (res) => {
		setGymList(res.data.object);
		setGymCnt(res.data.map.gymCnt);
		console.log(res);
		var tempInt = parseInt(res.data.map.codeCnt % pageCnt);
		if (tempInt != 0) {
			tempInt = parseInt(res.data.map.codeCnt / pageCnt) + 1;
		} else {
			tempInt = parseInt(res.data.map.codeCnt / pageCnt);
		}
		settingPasing(tempInt);
	};

	const settingPasing = (maxNum) => {
		setPageMaxNum(parseInt(maxNum));
		// var start = (pageNum > 2 ? ( (maxNum - pageNum > 4) ? pageNum - 2 : maxNum - pageNum) : 1);
		var start =
			maxNum <= 5 || pageNum <= 2
				? 1
				: pageNum - (maxNum - pageNum <= 2 ? 4 - (maxNum - pageNum) : 2);
		var end =
			maxNum <= 5 || maxNum - pageNum <= 2
				? maxNum
				: (pageNum < 3 ? 3 : pageNum) + 2;
		var tempList = [];

		if (maxNum != 0) {
			for (var i = start; i <= end; i++) {
				tempList.push(i);
			}
		}
		setPaging(tempList);
	};

	const handleCheck = (code) => {
		if (selectGym === code) {
			setSelectGym(null);
		} else {
			setSelectGym(code);
		}
	};

	const handleDetailClick = () => {
		if (!selectGym) {
			alert('체육관을 선택해 주세요.');
			return;
		}
		window.location.href = `/admin/gymMngDetail?gymId=${selectGym}&pageNum=${pageNum}&lareaAddrId=${selectedSigngu}&spSignguCd=${selectedSido}&searchKeyword=${searchKeyword}&gymTypeCd=${selectedGymTypeCd}`;
	};

	useEffect(() => {
		getGymPaging(pageNum);
	}, [pageNum]);
	useEffect(() => {
		if (sidoList == null || sidoList.length == 0) {
			getLareAddr();
		}
		if (gymTypeCdList == null || gymTypeCdList.length == 0) {
			getCode();
		}
	}, []);

	return (
		<AdminLyout>
			<div>
				<h1 className="admin_title">체육관 관리</h1>
				<section className="admin_list">
					<div className="admin_bar">
						<div className="admin_total">
							{' '}
							총 <b>{gymCnt == null ? '0' : gymCnt}</b> 건{' '}
						</div>
						<div className="admin_search">
							<select
								value={selectedSido}
								onChange={handleSidoChange}
							>
								<option value="">지역(시)</option>
								{sidoList.map((item, index) => (
									<option
										key={index}
										value={item.spSignguCd}
									>
										{item.spSignguNm}
									</option>
								))}
							</select>
							<select
								value={selectedSigngu}
								onChange={handleSignguChange}
							>
								<option value="">지역(구)</option>
								{signguList.map((item, index) => (
									<option
										key={index}
										value={item.lareaAddrId}
									>
										{item.signguNm}
									</option>
								))}
							</select>
							<select
								value={selectedGymTypeCd}
								onChange={handleGymTypeCd}
							>
								<option value="">종류</option>
								{gymTypeCdList.map((item, index) => (
									<option
										key={index}
										value={item.codeLCd}
									>
										{item.codeLNm}
									</option>
								))}
							</select>
							<input
								type="text"
								style={{ minWidth: '90px' }}
								name="keyword"
								value={searchKeyword}
								onChange={handleSearchKeyword}
								placeholder="키워드"
							/>
							<button onClick={onClickSearch}>검색</button>
						</div>
					</div>
					<div
						className="admin_table type1"
						style={{ overflow: 'auto' }}
					>
						<table
							style={{
								borderCollapse: 'collapse',
								width: '100%',
								tableLayout: 'auto',
							}}
						>
							<thead>
								<tr>
									<th>선택</th>
									<th>체육관명</th>
									<th>주소</th>
									<th>체육관종류</th>
									<th>연락처</th>
									<th>등록일시</th>
									<th>수정일시</th>
								</tr>
							</thead>
							<tbody>
								{gymList.length === 0 ? (
									<tr>
										<td
											style={{ textAlign: 'center' }}
											colSpan={7}
										>
											검색된 결과가 없습니다.
										</td>
									</tr>
								) : (
									gymList.map((item, index) => (
										<tr style={{ cursor: 'pointer' }}>
											<td>
												<input
													type="checkbox"
													checked={selectGym === item.gymId}
													onChange={() => handleCheck(item.gymId)}
												/>
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.gymNm}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.lareaAddrNm}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.gymTypeNm}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.gymCadr}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.regrDtm}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.updateDtm}
											</td>
										</tr>
									))
								)}
							</tbody>
						</table>
					</div>
					<div className="paging">
						{paging.map((num) => (
							<a
								key={num}
								onClick={() => setPageNum(num)}
							>
								{num}
							</a>
						))}
					</div>
					<div className="admin_table_btn">
						<button
							className="admin_insert_btn"
							style={{ margin: '20px' }}
						>
							등록
						</button>
						<button
							className="admin_detail_btn"
							style={{ margin: '20px' }}
							onClick={handleDetailClick}
						>
							상세
						</button>
					</div>
				</section>
			</div>
		</AdminLyout>
	);
}

export default GymManage;
