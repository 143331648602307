import React from 'react';
import styles from './ListButton.module.css';

const ListButton = () => {
	return (
		<div className={styles.list_button}>
			<p>목록으로 돌아가기</p>
			<MenuIcon />
		</div>
	);
};

function MenuIcon() {
	return (
		<svg
			width="19"
			height="17"
			viewBox="0 0 19 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="19"
				height="3"
				fill="#777777"
			/>
			<rect
				y="7"
				width="19"
				height="3"
				fill="#777777"
			/>
			<rect
				y="14"
				width="19"
				height="3"
				fill="#777777"
			/>
		</svg>
	);
}

export default ListButton;
