import React from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import MasterSearch from '../../../common/MasterSearch/MasterSearch.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';
import data from './dummy.json';
import styles from './MngGymList.module.css';
import Checkbox from '../../../common/Checkbox/Checkbox.tsx';
import Button from '../../../common/Button/Button.tsx';
import Pagination from '../../../common/Pagination/Pagination.tsx';
import AssociationSearch from '../../../common/AssociationSearch/AssociationSearch.tsx';
import CourtSearch from '../../../common/CourtSearch/CourtSearch.tsx';

const MngGymList = () => {
	return (
		<div>
			<MyCompetitionHeader title="체육관" />
			<CourtSearch />
			<Table>
				<TableHeader>
					<TableRow>
						<TableHead className={styles.check_box}>
							<Checkbox />
						</TableHead>
						<TableHead className={styles.number}>No.</TableHead>
						<TableHead className={styles.type}>유형</TableHead>
						<TableHead className={styles.name}>체육관 이름</TableHead>
						<TableHead className={styles.address}>주소</TableHead>
						<TableHead className={styles.capacity}>수용인원(명)</TableHead>
						<TableHead className={styles.facilities}>부대시설(개)</TableHead>
						<TableHead className={styles.date}>연락처</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody className={styles.table_body}>
					{data.object.map((item) => (
						<TableRow className={styles.table_row}>
							<TableCell className={styles.check_box}>
								<Checkbox />
							</TableCell>
							<TableCell className={styles.number}>{item.number}</TableCell>
							<TableCell className={styles.type}>{item.type}</TableCell>
							<TableCell className={styles.name}>{item.name}</TableCell>
							<TableCell className={styles.address}>{item.address}</TableCell>
							<TableCell className={styles.capacity}>{item.capacity}</TableCell>
							<TableCell className={styles.facilities}>
								{item.facilities}
							</TableCell>
							<TableCell className={styles.contact}>{item.contact}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<div className={styles.table_footer}>
				<Button weight="bold">선택항목 삭제</Button>
				<Pagination />
				<div className={styles.blank_div}></div>
			</div>
		</div>
	);
};

export default MngGymList;
