import React, { useState } from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import MasterSearch from '../../../common/MasterSearch/MasterSearch.tsx';
import styles from './MngCmptLRegItem.module.css';
import Input from '../../../common/Input/Input.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';
import SportRegistrationStep from '../../../common/SportRegistrationStep/SportRegistrationStep.tsx';
import clsx from 'clsx';
import Checkbox from '../../../common/Checkbox/Checkbox.tsx';

import HTMLEditor from '../../../common/HTMLEditor/HTMLEditor.tsx';
import FileInput from '../../../common/FileInput/FileInput.tsx';
import Button from '../../../common/Button/Button.tsx';
import ArrowRight from '../../../common/Icons/ArrowRight.tsx';
import Modal from '../../../common/Modal/Modal.tsx';
import ArrowLeft from '../../../common/Icons/ArrowLeft.tsx';

const years = Array.from({ length: 2025 - 1900 + 1 }, (_, i) =>
	(1900 + i).toString(),
);

const MngCmptLRegItem = () => {
	return (
		<div>
			<MyCompetitionHeader title="대회마스터" />
			<div className={styles.container}>
				<div className={styles.left_section}>
					<div className={styles.sub_title}>
						<h1>신규대회 등록</h1>
						<p>
							아래의 순서에 따라
							<br />
							개최하고 싶은 대회의
							<br />
							정보를 입력하시면
							<br />
							해당 대회를 등록하실 수 있습니다.
						</p>
					</div>
					<SportRegistrationStep step={2} />
				</div>
				<div className={styles.right_section}>
					<div className={styles.title}>
						<h1>3. 경품/기념품 등록</h1>
					</div>
					<p className={styles.description}>
						경품 및 기념품 이미지 + 내용글의 순서로 구성된 기본 모듈을 추가하며
						전체 내용을 등록하실 수 있습니다.
					</p>
					<div className={styles.divider}></div>
					<div className={styles.prize_type_container}>
						<select>
							<option value="prize">경품</option>
						</select>
						<FileInput className={styles.prize_file_input} />
					</div>
					<p className={styles.prize_file_description}>
						상품이미지 등록 : 가로 1024 픽셀, 세로 2000 이하 픽셀의 JPG 포맷 /
						다른 크기의 경우 이미지가 왜곡 될 수 있습니다.
					</p>
					<div className={styles.prize_title_container}>
						<Input
							className={styles.prize_title_input}
							placeholder="상품명"
						/>
						<Input
							className={styles.prize_quantity}
							placeholder="상품수량 (숫자만)"
							type="number"
						/>
					</div>
					<HTMLEditor
						value=""
						onChange={() => {}}
					/>
					<div className={styles.description_control_button_row}>
						<div className={styles.description_control_button}>
							<button className={styles.control_button}>-</button>
							이미지 / 내용 삭제하기
						</div>
						<div className={styles.description_control_button}>
							이미지 / 내용 추가하기
							<button className={styles.control_button}>+</button>
						</div>
					</div>
					<div className={styles.divider} />
					<div className={styles.table_footer}>
						<span className={styles.pagination_button}>
							<ArrowLeft
								width={24}
								height={24}
							/>
							이전단계로 이동
						</span>
						<div className={styles.pagination}>
							<div className={styles.footer_progress}>
								<strong>3</strong>
								<span> / </span>
								<span>5</span>
							</div>
						</div>
						<Button
							weight="bold"
							suffixIcon={<ArrowRight />}
						>
							저장 및 다음단계로 이동
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MngCmptLRegItem;
