import React from 'react';
import Input from '../../../common/Input/Input.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';
import styles from './MngGroupMain.module.css';
import defaultThumbnail from '../../../assets/images/common/default_thumbnail.png';
import Button from '../../../common/Button/Button.tsx';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import AssociationSearch from '../../../common/AssociationSearch/AssociationSearch.tsx';
import clsx from 'clsx';
import ArrowRight from '../../../common/Icons/ArrowRight.tsx';
import BackToListButton from '../../../common/BackToListButton/BackToListButton.tsx';
import ArrowLeft from '../../../common/Icons/ArrowLeft.tsx';
import Checkbox from '../../../common/Checkbox/Checkbox.tsx';
import data from './dummy.json';
import Pagination from '../../../common/Pagination/Pagination.tsx';

const MngGroupMain = () => {
	return (
		<div>
			<MyCompetitionHeader title="기관/단체/업체" />
			<div className={styles.container}>
				<div className={styles.left_section}>
					<img
						src={defaultThumbnail}
						alt="thumbnail"
						className={styles.default_thumbnail}
					/>
				</div>
				<div className={styles.right_section}>
					<div className={styles.title_container}>
						<h1 className={styles.title}>유한회사 나이키 코리아</h1>
						<BackToListButton onClick={() => {}} />
					</div>
					<form
						action=""
						className={styles.confirm}
					>
						<div className={styles.confirm_row}>
							<div className={styles.confirm_label}>단체구분</div>
							<div className={styles.confirm_value}>기업</div>
						</div>
						<div className={styles.divided_confirm_row}>
							<div className={styles.divided_confirm_row_left}>
								<div className={styles.confirm_label}>사업자번호</div>
								<div className={styles.confirm_value}>123 - 45 - 67890</div>
							</div>
							<div className={styles.divided_confirm_row_right}>
								<div className={styles.confirm_label}>사업자 등록증</div>
								<div className={styles.confirm_value}>
									<a
										download={true}
										href=""
									>
										나이키코리아 사업자등록증2024.jpg
									</a>
								</div>
							</div>
						</div>
						<div className={styles.divided_confirm_row}>
							<div className={styles.divided_confirm_row_left}>
								<div className={styles.confirm_label}>대표 연락처</div>
								<div className={styles.confirm_value}>010-3342-2189</div>
							</div>
							<div className={styles.divided_confirm_row_right}>
								<div className={styles.confirm_label}>대표 이메일</div>
								<div className={styles.confirm_value}>asdboc2024@naver.com</div>
							</div>
						</div>
						<div className={styles.confirm_row}>
							<div className={styles.confirm_label}>주소</div>
							<div className={clsx(styles.confirm_value, styles.address)}>
								<p>서울특별시 강남구 테헤란로 123길 456번지</p>
								<p>우리그라운드빌딩 15층</p>
							</div>
						</div>
						<div className={styles.confirm_row}>
							<div className={styles.confirm_label}>웹사이트 URL</div>
							<div className={styles.confirm_value}>www.woorigraound.com</div>
						</div>
					</form>
					<div className={styles.confirm_footer}>
						<Button
							weight="bold"
							className={styles.edit_button}
							prefixIcon={<ArrowLeft fill="#fff" />}
						>
							수정하기
						</Button>
						<p className={styles.caution}>
							단체 정보가 간편 등록 상태이면 상세한 정보가 없습니다. <br />
							단체 정보를 수정하고 싶은 경우 단체 권한 신청을 해주시기 바랍니다.
						</p>
						<button className={styles.register_button}>
							단체 권한 신청하기
						</button>
					</div>
				</div>
			</div>
			<Table>
				<TableHeader>
					<TableRow>
						<TableHead className={styles.number}>No.</TableHead>
						<TableHead className={styles.name}>마스터 대회명</TableHead>
						<TableHead className={styles.status}>상태</TableHead>
						<TableHead className={styles.location}>데회장소</TableHead>
						<TableHead className={styles.date_range}>대회기간</TableHead>
						<TableHead className={styles.date}>등록일</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody className={styles.table_body}>
					{data.object.map((item) => (
						<TableRow className={styles.table_row}>
							<TableCell className={styles.number}>{item.number}</TableCell>
							<TableCell className={styles.name}>{item.title}</TableCell>
							<TableCell className={styles.status}>{item.status}</TableCell>
							<TableCell className={styles.location}>{item.location}</TableCell>
							<TableCell className={styles.date_range}>
								{item.date_range}
							</TableCell>
							<TableCell className={styles.date}>{item.date}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<div className={styles.table_footer}>
				<Pagination />
			</div>
		</div>
	);
};

export default MngGroupMain;
