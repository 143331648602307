import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import AdminLyout from '../../layout/_admin/AdminLayout.tsx';

import { fn_axios, getCookieUserId } from '../../common/common.tsx';
import { API } from '../../config/config.tsx';
import KakaoMap from '../../config/KakaoMap.tsx';

function GymManageDetail() {
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const gymId = queryParams.get('gymId');
	// const pageNum = queryParams.get('pageNum');
	// const lareaAddrId = queryParams.get('lareaAddrId');
	// const spSignguCd  = queryParams.get('spSignguCd');
	// const searchKeyword = queryParams.get('searchKeyword');
	// const gymTypeCd = queryParams.get('gymTypeCd');
	// const gymLttd = queryParams.get('gymLttd');
	// const gymLgtd = queryParams.get('gymLgtd');
	// 체육관 정보
	const [gymNm, setGymNm] = useState('');
	const [dtlAddr, setDtlAddr] = useState('');
	const [gymTypeNm, setGymTypeNm] = useState('');
	const [gymCadr, setGymCadr] = useState('');
	const [gymRspsb, setGymRspsb] = useState('');
	const [gymHmpg, setGymHpmg] = useState('');
	const [accmdPerson, setAccmdPerson] = useState('');
	const [cortNum, setCortNum] = useState('');
	const [seatNum, setSeatNum] = useState('');
	//체육관 이미지 정보
	const [atchFileId, setAtchFileId] = useState('');
	const [atchFileUrl, setAtchFileUrl] = useState('');
	const [realAtchFileNm, setRealAtchFileNm] = useState('');

	const [gym, setGym] = useState(null);
	const [gymPictList, setGymPictList] = useState([]);

	const kakakoMapApi = process.env.REACT_APP_KAKAO_MAPS_KEY;

	const getGymDetail = () => {
		const param = {
			method: 'GET',
			url: `${API.BACKEND}/${API.VERSION}/admin/getGymDetail`,
			params: { gymId: gymId },
		};
		fn_axios(param, callBackGetGymDetail, null);
	};
	const callBackGetGymDetail = (res) => {
		console.log(res);
		// setGym(res.data.object);
		setGymPictList(res.data.map.gymPictList);
		const gymVo = res.data.object;
		setGymNm(gymVo.gymNm);
		setDtlAddr(gymVo.dtlAddr);
		setGymTypeNm(gymVo.gymTypeNm);
		setGymCadr(gymVo.gymCadr);
		setGymRspsb(gymVo.gymRspsb);
		setGymHpmg(gymVo.gymHmpg);
		setAccmdPerson(gymVo.accmdPerson);
		setCortNum(gymVo.cortNum);
		setSeatNum(gymVo.seatNum);
	};

	useEffect(() => {
		getGymDetail();
	}, []);

	return (
		<AdminLyout>
			<div className="admin_detail_contents">
				<h1 className="admin_title">체육관 상세</h1>
				<h1 className="admin_sub_title"></h1>
				<section className="admin_gym_detail_container">
					{/* 왼쪽 이미지 세션 */}
					<div className="admin_gym_image">
						<div
							id="my-keen-slider7"
							className="keen-slider slide_box"
						>
							{gymPictList == null || gymPictList.length === 0 ? (
								<div className="slide01 keen-slider__slide number-slide1">
									<img
										src="/assets/images/02_ground/ground_slide_img01.jpg"
										alt=""
									/>
								</div>
							) : (
								gymPictList.map((item, index) => (
									<div
										key={index}
										className={`slide01 keen-slider__slide number-slide${index + 1
											}`}
									>
										<img
											src={item.atchFileUrl}
											alt={item.realAtchFileNm}
										/>
									</div>
								))
							)}
						</div>
					</div>

					{/* 오른쪽 세션 */}
					<div className="admin_gym_info">
						<div className="admin_gym_group">
							<label htmlFor="gymNm">
								<strong>체육관 이름 : </strong>
							</label>
							<input
								type="text"
								id="gymNm"
								value={gymNm}
							/>
						</div>
						<div className="admin_gym_group">
							<label htmlFor="dtlAddr">
								<strong>주소 : </strong>
							</label>
							<input
								type="text"
								id="dtlAddr"
								value={dtlAddr}
							/>
						</div>
						<div className="admin_gym_group">
							<label htmlFor="gymTypeNm">
								<strong>체육관 우형 : </strong>
							</label>
							<input
								type="text"
								id="gymNm"
								value={gymTypeNm}
							/>
						</div>
						<div className="admin_gym_group">
							<label htmlFor="gymTypeNm">
								<strong>연락처 : </strong>
							</label>
							<input
								type="text"
								id="gymNm"
								value={gymCadr}
							/>
						</div>
						<div className="admin_gym_group">
							<label htmlFor="gymTypeNm">
								<strong>담당부서 : </strong>
							</label>
							<input
								type="text"
								id="gymNm"
								value={gymRspsb}
							/>
						</div>
						<div className="admin_gym_group">
							<label htmlFor="gymTypeNm">
								<strong>홈페이지 : </strong>
							</label>
							<input
								type="text"
								id="gymNm"
								value={gymHmpg}
							/>
						</div>
						<div className="admin_gym_group">
							<label htmlFor="gymTypeNm">
								<strong>수용인원 : </strong>
							</label>
							<input
								type="text"
								id="gymNm"
								value={accmdPerson}
							/>
						</div>
						<div className="admin_gym_group">
							<label htmlFor="gymTypeNm">
								<strong>코트수 : </strong>
							</label>
							<input
								type="text"
								id="gymNm"
								value={cortNum}
							/>
						</div>
						<div className="admin_gym_group">
							<label htmlFor="gymTypeNm">
								<strong>관람석 : </strong>
							</label>
							<input
								type="text"
								id="gymNm"
								value={seatNum}
							/>
						</div>
						<div className="admin_gym_group">
							<label htmlFor="gymTypeNm">
								<strong>이용금액 : </strong>
							</label>
							<input
								type="text"
								id="gymNm"
								value={gymTypeNm}
							/>
						</div>
						<div className="admin_gym_group">
							<label htmlFor="gymTypeNm">
								<strong>주차시설 : </strong>
							</label>
							<input
								type="text"
								id="gymNm"
								value="정보없음"
							/>
						</div>
						<div className="admin_gym_group">
							<label htmlFor="gymTypeNm">
								<strong>부대시설 : </strong>
							</label>
							<input
								type="text"
								id="gymNm"
								value="정보없음"
							/>
						</div>
					</div>

					{/* 화면 밑 */}
					<div className="admin_map">
						<KakaoMap
							apiKey={kakakoMapApi}
							latitude={gym == null ? '' : gym.gymLttd}
							longitube={gym == null ? '' : gym.gymLgtd}
							address={dtlAddr}
						></KakaoMap>
					</div>
				</section>
			</div>
		</AdminLyout>
	);
}

export default GymManageDetail;
