import React from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import SportHeader from '../../../common/SportHeader/SportHeader.tsx';
import data from './dummy.json';
import styles from './MngCmptLNotice.module.css';
import ApplyButton from '../../../common/ApplyButton/ApplyButton.tsx';
import Button from '../../../common/Button/Button.tsx';
import ListButton from '../../../common/ListButton/ListButton.tsx';
import Pagination from '../../../common/Pagination/Pagination.tsx';
import Input from '../../../common/Input/Input.tsx';
import Checkbox from '../../../common/Checkbox/Checkbox.tsx';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '../../../common/Table/Table.tsx';

const MngCmptLNotice = () => {
	return (
		<div>
			<MyCompetitionHeader title="대회관리" />
			<SportHeader
				title={data.title}
				date={data.date}
				views={data.views}
				participants={data.participants}
				currentCategory="세부종목"
			/>
			<div className={styles.container}>
				<div className={styles.left_section}>
					<img
						src={data.poster}
						alt="thumbnail"
						className={styles.poster}
					/>
					<ApplyButton />
				</div>
				<div className={styles.right_section}>
					<div className={styles.search_container}>
						<div className={styles.search}>
							<Input type="text" />
							<Button weight="bold">제목검색</Button>
						</div>
						<div className={styles.register_button}>공지사항에 글 등록하기</div>
					</div>
					<Table>
						<TableHeader>
							<TableRow>
								<TableHead className={styles.check_box}>
									<Checkbox />
								</TableHead>
								<TableHead className={styles.title}>제목</TableHead>
								<TableHead className={styles.date}>등록일</TableHead>
								<TableHead className={styles.views}>조회수</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody className={styles.table_body}>
							{data.map.map((item) => (
								<TableRow className={styles.table_row}>
									<TableCell className={styles.check_box}>
										<Checkbox />
									</TableCell>
									<TableCell className={styles.title}>{item.title}</TableCell>
									<TableCell className={styles.date}>{item.date}</TableCell>
									<TableCell className={styles.views}>{item.views}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<div className={styles.table_footer}>
						<Button weight="bold">수정하기</Button>
						<Pagination />
						<ListButton />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MngCmptLNotice;
