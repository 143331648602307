import React from 'react';
import Input from '../Input/Input.tsx';
import Button from '../Button/Button.tsx';
import styles from './CourtSearch.module.css';
import excelIcon from '../../assets/images/common/excel.png';

interface CourtSearchProps {
	onClickSaveButton?: () => void;
}

const CourtSearch = ({ onClickSaveButton }: CourtSearchProps) => {
	return (
		<div className={styles.search_box}>
			<select
				name=""
				id=""
				className={styles.filter_select}
			>
				<option value="1">전체유형</option>
			</select>
			<select
				name=""
				id=""
				className={styles.filter_select}
			>
				<option value="1">지역선택 : 도 / 시 / 군</option>
			</select>
			<Input
				type="text"
				placeholder="이름 검색"
				className={styles.filter_search_input}
			/>
			<Button
				weight="bold"
				className={styles.search_button}
			>
				검색
			</Button>
			<button
				className={styles.save_button}
				onClick={onClickSaveButton}
			>
				현재 리스트 엑셀파일로 저장
				<img
					src={excelIcon}
					alt="excel"
					className={styles.save_button_icon}
				/>
			</button>
			<button className={styles.register_button}>
				<span className={styles.register_button_text}>체육관 등록하기</span>
				....
				<svg
					width="23"
					height="24"
					viewBox="0 0 23 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M22.2594 9.32866L13.0652 0.620471L13.0468 0.603164C12.6242 0.21518 12.0722 0 11.4994 0C10.9267 0 10.3746 0.21518 9.9521 0.603164L9.93255 0.620471L0.7406 9.32866C0.507495 9.54505 0.321406 9.80738 0.193973 10.0992C0.0665393 10.3911 0.000504657 10.7062 0 11.0248V21.6923C0 22.3043 0.242321 22.8913 0.673654 23.3241C1.10499 23.7569 1.69 24 2.3 24H7.82C8.43 24 9.01501 23.7569 9.44635 23.3241C9.87768 22.8913 10.12 22.3043 10.12 21.6923V16.6153H12.88V21.6923C12.88 22.3043 13.1223 22.8913 13.5537 23.3241C13.985 23.7569 14.57 24 15.18 24H20.7C21.31 24 21.895 23.7569 22.3263 23.3241C22.7577 22.8913 23 22.3043 23 21.6923V11.0248C22.9995 10.7062 22.9335 10.3911 22.806 10.0992C22.6786 9.80738 22.4925 9.54505 22.2594 9.32866ZM20.24 21.2307H15.64V16.1537C15.64 15.8507 15.5805 15.5506 15.4649 15.2706C15.3493 14.9906 15.1799 14.7362 14.9663 14.5219C14.7528 14.3076 14.4992 14.1377 14.2202 14.0217C13.9411 13.9057 13.642 13.846 13.34 13.846H9.66C9.05 13.846 8.46499 14.0892 8.03365 14.5219C7.60232 14.9547 7.36 15.5417 7.36 16.1537V21.2307H2.76V11.2245L11.5 2.9455L20.24 11.2245V21.2307Z"
						fill="#111111"
					/>
				</svg>
			</button>
		</div>
	);
};

export default CourtSearch;
