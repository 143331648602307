import React from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import SportHeader from '../../../common/SportHeader/SportHeader.tsx';
import data from './dummy.json';
import styles from './MngCmptLNoticeDetail.module.css';
import ApplyButton from '../../../common/ApplyButton/ApplyButton.tsx';
import Button from '../../../common/Button/Button.tsx';
import ListButton from '../../../common/ListButton/ListButton.tsx';
import Pagination from '../../../common/Pagination/Pagination.tsx';
import Input from '../../../common/Input/Input.tsx';
import Checkbox from '../../../common/Checkbox/Checkbox.tsx';

const MngCmptLNoticeDetail = () => {
	return (
		<div>
			<MyCompetitionHeader title="대회관리" />
			<SportHeader
				title={data.title}
				date={data.date}
				views={data.views}
				participants={data.participants}
				currentCategory="경품/기념품"
			/>
			<div className={styles.container}>
				<div className={styles.left_section}>
					<img
						src={data.poster}
						alt="thumbnail"
						className={styles.poster}
					/>
					<ApplyButton />
				</div>
				<div className={styles.right_section}>
					<div className={styles.notice_content}>
						<div
							className={styles.notice_content_text}
							dangerouslySetInnerHTML={{ __html: data.content }}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

function CommentIcon() {
	return (
		<svg
			width="26"
			height="19"
			viewBox="0 0 26 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={styles.comment_icon}
		>
			<mask
				id="mask0_6508_2005"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="26"
				height="19"
			>
				<path
					d="M16.0379 16.9155C15.8303 17.1097 15.5569 17.2177 15.2728 17.2178L2.81328 17.2178C2.51574 17.2178 2.23038 17.0994 2.01999 16.8887C1.8096 16.678 1.6914 16.3922 1.6914 16.0942L1.6914 2.61098C1.6914 2.31299 1.8096 2.02719 2.01999 1.81648C2.23038 1.60576 2.51574 1.48738 2.81328 1.48738L15.2728 1.48739C15.5569 1.48749 15.8303 1.59551 16.0379 1.78963L24.1289 9.35258L16.0379 16.9155Z"
					fill="white"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M15.2725 18.3413L2.8125 18.3413C2.21742 18.3413 1.64671 18.1045 1.22593 17.6831C0.80514 17.2617 0.568747 16.6901 0.568747 16.0941L0.568748 2.61092C0.568748 2.01493 0.805141 1.44334 1.22593 1.02191C1.64671 0.600473 2.21742 0.36372 2.8125 0.36372L15.2725 0.363721C15.8406 0.363937 16.3874 0.579963 16.8027 0.968215L24.8936 8.53109C25.1209 8.74359 25.25 9.04107 25.25 9.35252C25.25 9.66396 25.1209 9.96144 24.8936 10.1739L16.8027 17.7368C16.8027 17.7368 16.8027 17.7368 16.8027 17.7368C16.3874 18.125 15.8405 18.3411 15.2725 18.3413ZM15.2717 16.094L22.4839 9.35251L15.2717 2.61099L2.8125 2.61092L2.8125 16.0941L15.2717 16.094Z"
					fill="white"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M12.5816 12.9561C12.1434 13.3949 11.4331 13.3949 10.995 12.9561L8.98359 10.9416L6.97219 12.9561C6.53407 13.3949 5.82374 13.3949 5.38562 12.9561C4.9475 12.5173 4.9475 11.8058 5.38562 11.3671L7.39702 9.35256L5.38562 7.33807C4.9475 6.89927 4.9475 6.18785 5.38562 5.74906C5.82374 5.31026 6.53407 5.31026 6.97219 5.74906L8.98359 7.76355L10.995 5.74906C11.4331 5.31026 12.1434 5.31026 12.5816 5.74906C13.0197 6.18785 13.0197 6.89927 12.5816 7.33807L10.5702 9.35256L12.5816 11.3671C13.0197 11.8058 13.0197 12.5173 12.5816 12.9561Z"
					fill="black"
				/>
			</mask>
			<g mask="url(#mask0_6508_2005)">
				<path
					d="M33.0586 22.656L-25.6379 22.656L-25.6379 -11.5913L33.0586 -11.5913L33.0586 22.656Z"
					fill="#111111"
				/>
			</g>
		</svg>
	);
}

function Arrow(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="11"
			height="19"
			viewBox="0 0 11 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M10.4399 15.7837L3.99228 9.30843L10.4565 2.83317C10.6104 2.67906 10.7324 2.4961 10.8157 2.29475C10.8989 2.09339 10.9418 1.87758 10.9418 1.65963C10.9418 1.44169 10.8989 1.22587 10.8157 1.02452C10.7324 0.823162 10.6104 0.640205 10.4565 0.486094C10.3027 0.331983 10.12 0.209736 9.91901 0.126331C9.718 0.0429272 9.50255 -1.62382e-09 9.28497 0C9.0674 1.62382e-09 8.85196 0.0429272 8.65094 0.126331C8.44993 0.209736 8.26729 0.331983 8.11344 0.486094L0.485972 8.12657C0.331921 8.28057 0.209703 8.46348 0.126313 8.66486C0.0429239 8.86623 0 9.0821 0 9.3001C0 9.51811 0.0429239 9.73398 0.126313 9.93535C0.209703 10.1367 0.331921 10.3196 0.485972 10.4736L8.11344 18.1141C8.76152 18.7633 9.80843 18.7633 10.4565 18.1141C10.7616 17.8022 10.9311 17.3821 10.928 16.9455C10.9248 16.5088 10.7494 16.0912 10.4399 15.7837Z"
				fill="#CCCCCC"
			/>
		</svg>
	);
}

export default MngCmptLNoticeDetail;
