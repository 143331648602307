import React from 'react';
import styles from './SportHeader.module.css';
import clsx from 'clsx';
import ListButton from '../ListButton/ListButton.tsx';

const categories = [
	'대회요강',
	'세부종목',
	'공지사항',
	'경품/기념품',
	'대회현황',
	'1:1 문의',
	'참가명단',
	'리포트',
	'정산관리',
] as const;

interface SportHeaderProps {
	title: string;
	date: string;
	views: number;
	participants: number;
	currentCategory: (typeof categories)[number];
}
const SportHeader = ({
	title,
	date,
	views,
	participants,
	currentCategory,
}: SportHeaderProps) => {
	return (
		<div className={styles.container}>
			<div className={styles.upper_container}>
				<div className={styles.back_button}>
					<LeftArrow />
				</div>
				<h1 className={styles.title}>{title}</h1>
				<div className={styles.info}>
					<p>{date}</p>
					<span>/</span>
					<p> 조회: {views.toLocaleString()}</p>
					<span>/</span>
					<p className={styles.participants}>
						참가: {participants.toLocaleString()}
					</p>
				</div>
			</div>
			<div className={styles.lower_container}>
				<div className={styles.category_list}>
					{categories.map((category) => (
						<div
							className={clsx(
								styles.category_item,
								currentCategory === category && styles.current_category,
							)}
						>
							{category}
						</div>
					))}
				</div>
				<ListButton />
			</div>
		</div>
	);
};

function LeftArrow() {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.0714 0.443778C11.6631 1.03548 11.6631 1.99482 11.0714 2.58653L5.17305 8.48485H18.4848C19.3216 8.48485 20 9.1632 20 10C20 10.8368 19.3216 11.5152 18.4848 11.5152H5.17305L11.0714 17.4135C11.6631 18.0052 11.6631 18.9645 11.0714 19.5562C10.4797 20.1479 9.52033 20.1479 8.92863 19.5562L0.443778 11.0714C-0.147926 10.4797 -0.147926 9.52033 0.443778 8.92863L8.92863 0.443778C9.52033 -0.147926 10.4797 -0.147926 11.0714 0.443778Z"
				fill="#777777"
			/>
		</svg>
	);
}

export default SportHeader;
