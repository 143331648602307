import React from 'react';

import styles from './Checkbox.module.css';
import clsx from 'clsx';

const Checkbox = (props: React.InputHTMLAttributes<HTMLInputElement>) => {
	return (
		<input
			type="checkbox"
			className={clsx(styles.checkbox, props.className)}
			{...props}
		/>
	);
};

export default Checkbox;
