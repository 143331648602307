import React, { useState } from 'react';
import Modal from '../../../../common/Modal/Modal.tsx';
import Pagination from '../../../../common/Pagination/Pagination.tsx';
import styles from './BatchModal.module.css';
import Input from '../../../../common/Input/Input.tsx';
import Checkbox from '../../../../common/Checkbox/Checkbox.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableCell,
	TableBody,
} from '../../../../common/Table/Table.tsx';

import Button from '../../../../common/Button/Button.tsx';

interface BatchModalProps {
	open: boolean;
	onClose: () => void;
}

const BatchModal = ({ open, onClose }: BatchModalProps) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			title="경기구분 일괄 생성하기"
		>
			<div className={styles.subtitle_row}>
				<div className={styles.subtitle}>배드민턴 경기구분</div>
				<div className={styles.subtitle_description}>
					일괄생성시 작성중이던 경기구분은 사라지며 다시 되돌릴 수 없습니다.
				</div>
			</div>
			<div className={styles.divider}></div>
			<form className={styles.form}>
				<div className={styles.field_row}>
					<strong className={styles.field_type}>필수항목</strong>
					<span className={styles.field_item}>
						참가연령 및 성별은 사용자 신청 시 자동으로 체크됩니다.
					</span>
				</div>
				<div className={styles.field_row}>
					<p className={styles.field_type}>최소 참가인원</p>
					<span className={styles.field_option_item}>
						<Input type="number" />
					</span>
					<p className={styles.field_type}>~ 최대 참가인원</p>
					<span className={styles.field_option_item}>
						<Input type="number" />
					</span>
				</div>
				<div className={styles.field_row}>
					<p className={styles.field_type}>참가구분</p>
					<span className={styles.field_option_item}>
						<Checkbox></Checkbox>
						<label htmlFor="">개인참가</label>
					</span>
					<span className={styles.field_option_item}>
						<Checkbox></Checkbox>
						<label htmlFor="">파트너</label>
					</span>
					<span className={styles.field_option_item}>
						<Checkbox></Checkbox>
						<label htmlFor="">팀</label>
					</span>
				</div>
				<div className={styles.field_row}>
					<p className={styles.field_type}>경기방식</p>
					<span className={styles.field_option_item}>
						<Checkbox></Checkbox>
						<label htmlFor="">남자단식</label>
					</span>
					<span className={styles.field_option_item}>
						<Checkbox></Checkbox>
						<label htmlFor="">여자단식</label>
					</span>
					<span className={styles.field_option_item}>
						<Checkbox></Checkbox>
						<label htmlFor="">남자복식</label>
					</span>
					<span className={styles.field_option_item}>
						<Checkbox></Checkbox>
						<label htmlFor="">여자복식</label>
					</span>
					<span className={styles.field_option_item}>
						<Checkbox></Checkbox>
						<label htmlFor="">혼합복식</label>
					</span>
				</div>
				<div className={styles.field_row}>
					<p className={styles.field_type}>참가연령</p>
					<span className={styles.field_option_item}>
						<select>
							<option value="1">10대</option>
						</select>
					</span>
					<p className={styles.field_type}>부터 ~</p>
					<span className={styles.field_option_item}>
						<select>
							<option value="1">10대</option>
						</select>
					</span>
				</div>
				<div className={styles.field_row}>
					<p className={styles.field_type}>급수설정</p>
					<span className={styles.field_option_item_small}>
						<Checkbox></Checkbox>
						<label htmlFor="">자강</label>
					</span>
					<span className={styles.field_option_item_small}>
						<Checkbox></Checkbox>
						<label htmlFor="">준자강</label>
					</span>
					<span className={styles.field_option_item_small}>
						<Checkbox></Checkbox>
						<label htmlFor="">S</label>
					</span>
					<span className={styles.field_option_item_small}>
						<Checkbox></Checkbox>
						<label htmlFor="">A</label>
					</span>
					<span className={styles.field_option_item_small}>
						<Checkbox></Checkbox>
						<label htmlFor="">B</label>
					</span>
					<span className={styles.field_option_item_small}>
						<Checkbox></Checkbox>
						<label htmlFor="">C</label>
					</span>
					<span className={styles.field_option_item_small}>
						<Checkbox></Checkbox>
						<label htmlFor="">D</label>
					</span>
					<span className={styles.field_option_item_small}>
						<Checkbox></Checkbox>
						<label htmlFor="">E</label>
					</span>
				</div>
			</form>
			<div className={styles.divider}></div>
			<div className={styles.subtitle_row}>
				<div className={styles.subtitle}>추가항목</div>
				<div className={styles.subtitle_description}>
					: 대회관리자가 직접 필요한 항목을 생성하여 추가할 수 있습니다.
				</div>
			</div>
			<form className={styles.form}>
				<div className={styles.field_row}>
					<p className={styles.field_type}>급수설정</p>
					<span className={styles.field_option_item}>
						<Checkbox></Checkbox>
						<label htmlFor="">장애인</label>
					</span>
					<span className={styles.field_option_item}>
						<Checkbox></Checkbox>
						<label htmlFor="">비장애인</label>
					</span>
				</div>
				<div className={styles.field_row}>
					<p className={styles.field_type}>옷사이즈</p>
					<span className={styles.field_option_item}>
						<Input type="number" />
					</span>
				</div>
			</form>
			<div className={styles.divider}></div>
			<div className={styles.button_container}>
				<Button weight="bold">선택완료 / 경기구분 일괄 반영</Button>
			</div>
		</Modal>
	);
};

export default BatchModal;
