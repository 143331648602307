import React from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import MasterSearch from '../../../common/MasterSearch/MasterSearch.tsx';
import styles from './MngCmptLRegAgree.module.css';
import Input from '../../../common/Input/Input.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';
import SportRegistrationStep from '../../../common/SportRegistrationStep/SportRegistrationStep.tsx';

const MngCmptLRegAgree = () => {
	return (
		<div>
			<MyCompetitionHeader title="대회마스터" />

			<div className={styles.container}>
				<div className={styles.left_section}>
					<div className={styles.sub_title}>
						<h1>신규대회 등록</h1>
						<p>
							아래의 순서에 따라
							<br />
							개최하고 싶은 대회의
							<br />
							정보를 입력하시면
							<br />
							해당 대회를 등록하실 수 있습니다.
						</p>
					</div>
					<SportRegistrationStep />
				</div>
				<div className={styles.right_section}>
					<div className={styles.title}>
						<h1>0. 대회등록 가이드</h1>
						<span>대회마스터 등록하기 M</span>
					</div>
					<div className={styles.guide_content}>
						<p>대회등록 약관</p>
						<p>동의사항 확인</p>
						<p>등록절차</p>
						<p>주의사항</p>
						<p>수수료 안해 포함</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MngCmptLRegAgree;
