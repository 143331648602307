import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface HTMLEditorProps {
	value: string;
	onChange: (value: string) => void;
}

const HTMLEditor = ({ value, onChange }: HTMLEditorProps) => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	const uploadImageToS3 = async (file: any) => {};

	return (
		<ReactQuill
			value={value}
			onChange={onChange}
		></ReactQuill>
	);

	return (
		<Editor
			editorStyle={{
				border: '1px solid #ccc',
				padding: '10px',
				borderRadius: '5px',
				minHeight: '300px',
			}}
			editorState={editorState}
			toolbarClassName="toolbarClassName"
			wrapperClassName="wrapperClassName"
			editorClassName="editorClassName"
			toolbar={{
				options: ['inline', 'blockType', 'colorPicker', 'image'],
				inline: {
					options: ['bold', 'italic', 'underline', 'strikethrough'],
				},
				blockType: {
					inDropdown: true,
					options: [
						'Normal',
						'H1',
						'H2',
						'H3',
						'H4',
						'H5',
						'H6',
						'Blockquote',
						'Code',
					],
				},
				colorPicker: {
					colors: [
						'black',
						'red',
						'blue',
						'green',
						'orange',
						'purple',
						'yellow',
						'gray',
					],
				},
				image: {
					uploadEnabled: true,
					alignmentEnabled: true,
					previewImage: true,
					inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
					uploadCallback: uploadImageToS3, // 🔥 S3 업로드 콜백 함수 설정
					alt: { present: true, mandatory: false },
				},
			}}
			onEditorStateChange={(editorState) => {
				setEditorState(editorState);
				onChange(editorState.getCurrentContent().getPlainText());
			}}
		/>
	);
};

export default HTMLEditor;
