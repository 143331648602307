import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import AdminLyout from '../../layout/_admin/AdminLayout.tsx';

import { fn_axios, getCookieUserId } from '../../common/common.tsx';
import { API } from '../../config/config.tsx';

function BnnrManage() {


    return (
        <AdminLyout>
            <div>
                
            </div>
        </AdminLyout>
    )
}

export default BnnrManage;