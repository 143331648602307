import React from 'react';

const Avatar = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="20"
			height="21"
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M20 17.7526V21H0V17.7526C0 17.7526 0 11.2577 10 11.2577C20 11.2577 20 17.7526 20 17.7526ZM15 4.87114C15 3.90772 14.7068 2.96593 14.1573 2.16488C13.6079 1.36382 12.827 0.73948 11.9134 0.370795C10.9998 0.00211067 9.99445 -0.0943541 9.02455 0.0935996C8.05464 0.281553 7.16373 0.745484 6.46447 1.42672C5.7652 2.10796 5.289 2.97592 5.09607 3.92082C4.90315 4.86573 5.00216 5.84515 5.3806 6.73524C5.75904 7.62532 6.3999 8.38609 7.22215 8.92133C8.0444 9.45658 9.01109 9.74227 10 9.74227C11.3261 9.74227 12.5979 9.22906 13.5355 8.31555C14.4732 7.40203 15 6.16304 15 4.87114Z"
				fill="white"
			/>
		</svg>
	);
};

export default Avatar;
