import React from 'react';
import Modal from '../../../../common/Modal/Modal.tsx';
import Pagination from '../../../../common/Pagination/Pagination.tsx';
import styles from './PreviousEventModal.module.css';
import Input from '../../../../common/Input/Input.tsx';
import Checkbox from '../../../../common/Checkbox/Checkbox.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableCell,
	TableBody,
} from '../../../../common/Table/Table.tsx';

import data from './dummy.json';
import Button from '../../../../common/Button/Button.tsx';

interface PreviousEventModalProps {
	open: boolean;
	onClose: () => void;
}

const PreviousEventModal = ({ open, onClose }: PreviousEventModalProps) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			className={styles.modal}
			title="이전대회 세부종목 불러오기"
			description="현재 등록중인 대회의 세부종목이 선택하신 대회와 동일하게 변경되며 반영 이후에는 전에 작성중이던 내용으로 다시 되돌릴 수 없습니다. 선택을 완료하시기 전에 반드시 내용을 확인하시기 바랍니다."
		>
			<div className={styles.search_container}>
				<Input />
				<div className={styles.search_button}>검색</div>
			</div>
			<Table className={styles.table}>
				<TableHeader>
					<TableRow>
						<TableCell className={styles.checkbox}></TableCell>
						<TableCell className={styles.no}>No</TableCell>
						<TableCell className={styles.tournament_name}>대회명</TableCell>
						<TableCell className={styles.event_count}>세부종목 수</TableCell>
						<TableCell className={styles.tournament_period}>대회기간</TableCell>
					</TableRow>
				</TableHeader>
				<TableBody>
					{data.map((item) => (
						<TableRow key={item.no}>
							<TableCell className={styles.checkbox}>
								<Checkbox />
							</TableCell>
							<TableCell className={styles.no}>{item.no}</TableCell>
							<TableCell className={styles.tournament_name}>
								{item.tournament_name}
							</TableCell>
							<TableCell className={styles.event_count}>
								{item.event_count}
							</TableCell>
							<TableCell className={styles.tournament_period}>
								{item.tournament_period}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<Pagination />
			<div className={styles.button_container}>
				<Button weight="bold">선택완료 / 이전대회 세부종목 전체 반영</Button>
			</div>
		</Modal>
	);
};

export default PreviousEventModal;
