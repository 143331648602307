import React from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import SportHeader from '../../../common/SportHeader/SportHeader.tsx';
import data from './dummy.json';
import styles from './MngCmptLDetail.module.css';
import ApplyButton from '../../../common/ApplyButton/ApplyButton.tsx';
import Button from '../../../common/Button/Button.tsx';
import ListButton from '../../../common/ListButton/ListButton.tsx';

const MngCmptLDetail = () => {
	return (
		<div>
			<MyCompetitionHeader title="대회관리" />
			<SportHeader
				title={data.title}
				date={data.date}
				views={data.views}
				participants={data.participants}
				currentCategory="대회요강"
			/>
			<div className={styles.container}>
				<div className={styles.left_section}>
					<img
						src={data.poster}
						alt="thumbnail"
						className={styles.poster}
					/>
					<ApplyButton />
					<p className={styles.thumbnail_title}>썸네일 이미지</p>
					<img
						src={data.thumbnail}
						alt="thumbnail"
						className={styles.thumbnail}
					/>
				</div>
				<div className={styles.right_section}>
					<div className={styles.info}>
						<div className={styles.info_row}>
							<p className={styles.info_type}>대회명</p>
							<p className={styles.info_content}>{data.title}</p>
						</div>
						<div className={styles.info_row}>
							<p className={styles.info_type}>주관</p>
							<p className={styles.info_content}>{data.host}</p>
						</div>
						<div className={styles.info_row}>
							<p className={styles.info_type}>협찬</p>
							<p className={styles.info_content}>{data.sponsor}</p>
						</div>
						<div className={styles.info_row}>
							<p className={styles.info_type}>후원</p>
							<p className={styles.info_content}>{data.support}</p>
						</div>
						<div className={styles.info_row}>
							<p className={styles.info_type}>대회기간</p>
							<p className={styles.info_content}>{data.event_period}</p>
						</div>
						<div className={styles.info_row}>
							<p className={styles.info_type}>접수기간</p>
							<p className={styles.info_content}>{data.registration_period}</p>
						</div>
						<div className={styles.info_row}>
							<p className={styles.info_type}>개회식</p>
							<p className={styles.info_content}>{data.opening_ceremony}</p>
						</div>
						<div className={styles.info_row}>
							<p className={styles.info_type}>대회장소</p>
							<p className={styles.info_content}>{data.venue}</p>
						</div>
						<div className={styles.info_row}>
							<p className={styles.info_type}>대회지역</p>
							<p className={styles.info_content}>{data.location}</p>
						</div>
						<div className={styles.info_row}>
							<p className={styles.info_type}>모집인원</p>
							<p className={styles.info_content}>
								{data.limit.toLocaleString()} 명
							</p>
						</div>
						<div className={styles.info_row}>
							<p className={styles.info_type}>참가대상</p>
							<p className={styles.info_content}>{data.eligibility}</p>
						</div>
					</div>
					<div className={styles.settlement}>
						<p className={styles.settlement_title}>대회 정산정보</p>
						<p className={styles.settlement_content}>{data.settlement_info}</p>
					</div>
					<div className={styles.content}>
						<div
							dangerouslySetInnerHTML={{
								__html: data.content,
							}}
						></div>
					</div>
					<div className={styles.attach_file}>
						<p>첨부파일 : 대회요강 및 규정.hwp / 유의사항 및 운영지침.pdf </p>
					</div>
					<div className={styles.info_footer}>
						<Button weight="bold">수정하기</Button>
						<ListButton />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MngCmptLDetail;
