import React from 'react';
import styles from './MyCompetitionHeader.module.css';

interface MyCompetitionHeaderProps {
	title: string;
}

const MyCompetitionHeader = ({ title }: MyCompetitionHeaderProps) => {
	return (
		<div className={styles.my_competition_header}>
			<p className={styles.my_competition_header_title}>{title}</p>
			<select
				name=""
				id=""
				className={styles.my_competition_header_select}
			>
				<option value="1">제31회 국민생활체육전국배드민턴대회</option>
			</select>
			<button className={styles.my_competition_header_button}>
				<p>신규 대회 등록하기</p>
				<FlagIcon />
			</button>
		</div>
	);
};

function FlagIcon() {
	return (
		<svg
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8.26615 2.98574C7.40604 2.46446 6.43297 2.16341 5.43154 2.10878C4.43011 2.05415 3.43063 2.24758 2.52 2.67225C2.38787 2.73373 2.27593 2.83217 2.19741 2.95593C2.11889 3.07968 2.07708 3.22358 2.07692 3.37059V11.1139C3.31711 10.6098 4.65598 10.403 5.98853 10.5096C7.32108 10.6161 8.61109 11.0333 9.75739 11.7283L10.2337 12.018C11.0364 12.5048 11.9477 12.779 12.8832 12.8152C13.8186 12.8514 14.748 12.6485 15.5852 12.2251C15.6867 12.1737 15.7721 12.0948 15.8317 11.9972C15.8914 11.8995 15.923 11.7871 15.9231 11.6723V3.81703C14.7065 4.26934 13.405 4.44027 12.1145 4.31724C10.8239 4.19421 9.57701 3.78035 8.46554 3.10609L8.26615 2.98574ZM16.5143 14.1032C15.3646 14.6842 14.0885 14.9627 12.804 14.9128C11.5196 14.8629 10.2683 14.4862 9.16615 13.8177L8.68846 13.528C7.79595 12.9868 6.7891 12.6675 5.75069 12.5962C4.71227 12.5249 3.67195 12.7037 2.71523 13.118L2.07692 13.3951V17.9504C2.07692 18.2288 1.96751 18.4957 1.77276 18.6926C1.57802 18.8894 1.31388 19 1.03846 19C0.763044 19 0.498908 18.8894 0.304158 18.6926C0.109409 18.4957 0 18.2288 0 17.9504V3.37059C0 2.25101 0.643846 1.23499 1.64769 0.766161C2.86593 0.197805 4.20312 -0.0610479 5.54292 0.0121248C6.88272 0.0852975 8.18453 0.488279 9.33508 1.18601L9.53446 1.30636C10.456 1.86543 11.4987 2.1883 12.5719 2.24684C13.6451 2.30538 14.7162 2.09782 15.6918 1.64224L16.5268 1.25318C16.685 1.17946 16.859 1.14715 17.0328 1.15924C17.2066 1.17132 17.3746 1.22741 17.5213 1.32233C17.6681 1.41726 17.7888 1.54797 17.8725 1.70243C17.9561 1.85689 18 2.03014 18 2.20623V11.6723C18 12.7009 17.424 13.6428 16.5143 14.1032Z"
				fill="white"
			/>
		</svg>
	);
}

export default MyCompetitionHeader;
