import React from 'react';
import Input from '../../../common/Input/Input.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';
import styles from './MngGroupRegComplet.module.css';
import defaultThumbnail from '../../../assets/images/common/default_thumbnail.png';
import Button from '../../../common/Button/Button.tsx';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import AssociationSearch from '../../../common/AssociationSearch/AssociationSearch.tsx';
import clsx from 'clsx';
import ArrowRight from '../../../common/Icons/ArrowRight.tsx';
import BackToListButton from '../../../common/BackToListButton/BackToListButton.tsx';
import ArrowLeft from '../../../common/Icons/ArrowLeft.tsx';

const MngGroupRegComplet = () => {
	return (
		<div>
			<MyCompetitionHeader title="기관/단체/업체" />
			<div className={styles.container}>
				<div className={styles.left_section}>
					<img
						src={defaultThumbnail}
						alt="thumbnail"
						className={styles.default_thumbnail}
					/>
					<div className={styles.file_input_container}>
						<div className={styles.file_input} />
						<button className={styles.file_input_button}>파일찾기</button>
					</div>
				</div>
				<div className={styles.right_section}>
					<div className={styles.title_container}>
						<h1 className={styles.title}>기관/단체/업체 등록 양식</h1>
						<p className={styles.description}>
							아래 양식을 작성하시고 등록을 신청하시면, 우리그라운드 운영팀에서
							24시간 이내에 내용을 검토하고 등록을 승인하겠습니다.
						</p>
					</div>
					<div className={styles.confirm}>
						<div className={styles.confirm_row}>
							<div className={styles.confirm_label}>등록자</div>
							<div className={styles.confirm_value}>
								임재영 (lostking2004@naver.com)
							</div>
						</div>
						<div className={styles.confirm_row}>
							<div className={styles.confirm_label}>단체구분</div>
							<div className={styles.confirm_value}>기업</div>
						</div>
						<div className={styles.confirm_row}>
							<div className={styles.confirm_label}>단체명</div>
							<div className={styles.confirm_value}>유한회사 나이키 코리아</div>
						</div>
						<div className={styles.divided_confirm_row}>
							<div className={styles.divided_confirm_row_left}>
								<div className={styles.confirm_label}>사업자번호</div>
								<div className={styles.confirm_value}>123 - 45 - 67890</div>
							</div>
							<div className={styles.divided_confirm_row_right}>
								<div className={styles.confirm_label}>사업자 등록증</div>
								<div className={styles.confirm_value}>
									<a
										download={true}
										href=""
									>
										나이키코리아 사업자등록증2024.jpg
									</a>
								</div>
							</div>
						</div>
						<div className={styles.divided_confirm_row}>
							<div className={styles.divided_confirm_row_left}>
								<div className={styles.confirm_label}>대표 연락처</div>
								<div className={styles.confirm_value}>010-3342-2189</div>
							</div>
							<div className={styles.divided_confirm_row_right}>
								<div className={styles.confirm_label}>대표 이메일</div>
								<div className={styles.confirm_value}>asdboc2024@naver.com</div>
							</div>
						</div>
						<div className={styles.confirm_row}>
							<div className={styles.confirm_label}>주소</div>
							<div className={styles.confirm_value}>
								서울특별시 강남구 테헤란로 123길 456번지
							</div>
						</div>
						<div className={styles.confirm_row}>
							<div className={styles.confirm_label}></div>
							<div className={styles.confirm_value}>우리그라운드빌딩 15층</div>
						</div>
						<div className={styles.confirm_row}>
							<div className={styles.confirm_label}>지역</div>
							<div className={styles.confirm_value}>
								서울특별시 강남구 / 성남시 분당구 / 수원시 영통구
							</div>
						</div>
						<div className={styles.confirm_row}>
							<div className={styles.confirm_label}>웹사이트 URL</div>
							<div className={styles.confirm_value}>www.woorigraound.com</div>
						</div>
					</div>
					<div className={styles.confirm_footer}>
						<div className={styles.button_group}>
							<Button
								weight="bold"
								className={styles.edit_button}
								prefixIcon={<ArrowLeft fill="#fff" />}
							>
								수정하기
							</Button>
							<Button
								weight="bold"
								className={styles.submit_button}
							>
								등록완료 / 승인대기{' '}
							</Button>
						</div>
						<BackToListButton onClick={() => {}} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MngGroupRegComplet;
