import React from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import MasterSearch from '../../../common/MasterSearch/MasterSearch.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';
import data from './dummy.json';
import styles from './MngCmptLReqs.module.css';
import Checkbox from '../../../common/Checkbox/Checkbox.tsx';
import Button from '../../../common/Button/Button.tsx';
import Pagination from '../../../common/Pagination/Pagination.tsx';
import SportHeader from '../../../common/SportHeader/SportHeader.tsx';

const MngCmptLReqs = () => {
	return (
		<div>
			<div>
				<MyCompetitionHeader title="대회관리" />
				<SportHeader
					title="제31회 국민생활체육전국배드민턴대회"
					date="2024년 3월 14일"
					views={1312}
					participants={219}
					currentCategory="참가명단"
				/>
			</div>
			<div className={styles.table_header}>
				<div className={styles.table_header_left}>
					<div className={styles.total_count}>
						전체인원 543명 = 결재완료 546명 - 결재취소 3명
					</div>
				</div>
				<div className={styles.table_header_right}>
					<select>
						<option value="all">연령</option>
					</select>
					<select>
						<option value="all">상태</option>
					</select>
					<div className={styles.excel_download}>
						현재 리스트 엑셀파일로 저장
						<ExcelDownload />
					</div>
				</div>
			</div>
			<Table>
				<TableHeader>
					<TableRow>
						<TableHead className={styles.check_box}>
							<Checkbox />
						</TableHead>
						<TableHead className={styles.number}>No.</TableHead>
						<TableHead className={styles.name}>이름</TableHead>
						<TableHead className={styles.phone}>전화번호</TableHead>
						<TableHead className={styles.email}>이메일</TableHead>
						<TableHead className={styles.category}>신청 세부종목</TableHead>
						<TableHead className={styles.status}>상태</TableHead>
						<TableHead className={styles.participants}>
							결제 / 취소일시
						</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody className={styles.table_body}>
					{data.object.map((item) => (
						<TableRow className={styles.table_row}>
							<TableCell className={styles.check_box}>
								<Checkbox />
							</TableCell>
							<TableCell className={styles.number}>{item.id}</TableCell>
							<TableCell className={styles.name}>{item.name}</TableCell>
							<TableCell className={styles.phone}>{item.phone}</TableCell>
							<TableCell className={styles.email}>{item.email}</TableCell>
							<TableCell className={styles.category}>{item.category}</TableCell>
							<TableCell className={styles.status}>{item.status}</TableCell>
							<TableCell className={styles.date}>
								{`${item.date} / ${item.time}`}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<div className={styles.table_footer}>
				<Button
					weight="bold"
					disabled
				>
					선택항목 삭제
				</Button>
				<Pagination />
				<div className={styles.blank_div}></div>
			</div>
		</div>
	);
};

function ExcelDownload() {
	return (
		<svg
			width="34"
			height="34"
			viewBox="0 0 34 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.8038 16.3092L9.04297 14.2373V29.5469C9.04311 29.7132 9.07601 29.8779 9.13978 30.0315C9.20356 30.1851 9.29696 30.3246 9.41467 30.4421C9.53237 30.5596 9.67207 30.6528 9.82578 30.7163C9.97949 30.7798 10.1442 30.8124 10.3105 30.8123H30.6043C30.7708 30.8127 30.9357 30.7803 31.0897 30.7169C31.2436 30.6535 31.3836 30.5604 31.5015 30.4429C31.6194 30.3253 31.713 30.1857 31.7769 30.0319C31.8409 29.8782 31.8738 29.7134 31.874 29.5469V23.9061L20.8038 16.3092Z"
				fill="#185C37"
			/>
			<path
				d="M20.8038 3.1875H10.3105C10.1442 3.18736 9.97949 3.21998 9.82578 3.2835C9.67207 3.34702 9.53237 3.44019 9.41467 3.5577C9.29696 3.67521 9.20356 3.81474 9.13978 3.96835C9.07601 4.12196 9.04311 4.28662 9.04297 4.45294V10.0938L20.8038 17L27.0311 19.0719L31.874 17V10.0938L20.8038 3.1875Z"
				fill="#21A366"
			/>
			<path
				d="M9.04297 10.0938H20.8038V17H9.04297V10.0938Z"
				fill="#107C41"
			/>
			<path
				opacity="0.1"
				d="M17.4601 8.7124H9.04297V25.978H17.4601C17.7955 25.9764 18.1167 25.8426 18.3542 25.6057C18.5916 25.3689 18.7262 25.048 18.7287 24.7126V9.97784C18.7262 9.64245 18.5916 9.32156 18.3542 9.08469C18.1167 8.84783 17.7955 8.71407 17.4601 8.7124Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M16.7684 9.40332H9.04297V26.6689H16.7684C17.1038 26.6673 17.425 26.5335 17.6625 26.2967C17.9 26.0598 18.0345 25.7389 18.037 25.4035V10.6688C18.0345 10.3334 17.9 10.0125 17.6625 9.77561C17.425 9.53875 17.1038 9.40499 16.7684 9.40332Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M16.7684 9.40332H9.04297V25.2877H16.7684C17.1038 25.286 17.425 25.1523 17.6625 24.9154C17.9 24.6785 18.0345 24.3576 18.037 24.0223V10.6688C18.0345 10.3334 17.9 10.0125 17.6625 9.77561C17.425 9.53875 17.1038 9.40499 16.7684 9.40332Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M16.0767 9.40332H9.04297V25.2877H16.0767C16.4121 25.286 16.7333 25.1523 16.9708 24.9154C17.2083 24.6785 17.3428 24.3576 17.3453 24.0223V10.6688C17.3428 10.3334 17.2083 10.0125 16.9708 9.77561C16.7333 9.53875 16.4121 9.40499 16.0767 9.40332Z"
				fill="black"
			/>
			<path
				d="M3.39362 9.40332H16.0777C16.4137 9.40304 16.7361 9.53617 16.974 9.77345C17.2119 10.0107 17.3458 10.3328 17.3464 10.6688V23.3316C17.3458 23.6676 17.2119 23.9897 16.974 24.2269C16.7361 24.4642 16.4137 24.5974 16.0777 24.5971H3.39362C3.22722 24.5973 3.06238 24.5648 2.90855 24.5014C2.75471 24.4379 2.61489 24.3448 2.49708 24.2272C2.37926 24.1097 2.28576 23.9701 2.22191 23.8165C2.15807 23.6628 2.12514 23.498 2.125 23.3316V10.6688C2.12514 10.5024 2.15807 10.3376 2.22191 10.1839C2.28576 10.0303 2.37926 9.89066 2.49708 9.77314C2.61489 9.65562 2.75471 9.56247 2.90855 9.49902C3.06238 9.43556 3.22722 9.40304 3.39362 9.40332Z"
				fill="url(#paint0_linear_6514_3388)"
			/>
			<path
				d="M6.05469 21.1149L8.72263 16.9881L6.27887 12.8848H8.24131L9.57475 15.5123C9.698 15.761 9.78725 15.9458 9.82763 16.0691H9.84569C9.93281 15.8693 10.0252 15.677 10.1219 15.489L11.5478 12.889H13.3541L10.8476 16.969L13.4178 21.1181H11.4958L9.95513 18.2376C9.88359 18.1138 9.82281 17.9841 9.77344 17.8498H9.74794C9.70301 17.9806 9.64312 18.1057 9.56944 18.2228L7.98313 21.1149H6.05469Z"
				fill="white"
			/>
			<path
				d="M30.6063 3.1875H20.8047V10.0938H31.8749V4.45294C31.8747 4.28653 31.8418 4.12178 31.778 3.96811C31.7141 3.81443 31.6206 3.67484 31.5028 3.55732C31.385 3.4398 31.2452 3.34665 31.0913 3.2832C30.9375 3.21974 30.7727 3.18722 30.6063 3.1875Z"
				fill="#33C481"
			/>
			<path
				d="M20.8047 17H31.8749V23.9062H20.8047V17Z"
				fill="#107C41"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_6514_3388"
					x1="4.77487"
					y1="8.40882"
					x2="14.6965"
					y2="25.5916"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#18884F" />
					<stop
						offset="0.5"
						stop-color="#117E43"
					/>
					<stop
						offset="1"
						stop-color="#0B6631"
					/>
				</linearGradient>
			</defs>
		</svg>
	);
}

export default MngCmptLReqs;
