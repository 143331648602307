import React from 'react';
import clsx from 'clsx';

import styles from './Button.module.css';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	/**
	 * 버튼의 색상
	 */
	variant?: 'black' | 'white';
	/**
	 * 버튼 앞에 표시할 아이콘
	 * @example
	 * <Button prefixIcon={<ArrowLeft />} />
	 */
	prefixIcon?: React.ReactNode;
	/**
	 * 버튼 뒤에 표시할 아이콘
	 * @example
	 * <Button suffixIcon={<ArrowRight />} />
	 */
	suffixIcon?: React.ReactNode;
	/**
	 * 버튼 텍스트 굵기
	 */
	weight?: 'normal' | 'bold';
}

const Button = ({
	children,
	className,
	variant = 'black',
	prefixIcon,
	suffixIcon,
	weight = 'normal',
	...props
}: ButtonProps) => {
	return (
		<button
			{...props}
			className={clsx(
				styles.button,
				styles[variant],
				className,
				styles[weight],
			)}
		>
			{prefixIcon && <span className={styles.prefix}>{prefixIcon}</span>}
			{children}
			{suffixIcon && <span className={styles.suffix}>{suffixIcon}</span>}
		</button>
	);
};

export default Button;
