import React from 'react';
import Input from '../../../common/Input/Input.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';
import styles from './MngGymContent.module.css';
import defaultThumbnail from '../../../assets/images/common/default_thumbnail.png';
import Button from '../../../common/Button/Button.tsx';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import AssociationSearch from '../../../common/AssociationSearch/AssociationSearch.tsx';
import clsx from 'clsx';
import ArrowRight from '../../../common/Icons/ArrowRight.tsx';
import BackToListButton from '../../../common/BackToListButton/BackToListButton.tsx';
import ArrowLeft from '../../../common/Icons/ArrowLeft.tsx';
import Checkbox from '../../../common/Checkbox/Checkbox.tsx';
import Pagination from '../../../common/Pagination/Pagination.tsx';

const MngGymContent = () => {
	return (
		<div>
			<MyCompetitionHeader title="기관/단체/업체" />
			<div className={styles.container}>
				<div className={styles.left_section}>
					<img
						src={defaultThumbnail}
						alt="thumbnail"
						className={styles.default_thumbnail}
					/>
				</div>
				<div className={styles.right_section}>
					<div className={styles.confirm}>
						<div className={styles.court_name}>동림 체육관</div>
						<div className={styles.confirm_row}>
							<div className={styles.confirm_label}>체육관 유형</div>
							<div className={styles.confirm_value}>유형</div>
						</div>
						<div className={styles.divided_confirm_row}>
							<div className={styles.divided_confirm_row_auto}>
								<div className={styles.confirm_label}>담당부서</div>
								<div className={styles.confirm_value}>사업부</div>
							</div>
							<div className={styles.divided_confirm_row_auto}>
								<div className={styles.confirm_label}>연락처</div>
								<div className={styles.confirm_value}>010-3342-2189</div>
							</div>
						</div>
						<div className={styles.divided_confirm_row}>
							<div className={styles.divided_confirm_row_auto}>
								<div className={styles.confirm_label}>수용인원</div>
								<div className={styles.confirm_value}>100명</div>
							</div>
							<div className={styles.divided_confirm_row_auto}>
								<div className={styles.confirm_label}>코트수</div>
								<div className={styles.confirm_value}>10개</div>
							</div>
							<div className={styles.divided_confirm_row_auto}>
								<div className={styles.confirm_label}>관람석</div>
								<div className={styles.confirm_value}>100석</div>
							</div>
						</div>
						<div className={styles.confirm_row}>
							<div className={styles.confirm_label}>이용금액 안내</div>
							<div className={styles.confirm_value}>10,000원</div>
						</div>
						<div className={styles.confirm_row}>
							<div className={styles.confirm_label}>주차시설</div>
							<div className={styles.confirm_value}>100대 (무료)</div>
						</div>
						<div className={styles.confirm_row}>
							<div className={styles.confirm_label}>웹사이트 URL</div>
							<div className={styles.confirm_value}>
								<a href="">www.woorigraound.com</a>
							</div>
						</div>
						<div className={styles.confirm_row}>
							<div className={styles.confirm_label}>주소</div>
							<div className={styles.confirm_value}>
								서울특별시 강남구 테헤란로 123길 456번지
							</div>
						</div>
					</div>
					<div className={styles.confirm_footer}>
						<Button
							weight="bold"
							className={styles.edit_button}
							prefixIcon={<ArrowLeft fill="#fff" />}
						>
							수정하기
						</Button>
						<BackToListButton onClick={() => {}} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MngGymContent;
