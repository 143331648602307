import React from 'react';
import Input from '../../../common/Input/Input.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';
import styles from './MngGymRegMain.module.css';
import defaultThumbnail from '../../../assets/images/common/default_thumbnail.png';
import Button from '../../../common/Button/Button.tsx';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import AssociationSearch from '../../../common/AssociationSearch/AssociationSearch.tsx';
import clsx from 'clsx';
import ArrowRight from '../../../common/Icons/ArrowRight.tsx';
import BackToListButton from '../../../common/BackToListButton/BackToListButton.tsx';
import Checkbox from '../../../common/Checkbox/Checkbox.tsx';
import FileInput from '../../../common/FileInput/FileInput.tsx';

const MngGymRegMain = () => {
	return (
		<div>
			<MyCompetitionHeader title="기관/단체/업체" />
			<div className={styles.container}>
				<div className={styles.left_section}>
					<img
						src={defaultThumbnail}
						alt="thumbnail"
						className={styles.default_thumbnail}
					/>
					<div className={styles.file_input_container}>
						<div className={styles.file_input} />
						<button className={styles.file_input_button}>파일찾기</button>
					</div>
				</div>
				<div className={styles.right_section}>
					<div className={styles.title_container}>
						<h1 className={styles.title}>기관/단체/업체 등록 양식</h1>
						<p className={styles.description}>
							아래 양식을 작성하시고 등록을 신청하시면, 우리그라운드 운영팀에서
							24시간 이내에 내용을 검토하고 등록을 승인하겠습니다.
						</p>
					</div>
					<form
						action=""
						className={styles.form}
					>
						<div className={styles.form_row}>
							<div className={styles.form_label}>체육관 유형</div>
							<div className={styles.form_input}>
								<select
									name=""
									id=""
								>
									<option value="1">기관</option>
									<option value="2">단체</option>
									<option value="3">업체</option>
								</select>
							</div>
						</div>
						<div className={styles.form_row}>
							<div className={styles.form_label}>체육관명</div>
							<div className={styles.form_input}>
								<Input />
							</div>
						</div>
						<div className={styles.divided_form_row}>
							<div className={styles.divided_form_row_left}>
								<div className={styles.form_label}>담당부서</div>
								<div className={styles.form_input}>
									<Input />
								</div>
							</div>
							<div className={styles.divided_form_row_right}>
								<div className={styles.form_label}>연락처</div>
								<div className={styles.form_input}>
									<Input />
								</div>
							</div>
						</div>
						<div className={styles.divided_form_row}>
							<div className={styles.divided_form_full_item}>
								<div className={styles.form_label}>수용인원 </div>
								<div className={styles.form_input}>
									<Input />
								</div>
							</div>
							<div className={styles.divided_form_full_item}>
								<div className={styles.form_label}>수용인원 </div>
								<div className={styles.form_input}>
									<Input />
								</div>
							</div>
							<div className={styles.divided_form_full_item}>
								<div className={styles.form_label}>관람석 </div>
								<div className={styles.form_input}>
									<Input />
								</div>
							</div>
						</div>

						<div className={styles.divided_form_row}>
							<div className={styles.divided_form_full_item}>
								<div className={styles.form_label}>수용인원 </div>
								<div className={styles.form_input}>
									<Input />
								</div>
							</div>
							<div className={styles.divided_form_row_right}>
								<div className={styles.form_label}>주차비용 </div>
								<div className={styles.form_input}>
									<Input />
								</div>
							</div>
						</div>

						<div className={styles.divided_form_row}>
							<div className={styles.divided_form_full_item}>
								<div className={styles.form_label}>부대시설 </div>
								<div className={styles.form_input}>
									<div className={styles.checkbox_container}>
										<label
											htmlFor=""
											className={styles.checkbox_label}
										>
											<Checkbox />
											전광판
										</label>
										<label
											htmlFor=""
											className={styles.checkbox_label}
										>
											<Checkbox />
											방송실
										</label>
										<label
											htmlFor=""
											className={styles.checkbox_label}
										>
											<Checkbox />
											화장실
										</label>
										<label
											htmlFor=""
											className={styles.checkbox_label}
										>
											<Checkbox />
											탈의실
										</label>
										<label
											htmlFor=""
											className={styles.checkbox_label}
										>
											<Checkbox />
											매점
										</label>
									</div>
								</div>
							</div>
						</div>

						<div className={styles.form_row}>
							<div className={styles.form_label}>주소</div>
							<div className={styles.form_input}>
								<div className={styles.address_input_container}>
									<div className={styles.address_input} />
									<button className={styles.address_input_button}>
										주소찾기
									</button>
								</div>
							</div>
						</div>
						<div className={styles.form_row}>
							<div className={styles.form_label}></div>
							<div className={styles.form_input}>
								<Input placeholder="상세 주소 입력" />
							</div>
						</div>
					</form>

					<div className={styles.form_row}>
						<div className={styles.form_label}>체육관 사진 추가</div>
						<div className={styles.form_input}>
							<FileInput />
						</div>
					</div>
					<div className={styles.form_footer}>
						<div></div>
						<Button
							weight="bold"
							className={styles.submit_button}
							suffixIcon={<ArrowRight />}
						>
							등록신청/내용확인
						</Button>
						<BackToListButton onClick={() => {}} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MngGymRegMain;
