import React, { useState } from 'react';
import Modal from '../../../../common/Modal/Modal.tsx';
import Pagination from '../../../../common/Pagination/Pagination.tsx';
import styles from './DetailModal.module.css';
import Input from '../../../../common/Input/Input.tsx';
import Checkbox from '../../../../common/Checkbox/Checkbox.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableCell,
	TableBody,
} from '../../../../common/Table/Table.tsx';

import data from './dummy.json';
import Button from '../../../../common/Button/Button.tsx';
import clsx from 'clsx';

interface DetailModalProps {
	open: boolean;
	onClose: () => void;
}

const DetailModal = ({ open, onClose }: DetailModalProps) => {
	const [details, setDetails] = useState(data);
	const [mode, setMode] = useState<'add' | 'list'>('list');
	const [type, setType] = useState<'list' | 'period' | 'text'>('list');

	const [tag, setTag] = useState<string>('');
	const [tags, setTags] = useState<string[]>([]);

	return (
		<Modal
			className={mode === 'add' ? styles.modal_add : undefined}
			open={open}
			onClose={onClose}
			title={mode === 'add' ? '경기구분 일괄 생성하기' : '항목추가하기'}
			description={
				mode === 'add' && (
					<div className={styles.modal_add_description}>
						<strong>리스트형 / 기간설정형 / 텍스트 입력형</strong> 등으로
						구분하여 항목을 추가할 수 있습니다.
					</div>
				)
			}
		>
			{mode === 'list' && (
				<>
					<div className={styles.subtitle_row}>
						<div className={styles.subtitle}>배드민턴 경기구분 생성</div>
						<div className={styles.subtitle_description}>
							경기구분 항목을 생성하여 하단의 리스트에 추가할 수 있습니다.
						</div>
					</div>
					<div className={styles.divider}></div>
					<div className={styles.search_container}>
						<select>
							<option value="1">전체형식</option>
						</select>
						<Input />
						<button className={styles.button}>검색</button>
						<span
							className={styles.add_item}
							onClick={() => setMode('add')}
						>
							항목추가하기
						</span>
					</div>
					<div className={styles.button_container}>
						<Button weight="bold">선택완료 / 경기구분 일괄 반영</Button>
					</div>
					<Table className={styles.table}>
						<TableHeader>
							<TableRow className={styles.table_header}>
								<TableCell className={styles.checkbox}>
									<Checkbox />
								</TableCell>
								<TableCell className={styles.no}>No</TableCell>
								<TableCell className={styles.type}>형식</TableCell>
								<TableCell className={styles.category}>경기구분명</TableCell>
								<TableCell className={styles.description}>설명</TableCell>
							</TableRow>
						</TableHeader>
						<TableBody className={styles.table_body}>
							{details.map((detail) => (
								<TableRow>
									<TableCell className={styles.checkbox}>
										<Checkbox />
									</TableCell>
									<TableCell className={styles.no}>{detail.no}</TableCell>
									<TableCell className={styles.type}>{detail.type}</TableCell>
									<TableCell className={styles.category}>
										{detail.category}
									</TableCell>
									<TableCell className={styles.description}>
										{detail.description}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<div className={styles.table_footer}>
						<button className={styles.remove_button}>선택항목 삭제</button>
						<Button weight="bold">선택완료 / 경기구분 항목 추가</Button>
						<div className={styles.fake}></div>
					</div>
				</>
			)}
			{mode === 'add' && (
				<>
					{(type === 'text' || type === 'period') && (
						<div className={styles.item_divider}></div>
					)}
					<div
						className={clsx(
							styles.add_type_item,
							type === 'list' && styles.add_type_item_selected,
						)}
					>
						<div className={styles.field_row}>
							<div className={clsx(styles.field_title, styles.field_label)}>
								리스트형
							</div>
							<div className={styles.field_value}>
								<button
									className={clsx(
										styles.select_button,
										type === 'list' && styles.select_button_selected,
									)}
									onClick={() => setType('list')}
								></button>
								<Input placeholder="항목명 입력 및 중복확인" />
								<button className={styles.button}>중복체크</button>
							</div>
						</div>
						<div className={styles.field_row}>
							<div className={styles.field_label}>항목설명</div>
							<div className={styles.field_value}>
								<Input />
							</div>
						</div>
						<div className={styles.field_row}>
							<div className={styles.field_label}>항목추가</div>
							<div className={styles.field_value}>
								<Input
									className={styles.detail_add_input}
									value={tag}
									onChange={(e) => setTag(e.target.value)}
								/>
								<button
									className={styles.detail_add_button}
									onClick={() => {
										setTags([...tags, tag]);
										setTag('');
									}}
								>
									+
								</button>
								<div className={styles.tags}>
									{tags.map((tag) => (
										<div
											className={styles.tag}
											onClick={() => {
												setTags(tags.filter((t) => t !== tag));
											}}
										>
											<div className={styles.tag_name}>{tag}</div>
											<TagDeleteButton />
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
					{type === 'list' ||
						(type === 'text' && <div className={styles.item_divider}></div>)}
					<div
						className={clsx(
							styles.add_type_item,
							type === 'period' && styles.add_type_item_selected,
						)}
					>
						<div className={styles.field_row}>
							<div className={clsx(styles.field_title, styles.field_label)}>
								기간설정형
							</div>
							<div className={styles.field_value}>
								<button
									className={clsx(
										styles.select_button,
										type === 'period' && styles.select_button_selected,
									)}
									onClick={() => setType('period')}
								></button>
								<Input placeholder="항목명 입력 및 중복확인" />
								<button className={styles.button}>중복체크</button>
							</div>
						</div>
						<div className={styles.field_row}>
							<div className={styles.field_label}>항목설명</div>
							<div className={styles.field_value}>
								<Input />
							</div>
						</div>
						<div className={styles.field_row}>
							<div className={styles.field_label}>항목추가</div>
							<div className={styles.field_value}>
								<Input
									className={styles.detail_add_input}
									value={tag}
									placeholder="시작일자"
									onChange={(e) => setTag(e.target.value)}
								/>
								<Input
									className={styles.detail_add_input}
									value={tag}
									placeholder="종료일자"
									onChange={(e) => setTag(e.target.value)}
								/>
								<p className={styles.input_description}>
									기간일자(년도.월.일) 입력 예시 : 2024.03.21
								</p>
							</div>
						</div>
					</div>
					{type === 'list' && <div className={styles.item_divider}></div>}
					<div
						className={clsx(
							styles.add_type_item,
							type === 'text' && styles.add_type_item_selected,
						)}
					>
						<div className={styles.field_row}>
							<div className={clsx(styles.field_title, styles.field_label)}>
								텍스트입력형
							</div>
							<div className={styles.field_value}>
								<button
									className={clsx(
										styles.select_button,
										type === 'text' && styles.select_button_selected,
									)}
									onClick={() => setType('text')}
								></button>
								<Input placeholder="항목명 입력 및 중복확인" />
								<button className={styles.button}>중복체크</button>
							</div>
						</div>
						<div className={styles.field_row}>
							<div className={styles.field_label}>항목설명</div>
							<div className={styles.field_value}>
								<Input />
							</div>
						</div>
						<div className={styles.field_row}>
							<div className={styles.field_label}></div>
							<div className={styles.field_value}>
								<p className={styles.input_description}>
									텍스트입력 형식은 경기구분 추가항목 설정을 위해 입력창이
									적용되어 생성됩니다.
								</p>
							</div>
						</div>
					</div>
					{type === 'list' ||
						(type === 'period' && <div className={styles.item_divider}></div>)}
					<div className={styles.button_container}>
						<Button
							weight="bold"
							onClick={() => {
								setMode('list');
								setType('list');
							}}
						>
							선택완료 / 항목 추가
						</Button>
					</div>
				</>
			)}
		</Modal>
	);
};

function TagDeleteButton() {
	return (
		<svg
			width="25"
			height="18"
			viewBox="0 0 25 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_6574_1325"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="25"
				height="18"
			>
				<path
					d="M9.21206 1.42584C9.4197 1.23172 9.69313 1.1237 9.97718 1.1236L22.4367 1.1236C22.7343 1.1236 23.0196 1.24198 23.23 1.45269C23.4404 1.66341 23.5586 1.9492 23.5586 2.2472L23.5586 15.7304C23.5586 16.0284 23.4404 16.3142 23.23 16.5249C23.0196 16.7356 22.7343 16.854 22.4367 16.854L9.97718 16.854C9.69313 16.8539 9.4197 16.7459 9.21206 16.5517L1.12109 8.98879L9.21206 1.42584Z"
					fill="white"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M9.97753 1.28586e-07L22.4375 0C23.0326 0 23.6033 0.236758 24.0241 0.658189C24.4449 1.07962 24.6813 1.65121 24.6813 2.2472L24.6813 15.7304C24.6813 16.3264 24.4449 16.898 24.0241 17.3194C23.6033 17.7408 23.0326 17.9776 22.4375 17.9776L9.97753 17.9776C9.40944 17.9774 8.86258 17.7613 8.4473 17.3731L0.356411 9.81022C0.129073 9.59772 2.49692e-09 9.30024 2.4133e-09 8.98879C2.32968e-09 8.67735 0.129073 8.37987 0.356411 8.16737L8.4473 0.604497C8.44727 0.604521 8.44732 0.604472 8.4473 0.604497C8.86256 0.216292 9.40948 0.000214867 9.97753 1.28586e-07ZM9.9783 2.24727L2.76611 8.98879L9.9783 15.7303L22.4375 15.7304L22.4375 2.2472L9.9783 2.24727Z"
					fill="white"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M12.6684 5.38525C13.1066 4.94645 13.8169 4.94645 14.255 5.38525L16.2664 7.39974L18.2778 5.38525C18.7159 4.94645 19.4263 4.94645 19.8644 5.38525C20.3025 5.82404 20.3025 6.53546 19.8644 6.97426L17.853 8.98875L19.8644 11.0032C20.3025 11.442 20.3025 12.1535 19.8644 12.5923C19.4263 13.031 18.7159 13.031 18.2778 12.5923L16.2664 10.5778L14.255 12.5923C13.8169 13.031 13.1066 13.031 12.6684 12.5923C12.2303 12.1535 12.2303 11.442 12.6684 11.0032L14.6798 8.98875L12.6684 6.97426C12.2303 6.53546 12.2303 5.82404 12.6684 5.38525Z"
					fill="black"
				/>
			</mask>
			<g mask="url(#mask0_6574_1325)">
				<path
					d="M-7.80859 -4.31458L50.8879 -4.31458L50.8879 29.9327L-7.80859 29.9327L-7.80859 -4.31458Z"
					fill="#111111"
				/>
			</g>
		</svg>
	);
}

export default DetailModal;
