import React from 'react';
import Input from '../../../common/Input/Input.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';
import styles from './MngGroupRegMain.module.css';
import defaultThumbnail from '../../../assets/images/common/default_thumbnail.png';
import Button from '../../../common/Button/Button.tsx';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import AssociationSearch from '../../../common/AssociationSearch/AssociationSearch.tsx';
import clsx from 'clsx';
import ArrowRight from '../../../common/Icons/ArrowRight.tsx';
import BackToListButton from '../../../common/BackToListButton/BackToListButton.tsx';

const MngGroupRegMain = () => {
	return (
		<div>
			<MyCompetitionHeader title="기관/단체/업체" />
			<div className={styles.container}>
				<div className={styles.left_section}>
					<img
						src={defaultThumbnail}
						alt="thumbnail"
						className={styles.default_thumbnail}
					/>
					<div className={styles.file_input_container}>
						<div className={styles.file_input} />
						<button className={styles.file_input_button}>파일찾기</button>
					</div>
				</div>
				<div className={styles.right_section}>
					<div className={styles.title_container}>
						<h1 className={styles.title}>기관/단체/업체 등록 양식</h1>
						<p className={styles.description}>
							아래 양식을 작성하시고 등록을 신청하시면, 우리그라운드 운영팀에서
							24시간 이내에 내용을 검토하고 등록을 승인하겠습니다.
						</p>
					</div>
					<form
						action=""
						className={styles.form}
					>
						<div className={styles.form_row}>
							<div className={styles.form_label}>단체구분</div>
							<div className={styles.form_input}>
								<select
									name=""
									id=""
								>
									<option value="1">기관</option>
									<option value="2">단체</option>
									<option value="3">업체</option>
								</select>
							</div>
						</div>
						<div className={styles.form_row}>
							<div className={styles.form_label}>단체명</div>
							<div className={styles.form_input}>
								<Input />
							</div>
						</div>
						<div className={styles.divided_form_row}>
							<div className={styles.divided_form_row_left}>
								<div className={styles.form_label}>사업자번호</div>
								<div className={styles.form_input}>
									<Input />
								</div>
							</div>
							<div className={styles.divided_form_row_right}>
								<div className={styles.form_label}>사업자 등록증</div>
								<div className={styles.form_input}>
									<div className={styles.file_input_container}>
										<div className={styles.file_input} />
										<button className={styles.file_input_button}>
											파일찾기
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className={styles.divided_form_row}>
							<div className={styles.divided_form_row_left}>
								<div className={styles.form_label}>대표 연락처</div>
								<div className={styles.form_input}>
									<Input />
								</div>
							</div>
							<div className={styles.divided_form_row_right}>
								<div className={styles.form_label}>대표 이메일</div>
								<div className={styles.form_input}>
									<Input />
								</div>
							</div>
						</div>
						<div className={styles.form_row}>
							<div className={styles.form_label}>주소</div>
							<div className={styles.form_input}>
								<div className={styles.address_input_container}>
									<div className={styles.address_input} />
									<button className={styles.address_input_button}>
										주소찾기
									</button>
								</div>
							</div>
						</div>
						<div className={styles.form_row}>
							<div className={styles.form_label}></div>
							<div className={styles.form_input}>
								<Input placeholder="상세 주소 입력" />
							</div>
						</div>
						<div className={styles.form_row}>
							<div className={styles.form_label}>지역</div>
							<div className={clsx(styles.form_input, styles.multiple_select)}>
								<select
									name=""
									id=""
								>
									<option value="1">시/도 선택</option>
								</select>
								<select
									name=""
									id=""
								>
									<option value="1">시/군/구 선택</option>
								</select>
								<button className={styles.plus_button}>+</button>
							</div>
						</div>
						<div className={styles.form_row}>
							<div className={styles.form_label}>웹사이트 URL</div>
							<div className={styles.form_input}>
								<Input />
							</div>
						</div>
					</form>
					<div className={styles.form_footer}>
						<div></div>
						<Button
							weight="bold"
							className={styles.submit_button}
							suffixIcon={<ArrowRight />}
						>
							등록신청/내용확인
						</Button>
						<BackToListButton onClick={() => {}} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MngGroupRegMain;
