import React, { useState } from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import MasterSearch from '../../../common/MasterSearch/MasterSearch.tsx';
import styles from './MngCmptMReg.module.css';
import Input from '../../../common/Input/Input.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';

interface History {
	year: string;
	month: string;
	title: string;
	applicants: string;
}

const MngCmptMReg = () => {
	const [histories, setHistories] = useState<History[]>([]);

	return (
		<div>
			<MyCompetitionHeader title="대회마스터" />
			<MasterSearch />
			<div className={styles.container}>
				<div className={styles.left_section}>
					<h1>대회마스터 등록하기</h1>
					<p>
						대회등록을 위해서는 <br />
						대회마스터가 반드시 사전에
						<br /> 등록되어 있어야 합니다. <br />
						<br />
						등록된 대회마스터가 없으신
						<br />
						회원님은 대회마스터를
						<br />
						먼저 등록해주시기 바랍니다.
					</p>
				</div>
				<div className={styles.right_section}>
					<div className={styles.back_button_container}>
						<div className={styles.back_button}>
							<p>목록으로 돌아가기</p>
							<MenuIcon />
						</div>
					</div>
					<div className={styles.input_list}>
						<div className={styles.input_row}>
							<label
								htmlFor="url"
								className={styles.input_type}
							>
								마스터 대회명
							</label>
							<div className={styles.input_value}>
								<Input id="url" />
							</div>
						</div>
						<div className={styles.input_row}>
							<label
								htmlFor="detail_competition"
								className={styles.input_type}
							>
								경기종목
							</label>
							<select
								name="detail_competition"
								id="detail_competition"
								className={styles.input_value}
							>
								<option value="1">경기종목 선택</option>
							</select>
						</div>
						<div className={styles.input_row}>
							<label
								htmlFor="location"
								className={styles.input_type}
							>
								주요 개최지역
							</label>
							<select
								name="location"
								id="location"
								className={styles.input_value}
							>
								<option value="1">시/군/구 선택</option>
							</select>
						</div>
						<div className={styles.input_row}>
							<label
								htmlFor="target"
								className={styles.input_type}
							>
								참가대상
							</label>
							<select
								name="target"
								id="target"
								className={styles.input_value}
							>
								<option value="1">대상자 전체</option>
							</select>
						</div>
						<div className={styles.input_row}>
							<label
								htmlFor="url"
								className={styles.input_type}
							>
								대회 URL
							</label>
							<div className={styles.input_value}>
								<Input id="url" />
							</div>
						</div>
					</div>
					<div>
						<p className={styles.history}>대회연혁</p>
						<Table>
							<TableHeader>
								<TableRow>
									<TableHead className={styles.history_date}>
										개최년월
									</TableHead>
									<TableHead className={styles.history_title}>
										회차 + 대회명
									</TableHead>
									<TableHead className={styles.history_applicants}>
										참가인원(명)
									</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{histories.map((history, idx) => (
									<TableRow key={idx}>
										<TableCell className={styles.history_date_cell}>
											<select
												name=""
												id=""
												className={styles.history_date_year}
											>
												<option value="1">2025</option>
											</select>
											<select
												name=""
												id=""
												className={styles.history_date_month}
											>
												<option value="1">01</option>
											</select>
										</TableCell>
										<TableCell>
											<Input className={styles.history_title} />
										</TableCell>
										<TableCell className={styles.history_applicants_cell}>
											<Input />
											<button
												className={styles.history_delete_button}
												onClick={() => {
													setHistories(histories.filter((_, i) => i !== idx));
												}}
											>
												-
											</button>
										</TableCell>
									</TableRow>
								))}
								<TableRow>
									<TableCell className={styles.history_date_cell}>
										<select
											name=""
											id=""
											className={styles.history_date_year}
										>
											<option value="1">2025</option>
										</select>
										<select
											name=""
											id=""
											className={styles.history_date_month}
										>
											<option value="1">01</option>
										</select>
									</TableCell>
									<TableCell>
										<Input className={styles.history_title} />
									</TableCell>
									<TableCell className={styles.history_applicants_cell}>
										<Input />
										<button
											className={styles.history_delete_button}
											onClick={() => {
												setHistories([
													...histories,
													{
														year: '',
														month: '',
														title: '',
														applicants: '',
													},
												]);
											}}
										>
											+
										</button>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</div>
					<div className={styles.register_button_container}>
						<button className={styles.register_button}>
							대회
							<br />
							마스터
							<br />
							등록
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

function MenuIcon() {
	return (
		<svg
			width="19"
			height="17"
			viewBox="0 0 19 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="19"
				height="3"
				fill="#777777"
			/>
			<rect
				y="7"
				width="19"
				height="3"
				fill="#777777"
			/>
			<rect
				y="14"
				width="19"
				height="3"
				fill="#777777"
			/>
		</svg>
	);
}

export default MngCmptMReg;
