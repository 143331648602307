import React from 'react';

import styles from './Pagination.module.css';

const Pagination = () => {
	return (
		<div className={styles.pagination}>
			<p>1</p>
			<p>~</p>
			<p>6</p>
			<p className={styles.current_page}>7</p>
			<p>8</p>
			<p>~</p>
			<p>13</p>
		</div>
	);
};

export default Pagination;
