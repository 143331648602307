import React, { useState } from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import MasterSearch from '../../../common/MasterSearch/MasterSearch.tsx';
import styles from './MngCmptLRegMain.module.css';
import Input from '../../../common/Input/Input.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';
import SportRegistrationStep from '../../../common/SportRegistrationStep/SportRegistrationStep.tsx';
import clsx from 'clsx';
import Checkbox from '../../../common/Checkbox/Checkbox.tsx';

import posterBlank from './blank_poster.png';
import thumbnailBlank from './blank_thumbnail.png';
import HTMLEditor from '../../../common/HTMLEditor/HTMLEditor.tsx';
import FileInput from '../../../common/FileInput/FileInput.tsx';
import Button from '../../../common/Button/Button.tsx';
import ArrowRight from '../../../common/Icons/ArrowRight.tsx';
import Modal from '../../../common/Modal/Modal.tsx';

const MngCmptLRegMain = () => {
	const [hosts, setHosts] = useState<string[]>([]);
	const [subjectivities, setSubjectivities] = useState<string[]>([]);
	const [sponsors, setSponsors] = useState<string[]>([]);
	const [boosters, setBoosters] = useState<string[]>([]);
	const [locations, setLocations] = useState<string[]>([]);
	const [attachments, setAttachments] = useState<File[]>([]);

	const [descriptions, setDescriptions] = useState<
		{
			title: string;
			content: string;
		}[]
	>([
		{
			title: '',
			content: '',
		},
	]);

	const [isOrganizationPopupOpen, setIsOrganizationPopupOpen] = useState(false);

	return (
		<div>
			<MyCompetitionHeader title="대회마스터" />
			<div className={styles.container}>
				<div className={styles.left_section}>
					<div className={styles.sub_title}>
						<h1>신규대회 등록</h1>
						<p>
							아래의 순서에 따라
							<br />
							개최하고 싶은 대회의
							<br />
							정보를 입력하시면
							<br />
							해당 대회를 등록하실 수 있습니다.
						</p>
					</div>
					<SportRegistrationStep step={1} />
					<div className={styles.image_container}>
						<h2 className={styles.image_title}>대회 포스터</h2>
						<div>
							<img
								src={posterBlank}
								alt=""
								className={styles.poster}
							/>
						</div>
						<FileInput />
						<span className={styles.image_description}>
							대회포스터는 대회요강에 화면에 등록되는 이미지로 클릭하시면
							팝업으로 전체크기의 포스터에서 자세한 내용을 를 확인할 수
							있습니다.
							<br />
							<br />
							썸네일 포스터는 메인페이지 및 대회리스트에서 보여지는 이미지로
							클릭하시면 해당 대회로 이동합니다.
						</span>
					</div>
					<div>
						<h2 className={styles.image_title}>썸네일 이미지</h2>
						<div>
							<img
								src={thumbnailBlank}
								alt=""
								className={styles.thumbnail}
							/>
						</div>
						<FileInput />
					</div>
				</div>
				<div className={styles.right_section}>
					<div className={styles.title}>
						<h1>1. 대회정보</h1>
						<span>대회마스터 등록하기 M</span>
					</div>
					<div>
						<div className={styles.field}>
							<span className={clsx(styles.field_title, styles.bold)}>
								마스터대회 선택
							</span>
							<div className={clsx(styles.field_content, styles.row)}>
								<select className={styles.content_30}>
									<option value="1">경기종목 선택</option>
								</select>
								<select className={styles.content_70}>
									<option value="1">대회 마스터</option>
								</select>
							</div>
						</div>
					</div>
					<div className={styles.divider} />
					<div className={styles.base_info}>
						<h2>대회 기본정보</h2>
						<div className={styles.field}>
							<span className={styles.field_title}>대회명</span>
							<div className={clsx(styles.field_content, styles.row)}>
								<Input className={styles.content_100} />
							</div>
						</div>
						<div className={styles.field}>
							<span className={styles.field_title}>주최</span>
							<div className={styles.added_row}>
								{hosts.map((host, idx) => (
									<Input
										className={styles.added_row_input}
										value={host}
									/>
								))}
								<button
									className={styles.control_button}
									onClick={() => {
										setIsOrganizationPopupOpen(true);
									}}
								>
									+
								</button>
							</div>
						</div>
						<div className={styles.field}>
							<span className={styles.field_title}>주관</span>
							<div className={styles.added_row}>
								{subjectivities.map((subjectivity, idx) => (
									<div
										className={styles.added_row_input}
										onClick={() => {
											setSubjectivities((prev) =>
												prev.filter((_, i) => i !== idx),
											);
										}}
									>
										{subjectivity}
									</div>
								))}
								<button
									className={styles.control_button}
									onClick={() => {
										setSubjectivities([
											...subjectivities,
											Math.random().toString(),
										]);
									}}
								>
									+
								</button>
							</div>
						</div>
						<div className={styles.field}>
							<span className={styles.field_title}>후원</span>
							<div className={styles.added_row}>
								{sponsors.map((sponsor, idx) => (
									<div
										className={styles.added_row_input}
										onClick={() => {
											setSponsors((prev) => prev.filter((_, i) => i !== idx));
										}}
									>
										{sponsor}
									</div>
								))}
								<button
									className={styles.control_button}
									onClick={() => {
										setSponsors([...sponsors, Math.random().toString()]);
									}}
								>
									+
								</button>
							</div>
						</div>
						<div className={styles.field}>
							<span className={styles.field_title}>협찬</span>
							<div className={styles.added_row}>
								{boosters.map((booster, idx) => (
									<div
										className={styles.added_row_input}
										onClick={() => {
											setBoosters((prev) => prev.filter((_, i) => i !== idx));
										}}
									>
										{booster}
									</div>
								))}
								<button
									className={styles.control_button}
									onClick={() => {
										setBoosters([...boosters, Math.random().toString()]);
									}}
								>
									+
								</button>
							</div>
						</div>
						<div className={styles.field}>
							<span className={styles.field_title}>대회기간</span>
							<div className={clsx(styles.field_content, styles.row)}>
								<Input
									className={styles.content_50}
									type="date"
								/>
								<span className={styles.date_separator}>~</span>
								<Input
									className={styles.content_50}
									type="date"
								/>
							</div>
						</div>
						<div className={styles.field}>
							<span className={styles.field_title}>접수기간</span>
							<div className={clsx(styles.field_content, styles.row)}>
								<Input
									className={styles.content_50}
									type="date"
								/>
								<span className={styles.date_separator}>~</span>
								<Input
									className={styles.content_50}
									type="date"
								/>
							</div>
						</div>
						<div className={styles.field}>
							<span className={styles.field_title}>개회식</span>
							<div className={clsx(styles.field_content, styles.row)}>
								<Input
									className={styles.content_50}
									type="date"
								/>
								<span className={styles.time_separator}>시간</span>
								<div className={styles.content_50}>
									<div className={styles.checkbox_container}>
										<Input
											className={styles.time_input}
											type="time"
										/>
										<Checkbox></Checkbox>
										<label
											htmlFor=""
											className={styles.text}
										>
											개회식 없음
										</label>
									</div>
								</div>
							</div>
						</div>
						<div className={styles.field}>
							<span className={styles.field_title}>대회장소</span>
							<div className={styles.field_content}>
								{locations.map((location, idx) => (
									<div className={styles.row}>
										<Input
											className={styles.content_100}
											value={location}
											onChange={(e) => {
												const newLocations = [...locations];
												newLocations[idx] = e.target.value;
												setLocations(newLocations);
											}}
										/>
										<button
											className={styles.control_button}
											onClick={() => {
												if (idx === locations.length - 1) {
													setLocations([...locations, '']);
												} else {
													const newLocations = [...locations];
													newLocations.splice(idx, 1);
													setLocations(newLocations);
												}
											}}
										>
											{idx === locations.length - 1 ? '+' : '-'}
										</button>
									</div>
								))}
							</div>
						</div>
						<div className={styles.field}>
							<span className={styles.field_title}>대회지역</span>
							<div className={clsx(styles.field_content, styles.row)}>
								<select
									name=""
									id=""
									className={styles.content_50}
								>
									<option>시/도 선택</option>
								</select>
								<select
									name=""
									id=""
									className={styles.content_50}
								>
									<option>시/군/구 선택</option>
								</select>
							</div>
						</div>
						<div className={styles.field}>
							<span className={styles.field_title}>모집인원</span>
							<div className={styles.field_content}>
								<div className={styles.row}>
									<Input
										className={styles.content_50}
										type="number"
									/>
									<span className={styles.text}>명</span>
									<div className={styles.checkbox_container}>
										<Checkbox></Checkbox>
										<label
											htmlFor=""
											className={styles.text}
										>
											인원제한 없음
										</label>
									</div>
								</div>
							</div>
						</div>
						<div className={styles.field}>
							<span className={styles.field_title}>참가대상</span>
							<div className={styles.field_content}>
								<div className={clsx(styles.wrap, styles.row)}>
									<div
										className={clsx(
											styles.checkbox_container,
											styles.target_checkbox,
										)}
									>
										<Checkbox></Checkbox>
										<label
											htmlFor=""
											className={styles.text}
										>
											제한없음
										</label>
									</div>
									<div
										className={clsx(
											styles.checkbox_container,
											styles.target_checkbox,
										)}
									>
										<Checkbox></Checkbox>
										<label
											htmlFor=""
											className={styles.text}
										>
											초등학생
										</label>
									</div>
									<div
										className={clsx(
											styles.checkbox_container,
											styles.target_checkbox,
										)}
									>
										<Checkbox></Checkbox>
										<label
											htmlFor=""
											className={styles.text}
										>
											중학생
										</label>
									</div>
									<div
										className={clsx(
											styles.checkbox_container,
											styles.target_checkbox,
										)}
									>
										<Checkbox></Checkbox>
										<label
											htmlFor=""
											className={styles.text}
										>
											고등학생
										</label>
									</div>
									<div
										className={clsx(
											styles.checkbox_container,
											styles.target_checkbox,
										)}
									>
										<Checkbox></Checkbox>
										<label
											htmlFor=""
											className={styles.text}
										>
											일반인
										</label>
									</div>
									<div
										className={clsx(
											styles.checkbox_container,
											styles.target_checkbox,
										)}
									>
										<Checkbox></Checkbox>
										<label
											htmlFor=""
											className={styles.text}
										>
											일반인
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.divider} />
					<div>
						<div className={styles.field}>
							<span className={clsx(styles.field_title, styles.bold)}>
								대회 정산정보
							</span>
							<div className={clsx(styles.field_content, styles.row)}>
								<select
									name=""
									id=""
								>
									<option value="1">은행명 선택</option>
								</select>
								<Input placeholder="계좌번호" />
								<Input placeholder="예금자명" />
							</div>
						</div>
					</div>
					<div className={styles.divider} />
					<div>
						<div className={styles.description_title}>
							<strong>대회 요강 등록</strong>
							<p>
								입력항목 이외에도 제목과 내용을 자유롭게 추가하여 등록하실 수
								있습니다.
							</p>
						</div>
						<div>
							{descriptions.map((description, idx) => (
								<div key={idx}>
									<h3>{description.title}</h3>
									<div className={clsx(styles.row, styles.description_select)}>
										<select
											name=""
											id=""
											className={styles.content_30}
										>
											<option value="1">입력항목 선택</option>
										</select>
										<Input />
									</div>
									<HTMLEditor
										value={description.content}
										onChange={(value) => {
											const newDescriptions = [...descriptions];
											newDescriptions[idx].content = value;
											setDescriptions(newDescriptions);
										}}
									/>
									<div className={styles.description_control_button_row}>
										<div className={styles.description_control_button}>
											<button
												onClick={() => {
													const newDescriptions = [...descriptions];
													newDescriptions.splice(idx, 1);
													setDescriptions(newDescriptions);
												}}
												className={styles.control_button}
											>
												-
											</button>
											제목 / 내용 삭제하기
										</div>
										<div className={styles.description_control_button}>
											제목 / 내용 추가하기
											<button
												onClick={() => {
													setDescriptions([
														...descriptions,
														{ title: '', content: '' },
													]);
												}}
												className={styles.control_button}
											>
												+
											</button>
										</div>
									</div>
									<div className={styles.divider} />
								</div>
							))}
						</div>
						<div className={styles.field}>
							<span className={styles.field_title}>첨부파일</span>
							<div className={styles.attach_file_list}>
								{attachments.map((attachment, idx) => (
									<div className={styles.attach_file}>
										<FileInput
											fileName={attachment.name}
											onChange={(e) => {
												const newAttachments = [...attachments];
												if (e.target.files && e.target.files.length > 0) {
													newAttachments[idx] = e.target.files[0];
												}
												setAttachments(newAttachments);
											}}
										/>
										<button
											className={styles.control_button}
											onClick={() => {
												const newAttachments = [...attachments];
												newAttachments.splice(idx, 1);
												setAttachments(newAttachments);
											}}
										>
											-
										</button>
									</div>
								))}
								<div
									className={styles.attach_file}
									onClick={() => {
										setAttachments([...attachments, new File([], '')]);
									}}
								>
									<FileInput />
									<button className={styles.control_button}>+</button>
								</div>
							</div>
						</div>
						<div className={styles.divider} />
						<div className={styles.footer}>
							<div className={styles.footer_fake} />
							<div className={styles.footer_progress}>
								<strong>1</strong>
								<span> / </span>
								<span>5</span>
							</div>
							<Button
								weight="bold"
								suffixIcon={<ArrowRight />}
							>
								저장 및 다음단계로 이동
							</Button>
						</div>
					</div>
				</div>
			</div>
			<OrganizationPopup
				open={isOrganizationPopupOpen}
				onClose={() => setIsOrganizationPopupOpen(false)}
			/>
		</div>
	);
};

function OrganizationPopup({
	open,
	onClose,
}: {
	open: boolean;
	onClose: () => void;
}) {
	const dummy = {
		organization: [
			{
				id: 1,
				name: '오래도록 건강하게',
				date: '2025-01-01',
				valid: true,
			},
			{
				id: 2,
				name: '강철스매시',
				date: '2025-01-01',
				valid: true,
			},
			{
				id: 3,
				name: '건강한 삶',
				date: '2025-01-01',
				valid: true,
			},
			{
				id: 4,
				name: '스포츠 매니아',
				date: '2025-01-01',
				valid: true,
			},
			{
				id: 5,
				name: '피트니스 챔피언',
				date: '2025-01-01',
				valid: true,
			},
			{
				id: 6,
				name: '헬스 마스터',
				date: '2025-01-01',
				valid: true,
			},
			{
				id: 7,
				name: '운동의 즐거움',
				date: '2025-01-01',
				valid: true,
			},
			{
				id: 8,
				name: '활기찬 생활',
				date: '2025-01-01',
				valid: true,
			},
			{
				id: 9,
				name: '건강한 미래',
				date: '2025-01-01',
				valid: true,
			},
			{
				id: 10,
				name: '스포츠 애호가',
				date: '2025-01-01',
				valid: true,
			},
		],
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			title="기관/단체/업체 찾기"
			description="기관, 단체, 업체 등 유형을 먼저 선택하신 후, 입력하신 이름으로 검색 또는 신규등록할  수 있습니다.   "
		>
			<div className={styles.search_row}>
				<select className={styles.search_select}>
					<option value="1">유형 선택</option>
				</select>
				<Input className={styles.search_input}></Input>
				<button className={styles.search_button}>검색</button>
				<button className={styles.search_button}>신규등록</button>
			</div>
			<Table>
				<TableHeader>
					<TableRow>
						<TableHead className={styles.no}>No.</TableHead>
						<TableHead className={styles.name}>단체명</TableHead>
						<TableHead className={styles.date}>등록일자</TableHead>
						<TableHead className={styles.valid}>유효여부</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody className={styles.list_table_body}>
					{dummy.organization.map((item) => (
						<TableRow key={item.id}>
							<TableCell className={styles.no}>{item.id}</TableCell>
							<TableCell className={styles.name}>{item.name}</TableCell>
							<TableCell className={styles.date}>{item.date}</TableCell>
							<TableCell className={styles.valid}>
								<Checkbox></Checkbox>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<p className={styles.table_subtitle}>선택된 단체 (7)</p>
			<Table>
				<TableHeader>
					<TableRow>
						<TableHead className={styles.no}>No.</TableHead>
						<TableHead className={styles.name}>단체명</TableHead>
						<TableHead className={styles.date}>등록일자</TableHead>
						<TableHead className={styles.valid}>유효여부</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody className={styles.list_table_body}>
					{dummy.organization.map((item) => (
						<TableRow key={item.id}>
							<TableCell className={styles.no}>{item.id}</TableCell>
							<TableCell className={styles.name}>{item.name}</TableCell>
							<TableCell className={styles.date}>{item.date}</TableCell>
							<TableCell className={styles.valid}>
								<Checkbox></Checkbox>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<div className={styles.modal_footer}>
				<Button weight="bold">선정완료</Button>
			</div>
		</Modal>
	);
}

export default MngCmptLRegMain;
