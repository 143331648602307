import React from 'react';
import styles from './ApplyButton.module.css';
import clsx from 'clsx';

interface ApplyButtonProps {
	/**
	 * 좋아요 버튼 활성화 여부
	 */
	isLike?: boolean;
	/**
	 * 좋아요 버튼 클릭 이벤트
	 */
	onClickLike?: () => void;
	/**
	 * 참가신청 버튼 클릭 이벤트
	 */
	onClickApply?: () => void;
}

const ApplyButton = ({
	isLike = false,
	onClickLike,
	onClickApply,
}: ApplyButtonProps) => {
	return (
		<div className={styles.button_group}>
			<button
				className={styles.apply_button}
				onClick={onClickApply}
			>
				<p>참가신청하기</p>
			</button>
			<button
				className={clsx(
					styles.like_button,
					isLike && styles.like_button_active,
				)}
				onClick={onClickLike}
			>
				<svg
					width="22"
					height="20"
					viewBox="0 0 22 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M11 20L9.405 18.5613C3.74 13.4714 0 10.1035 0 5.99455C0 2.6267 2.662 0 6.05 0C7.964 0 9.801 0.882834 11 2.26703C12.199 0.882834 14.036 0 15.95 0C19.338 0 22 2.6267 22 5.99455C22 10.1035 18.26 13.4714 12.595 18.5613L11 20Z"
						fill={isLike ? '#e6c1c1' : 'white'}
					/>
				</svg>
			</button>
		</div>
	);
};

export default ApplyButton;
