import React, { useState } from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import styles from './MngCmptLRegComplet.module.css';
import Input from '../../../common/Input/Input.tsx';
import SportRegistrationStep from '../../../common/SportRegistrationStep/SportRegistrationStep.tsx';
import clsx from 'clsx';
import Checkbox from '../../../common/Checkbox/Checkbox.tsx';

import HTMLEditor from '../../../common/HTMLEditor/HTMLEditor.tsx';
import FileInput from '../../../common/FileInput/FileInput.tsx';
import Button from '../../../common/Button/Button.tsx';
import ArrowRight from '../../../common/Icons/ArrowRight.tsx';
import Modal from '../../../common/Modal/Modal.tsx';
import ArrowLeft from '../../../common/Icons/ArrowLeft.tsx';

const years = Array.from({ length: 2025 - 1900 + 1 }, (_, i) =>
	(1900 + i).toString(),
);

const MngCmptLRegComplet = () => {
	const [paymentType, setPaymentType] = useState<
		'card' | 'naver' | 'kakao' | 'toss'
	>('card');

	return (
		<div>
			<MyCompetitionHeader title="대회마스터" />
			<div className={styles.container}>
				<div className={styles.left_section}>
					<div className={styles.sub_title}>
						<h1>신규대회 등록</h1>
						<p>
							아래의 순서에 따라
							<br />
							개최하고 싶은 대회의
							<br />
							정보를 입력하시면
							<br />
							해당 대회를 등록하실 수 있습니다.
						</p>
					</div>
					<SportRegistrationStep />
				</div>
				<div className={styles.right_section}>
					<div className={styles.title}>
						<h1>대회등록 완료</h1>
					</div>
					<p className={styles.description}>
						등록비용 결제가 완료되었으며, 대회가 정상적으로 등록되었습니다.
						<br />
						본사에서 심사가 완료되면 대회운영을 진행하실 수 있습니다. (등록대회
						관리에서 자유롭게 내용수정이 가능합니다.)
					</p>
					<div className={styles.divider}></div>
					<div className={styles.sport_title_container}>
						<div className={styles.sport_title}>
							제31회 국민생활체육전국배드민턴대회
						</div>
						<div className={styles.sport_title_manage}>+ 대회내역 관리</div>
					</div>
					<div className={styles.field_row}>
						<div className={styles.field_label}>일자 :</div>
						<div className={styles.field_value}>
							2024년 3월 14일 ~ 2024년 3월 15일
						</div>
					</div>
					<div className={styles.field_row}>
						<div className={styles.field_label}>장소 :</div>
						<div className={styles.field_value}>경상북도 경산시 일원 </div>
					</div>
					<div className={styles.field_row}>
						<div className={styles.field_label}>기간 :</div>
						<div className={styles.field_value}>
							2024년 2월 3일 ~ 2024년 3월 3일 (D-31)
						</div>
					</div>
					<div className={styles.field_row}>
						<div className={styles.field_label}>접수 :</div>
						<div className={styles.field_value}>
							2024년 2월 3일 ~ 2024년 3월 3일 (D-31)
						</div>
					</div>
					<div className={styles.field_row}>
						<div className={styles.field_label}>금액 :</div>
						<div className={styles.field_value}>20,000원</div>
					</div>
					<div className={styles.divider} />
					<div className={styles.table_footer}>
						<span className={styles.pagination_button}>
							<ArrowLeft
								width={24}
								height={24}
							/>
							대회 관리화면으로 이동
						</span>
						<Button
							weight="bold"
							suffixIcon={<ArrowRight />}
						>
							홈 화면으로 이동
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MngCmptLRegComplet;
