import React from 'react';

function ArrowRight(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="7"
			height="12"
			viewBox="0 0 7 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M6.40625 6L0.40625 11.1962L0.40625 0.803847L6.40625 6Z"
				fill={props.fill || 'white'}
			/>
		</svg>
	);
}

export default ArrowRight;
