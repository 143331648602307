import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
	fn_axios,
	fn_axios_post_excel_down,
	getCookieUserId,
	CONSTANTS,
} from '../../common/common.tsx';
import { API } from '../../config/config.tsx';
import AdminLyout from '../../layout/_admin/AdminLayout.tsx';

function UserManage() {
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);

	const [userMngList, setUserMngList] = useState([]);
	const [pageNum, setPageNum] = useState(() => {
		const page = queryParams.get('pageNum');
		return page ? parseInt(page) : 1;
	});
	const [pageMaxNum, setPageMaxNum] = useState(0);
	const [pageCnt] = useState(10);
	const [paging, setPaging] = useState([]);
	const [usrMngCnt, setUsrMngCnt] = useState(null);
	const [headerList, setHeaderList] = useState([]);
	const [user, setUser] = useState({ kakaoId: null, naverId: null });

	// search
	const [searchKeyword, setSearchKeyword] = useState('');
	const [selecctedOrdColumn, setSelectedOrdColumn] = useState('');
	const [selectedLogInfo, setSelectedLogInfo] = useState('');
	const [selectUserState, setSelectUserState] = useState('');

	useEffect(() => {
		getUserManagePaging(pageNum);
	}, [pageNum]);

	const getUserManagePaging = async (num: number) => {
		const params = {
			method: 'GET',
			url: `${API.BACKEND}/${API.VERSION}/admin/getUserManagePaging`,
			params: {
				pageNum: num,
				searchKeyword: searchKeyword,
				LogInfo: selectedLogInfo,
				ordColumn: selecctedOrdColumn,
				userStateCd: selectUserState
			},
		};
		fn_axios(params, callbackGetUserManagePaging, null);
	};

	const onClickSearch = () => {
		if (pageNum == 1) {
			getUserManagePaging(pageNum);
		} else {
			setPageNum(1);
		}
	};

	const handleSearchKeyword = (e) => {
		setSearchKeyword(e.target.value);
	};
	const handleOrdColumn = (e) => {
		setSelectedOrdColumn(e.target.value);
	};
	const handleLogInfo = (e) => {
		setSelectedLogInfo(e.target.value);
	};
	const handleSelectUserState = (e) => {
		setSelectUserState(e.target.value);
	}

	const callbackGetUserManagePaging = (res) => {
		setUserMngList(res.data.object);
		setUsrMngCnt(res.data.map.usrMngCnt);
		console.log(res);
		console.log(res.data.map.usrMngCnt);
		var tempInt = parseInt(res.data.map.usrMngCnt % pageCnt);
		if (tempInt != 0) {
			tempInt = parseInt(res.data.map.usrMngCnt / pageCnt) + 1;
		} else {
			tempInt = parseInt(res.data.map.usrMngCnt / pageCnt);
		}
		settingPasing(tempInt);
	};

	const settingPasing = (maxNum) => {
		setPageMaxNum(parseInt(maxNum));
		// var start = (pageNum > 2 ? ( (maxNum - pageNum > 4) ? pageNum - 2 : maxNum - pageNum) : 1);
		var start =
			maxNum <= 5 || pageNum <= 2
				? 1
				: pageNum - (maxNum - pageNum <= 2 ? 4 - (maxNum - pageNum) : 2);
		var end =
			maxNum <= 5 || maxNum - pageNum <= 2
				? maxNum
				: (pageNum < 3 ? 3 : pageNum) + 2;
		var tempList = [];

		if (maxNum != 0) {
			for (var i = start; i <= end; i++) {
				tempList.push(i);
			}
		}
		setPaging(tempList);
	};
	const onClickPaging = (e) => {
		setPageNum(parseInt(e.target.text));
		//getPointPage(e.target.text);

		return null;
	};
	const callbackUserDetaulInfo = (res) => {
		//setUserDetail(res.data.map.userDetailInfo);
		console.log(res.data);
	};

	return (
		<AdminLyout>
			<div className="">
				<h1 className="admin_title">사용자 관리</h1>
				<section className="admin_list">
					<div className="admin_bar">
						<div className="admin_total">
							{' '}
							총 <b>{usrMngCnt == null ? '0' : usrMngCnt}</b>명
						</div>
						<div className="admin_search">
							<select
								style={{ minWidth: '100px' }}
								value={selecctedOrdColumn}
								onChange={handleOrdColumn}
							>
								<option value="">정렬</option>
								<option
									key={1}
									value={'USER_ID'}
								>
									순번
								</option>
								<option
									key={1}
									value={'USER_NM'}
								>
									이름
								</option>
							</select>
							<select
								style={{ minWidth: '100px' }}
								value={selectedLogInfo}
								onChange={handleLogInfo}
							>
								<option value="">전체</option>
								<option
									key={1}
									value={'NAVER_ID'}
								>
									네이버 회원
								</option>
								<option
									key={1}
									value={'KAKO_ID'}
								>
									카카오 회원
								</option>
							</select>
							<select
								style={{ minWidth: '100px' }}
								value={selectUserState}
								onChange={handleSelectUserState}
							>
								<option value="">
									회원상태
								</option>
								<option key={1} value={'USER_STATE_DIVS_CD_01'}>정상회원</option>
								<option key={2} value={'USER_STATE_DIVS_CD_02'}>탈퇴회원</option>
							</select>
							<input
								type="text"
								style={{ minWidth: '90px' }}
								name="keyword"
								value={searchKeyword}
								onChange={handleSearchKeyword}
								placeholder="키워드"
							/>
							<button onClick={onClickSearch}>검색</button>
							{/*<button onClick={onClickExcelDown}>Excel Download</button>*/}
						</div>
					</div>
					<div
						className="admin_table type1"
						style={{ overflow: 'auto' }}
					>
						<table
							style={{
								borderCollapse: 'collapse',
								width: '100%',
								tableLayout: 'auto',
							}}
						>
							<thead>
								<tr>
									<th>No</th>
									<th>사용자명</th>
									<th>이메일</th>
									<th>닉네임</th>
									<th>성별</th>
									<th>생년월일</th>
									<th>로그인구분</th>
									<th>사용자상태</th>
									<th>마지막 로그인 일</th>
									<th>등록일</th>
									<th>수정일</th>
								</tr>
							</thead>
							<tbody>
								{userMngList.length === 0 ? (
									<tr>
										<td
											style={{ textAlign: 'center' }}
											colSpan={11}
										>
											검색된 결과가 없습니다.
										</td>
									</tr>
								) : (
									userMngList.map((item, index) => (
										<tr
											style={{ cursor: 'pointer' }}
											onClick={() =>
												(window.location.href = `/admin/UserMngDetail?userId=${item.userId}`)
											}
										>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.no}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.userNm}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.userEmail}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.userNickname}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.userGndr}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.userBirth}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.logInfoNm}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.userStateCdNm}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.lastLoginDtm}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.regrDtm}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.updateDtm}
											</td>
										</tr>
									))
								)}
							</tbody>
						</table>
					</div>
					<div className="paging">
						{paging.map((num) => (
							<a
								key={num}
								onClick={(onClickPaging) => setPageNum(num)}
							>
								{num}
							</a>
						))}
					</div>
				</section>
			</div>
		</AdminLyout>
	);
}

export default UserManage;
