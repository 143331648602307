import React from 'react';
import styles from './Table.module.css';
import clsx from 'clsx';

export const Table = (
	props: React.TableHTMLAttributes<HTMLTableElement> & {
		/**
		 * 테이블 가로 스크롤 여부
		 */
		scrollable?: boolean;
	},
) => {
	if (props.scrollable) {
		return (
			<div className={styles.table_scrollable}>
				<table
					{...props}
					className={clsx(
						styles.table,
						styles.scrollable_table,
						props.className,
					)}
				></table>
			</div>
		);
	}

	return (
		<table
			{...props}
			className={clsx(styles.table, props.className)}
		></table>
	);
};

export const TableHeader = (
	props: React.TableHTMLAttributes<HTMLTableSectionElement>,
) => {
	return (
		<thead
			{...props}
			className={clsx(styles.table_header, props.className)}
		></thead>
	);
};

export const TableHead = (
	props: React.TableHTMLAttributes<HTMLTableHeaderCellElement>,
) => {
	return (
		<th
			{...props}
			className={clsx(styles.table_head, props.className)}
		></th>
	);
};

export const TableBody = (
	props: React.TableHTMLAttributes<HTMLTableSectionElement>,
) => {
	return (
		<tbody
			{...props}
			className={clsx(styles.table_body, props.className)}
		></tbody>
	);
};

export const TableRow = (
	props: React.TableHTMLAttributes<HTMLTableRowElement>,
) => {
	return (
		<tr
			{...props}
			className={clsx(styles.table_row, props.className)}
		></tr>
	);
};

interface TableCellProps
	extends React.TableHTMLAttributes<HTMLTableCellElement> {
	/**
	 * 셀 정렬 방식
	 * @default 'left'
	 */
	align?: 'left' | 'center' | 'right';
}

export const TableCell = ({ align = 'center', ...props }: TableCellProps) => {
	return (
		<td
			{...props}
			className={clsx(
				styles.table_cell,
				props.className,
				styles[`table_cell_${align}`],
			)}
		></td>
	);
};
