import React from 'react';
import Input from '../Input/Input.tsx';
import Button from '../Button/Button.tsx';
import styles from './MasterSearch.module.css';
import excelIcon from '../../assets/images/common/excel.png';

interface MasterSearchProps {
	onClickSaveButton?: () => void;
}

const MasterSearch = ({ onClickSaveButton }: MasterSearchProps) => {
	return (
		<div className={styles.search_box}>
			<select
				name=""
				id=""
				className={styles.filter_select}
			>
				<option value="1">경기종목 선택</option>
			</select>
			<Input
				type="text"
				className={styles.filter_search_input}
			/>
			<Button
				weight="bold"
				className={styles.search_button}
			>
				검색
			</Button>
			<button
				className={styles.save_button}
				onClick={onClickSaveButton}
			>
				현재 리스트 엑셀파일로 저장
				<img
					src={excelIcon}
					alt="excel"
					className={styles.save_button_icon}
				/>
			</button>
			<button className={styles.register_button}>대회마스터 등록하기 M</button>
		</div>
	);
};

export default MasterSearch;
