import React from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import MasterSearch from '../../../common/MasterSearch/MasterSearch.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';
import data from './dummy.json';
import styles from './MngGroupList.module.css';
import Checkbox from '../../../common/Checkbox/Checkbox.tsx';
import Button from '../../../common/Button/Button.tsx';
import Pagination from '../../../common/Pagination/Pagination.tsx';
import AssociationSearch from '../../../common/AssociationSearch/AssociationSearch.tsx';

const MngGroupList = () => {
	return (
		<div>
			<MyCompetitionHeader title="기관/단체/업체" />
			<AssociationSearch />
			<Table>
				<TableHeader>
					<TableRow>
						<TableHead className={styles.check_box}>
							<Checkbox />
						</TableHead>
						<TableHead className={styles.number}>No.</TableHead>
						<TableHead className={styles.type}>유형</TableHead>
						<TableHead className={styles.name}>이름</TableHead>
						<TableHead className={styles.business_number}>사업자번호</TableHead>
						<TableHead className={styles.address}>주소</TableHead>
						<TableHead className={styles.date}>등록일</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody className={styles.table_body}>
					{data.object.map((item) => (
						<TableRow className={styles.table_row}>
							<TableCell className={styles.check_box}>
								<Checkbox />
							</TableCell>
							<TableCell className={styles.number}>{item.number}</TableCell>
							<TableCell className={styles.type}>{item.type}</TableCell>
							<TableCell className={styles.name}>{item.name}</TableCell>
							<TableCell className={styles.business_number}>
								{item.unique_id}
							</TableCell>
							<TableCell className={styles.address}>{item.address}</TableCell>
							<TableCell className={styles.date}>{item.date}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<div className={styles.table_footer}>
				<Button
					weight="bold"
					disabled
				>
					선택항목 삭제
				</Button>
				<Pagination />
				<div className={styles.blank_div}></div>
			</div>
		</div>
	);
};

export default MngGroupList;
