import React from 'react';
import { useEffect } from 'react';

import styles from './NotFoundPage.module.css';

import Layout from '../../../layout/Layout.tsx';
import Slider from '../../../layout/Slider.tsx';
import Button from '../../../common/Button/Button.tsx';
import { Link, useNavigate } from 'react-router-dom';
import ArrowRight from '../../../common/Icons/ArrowRight.tsx';
import ArrowLeft from '../../../common/Icons/ArrowLeft.tsx';

function NotFoundPage() {
	const navigate = useNavigate();

	return (
		<Layout>
			<section className={styles.container}>
				<div className={styles.content}>
					<div className={styles.text_wrapper}>
						<h1 className={styles.title}>Page Not Found</h1>
						<p className={styles.description}>
							죄송합니다.
							<br className={styles.line_space} /> 요청하신 페이지를 찾을 수
							없습니다.
						</p>
						<p className={styles.description_detail}>
							존재하지 않는 주소이거나 변경 <br className={styles.line_space} />{' '}
							또는 삭제된 페이지입니다.
						</p>
					</div>
					<div className={styles.button_container}>
						<Button
							variant="black"
							onClick={() => navigate('/')}
							suffixIcon={<ArrowRight className={styles.button_icon} />}
							weight="bold"
						>
							홈으로 이동하기
						</Button>
						<Button
							variant="white"
							onClick={() => navigate(-1)}
							prefixIcon={<ArrowLeft className={styles.button_icon} />}
							weight="bold"
						>
							이전페이지로 돌아가기
						</Button>
					</div>
				</div>
			</section>
		</Layout>
	);
}

export default NotFoundPage;
