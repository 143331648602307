import React from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import styles from './MngCmptLSummary.module.css';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';

import dummy from './dummy.json';
import SportHeader from '../../../common/SportHeader/SportHeader.tsx';

const MngCmptLSummary = () => {
	return (
		<div className={styles.content}>
			<div>
				<MyCompetitionHeader title="대회관리" />
				<SportHeader
					title="제31회 국민생활체육전국배드민턴대회"
					date="2024년 3월 14일"
					views={1312}
					participants={219}
					currentCategory="대회현황"
				/>
			</div>
			<div className={styles.competition_detail}>
				<img
					src={
						'https://s3-alpha-sig.figma.com/img/af8a/0b79/8d2343e80b525d974c4815d0420c061a?Expires=1740355200&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=HOKeXpr8tt7fiL01M3LHdh0JQohkG6Wmvbpj2En8b64GFPHMBJWALePAS82IuejP216HQmZ0GmFN68ftNHiZrP0GP-8VBlVTj~pJQyI4Tp9Fls2WvX18zhm9VkqC0LJZpJaHBce~C-uwKWRirmjzI2UMtJABPVVbQGn3DRGBFN~HyftbnmZpDUmbKJ4M~4yfL~8yazyRxsmr8ykz15BWB-gs0zqPv0kyCEPWv8l7vrQJqfzcBnYCcIYtGQ5sTkjz9L0fZu8-Jg1WFX5B1CsY57HbphzuHQf7hTtb0oaFl5Ed0~OJd01gJqmQ9e7cH4achKrro6kjAmvZZttF11W0tA__'
					}
					alt=""
					className={styles.competition_thumbnail}
				/>
				<div className={styles.competition_info}>
					<div className={styles.competition_info_title_box}>
						<p className={styles.competition_info_title}>
							제31회 국민생활체육전국배드민턴대회
						</p>
						<button className={styles.action_button}>+대회내역 관리</button>
					</div>
					<div className={styles.competition_info_list}>
						<div className={styles.competition_info_item_box}>
							<p className={styles.competition_info_item_key}>일자 :</p>
							<p className={styles.competition_info_item_value}>
								2024년 3월 14일 ~ 2024년 3월 15일
							</p>
						</div>
						<div className={styles.competition_info_item_box}>
							<p className={styles.competition_info_item_key}>장소 :</p>
							<p className={styles.competition_info_item_value}>
								경상북도 경산시 일원
							</p>
						</div>
						<div className={styles.competition_info_item_box}>
							<p className={styles.competition_info_item_key}>기간 :</p>
							<p className={styles.competition_info_item_value}>
								2024년 2월 3일 ~ 2024년 3월 3일 (D-31)
							</p>
						</div>
						<div className={styles.competition_info_item_box}>
							<p className={styles.competition_info_item_key}>접수 :</p>
							<p className={styles.competition_info_item_value}>
								2024년 2월 3일 ~ 2024년 3월 3일 (D-21)
							</p>
						</div>
						<div className={styles.competition_info_item_box}>
							<p className={styles.competition_info_item_key}>금액 :</p>
							<p className={styles.competition_info_item_value}>
								총입금액 12,000,000 원
							</p>
						</div>
						<div className={styles.competition_info_item_box}>
							<p className={styles.competition_info_item_key}>인원 :</p>
							<p className={styles.competition_info_item_value}>
								신청인원 1,092 / 1,000 명 / 참가인원 1,000 명(500팀)
							</p>
						</div>
						<div className={styles.competition_info_item_box}>
							<p className={styles.competition_info_item_key}>결재 :</p>
							<p className={styles.competition_info_item_value}>
								결재완료 546명 - 결재취소 46명 = 최종 결재확인 500명
							</p>
						</div>
						<div className={styles.competition_info_item_box}>
							<p className={styles.competition_info_item_key}>참여 :</p>
							<p className={styles.competition_info_item_value}>
								조회수 1,891 / 좋아요 812
							</p>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div className={styles.entry_table_header}>
					<div className={styles.title_box}>
						<p className={styles.title}>참가명단</p>
						<button className={styles.action_button}>+ 참가명단 관리</button>
					</div>
					<div className={styles.entry_table_select_list}>
						<select
							className={styles.select_box}
							placeholder="연령"
						>
							<option value="all">전체</option>
							<option value="20">20대</option>
							<option value="30">30대</option>
							<option value="40">40대</option>
							<option value="50">50대</option>
							<option value="60">60대</option>
						</select>
						<select
							className={styles.entry_table_select_box}
							placeholder="상태"
						>
							<option value="all">전체</option>
							<option value="pending">신청중</option>
							<option value="completed">결제완료</option>
							<option value="canceled">취소됨</option>
						</select>
					</div>
				</div>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead className={styles.entry_table_no_head}>No.</TableHead>
							<TableHead className={styles.entry_table_name_head}>
								이름
							</TableHead>
							<TableHead className={styles.entry_table_phone_head}>
								전화번호
							</TableHead>
							<TableHead className={styles.entry_table_email_head}>
								이메일
							</TableHead>
							<TableHead className={styles.entry_table_details_head}>
								신청 세부종목
							</TableHead>
							<TableHead className={styles.entry_table_status_head}>
								상태
							</TableHead>
							<TableHead className={styles.entry_table_date_head}>
								결제 / 취소일시
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody className={styles.entry_table_table_body}>
						{dummy.entry.map((item) => (
							<TableRow key={item.id}>
								<TableCell className={styles.entry_table_no_head}>
									{item.id}
								</TableCell>
								<TableCell className={styles.entry_table_name_head}>
									{item.name}
								</TableCell>
								<TableCell className={styles.entry_table_phone_head}>
									{item.phone}
								</TableCell>
								<TableCell
									className={styles.entry_table_email_head}
									align="left"
								>
									{item.email}
								</TableCell>
								<TableCell className={styles.entry_table_details_head}>
									{item.details}
								</TableCell>
								<TableCell className={styles.entry_table_status_head}>
									{item.status}
								</TableCell>
								<TableCell className={styles.entry_table_date_head}>
									{item.date}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
			<div>
				<div className={styles.disciplines_header}>
					<div className={styles.title_box}>
						<p className={styles.title}>세부종목</p>
						<button className={styles.action_button}>+ 세부종목 관리</button>
					</div>
					<div className={styles.disciplines_select_list}>
						<select className={styles.disciplines_select_box}>
							<option value="all">전체</option>
							<option value="men">남자복식</option>
							<option value="women">여자복식</option>
							<option value="mixed">혼합복식</option>
						</select>
						<select className={styles.select_box}>
							<option value="all">전체</option>
							<option value="20">20대</option>
							<option value="30">30대</option>
							<option value="40">40대</option>
							<option value="50">50대</option>
							<option value="60">60대</option>
						</select>
						<select className={styles.disciplines_select_box}>
							<option value="all">전체</option>
							<option value="pending">A</option>
							<option value="completed">B</option>
							<option value="canceled">C</option>
						</select>
					</div>
				</div>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead className={styles.disciplines_no_head}>No.</TableHead>
							<TableHead className={styles.disciplines_group_head}>
								종목
							</TableHead>
							<TableHead className={styles.disciplines_age_head}>
								연령
							</TableHead>
							<TableHead className={styles.disciplines_grade_head}>
								등급
							</TableHead>
							<TableHead className={styles.disciplines_applicants_head}>
								신청 세부종목
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody className={styles.disciplines_table_body}>
						{dummy.disciplines.map((item) => (
							<TableRow key={item.id}>
								<TableCell className={styles.disciplines_no_head}>
									{item.id}
								</TableCell>
								<TableCell className={styles.disciplines_group_head}>
									{item.group}
								</TableCell>
								<TableCell className={styles.disciplines_age_head}>
									{item.age}
								</TableCell>
								<TableCell className={styles.disciplines_grade_head}>
									{item.grade}
								</TableCell>
								<TableCell className={styles.disciplines_applicants_head}>
									{item.applicants}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
			<div className={styles.table_row}>
				<div className={styles.simple_table_box}>
					<div className={styles.title_box}>
						<p className={styles.title}>공지사항</p>
						<button className={styles.action_button}>+ 더보기</button>
					</div>
					<div>
						<p className={styles.simple_table_date_head}>등록일</p>
						<p className={styles.simple_table_views_head}>조회수</p>
						<table className={styles.simple_table}>
							<tbody>
								{dummy.notices.map((item, idx) => (
									<tr
										key={item.title + idx}
										className={styles.simple_table_row}
									>
										<td className={styles.simple_table_title}>{item.title}</td>
										<td className={styles.simple_table_date}>{item.date}</td>
										<td className={styles.simple_table_views}>{item.views}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<div className={styles.simple_table_box}>
					<div className={styles.title_box}>
						<p className={styles.title}>1:1 문의</p>
						<button className={styles.action_button}>+ 더보기</button>
					</div>
					<div>
						<p className={styles.simple_table_date_head}>등록일</p>
						<p className={styles.simple_table_views_head}>조회수</p>
						<table className={styles.simple_table}>
							<tbody>
								{dummy.inquiries.map((item, idx) => (
									<tr
										key={item.title + idx}
										className={styles.simple_table_row}
									>
										<td className={styles.simple_table_title}>{item.title}</td>
										<td className={styles.simple_table_date}>{item.date}</td>
										<td className={styles.simple_table_views}>{item.views}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MngCmptLSummary;
