import React, { useState } from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import MasterSearch from '../../../common/MasterSearch/MasterSearch.tsx';
import styles from './MngCmptLRegGame.module.css';
import Input from '../../../common/Input/Input.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';
import SportRegistrationStep from '../../../common/SportRegistrationStep/SportRegistrationStep.tsx';
import clsx from 'clsx';
import Checkbox from '../../../common/Checkbox/Checkbox.tsx';

import data from './dummy.json';

import FileInput from '../../../common/FileInput/FileInput.tsx';
import Button from '../../../common/Button/Button.tsx';
import ArrowRight from '../../../common/Icons/ArrowRight.tsx';
import Modal from '../../../common/Modal/Modal.tsx';
import ArrowLeft from '../../../common/Icons/ArrowLeft.tsx';
import PreviousEventModal from './previous-modal/PreviousEventModal.tsx';
import BatchModal from './batch-modal/BatchModal.tsx';
import DetailModal from './detail-modal/DetailModal.tsx';

const years = Array.from({ length: 2025 - 1900 + 1 }, (_, i) =>
	(1900 + i).toString(),
);

const MngCmptLRegGame = () => {
	const [detailGames, setDetailGames] = useState<
		{
			no: number;
			category: string;
			age_start: number;
			age_end: number;
			rank: string;
			applicants: string;
			edit: boolean;
		}[]
	>(() =>
		data.map.map((item) => ({
			no: item.no,
			category: item.category,
			age_start: item.age_start,
			age_end: item.age_end,
			rank: item.rank,
			applicants: item.applicants,
			edit: false,
		})),
	);

	const [previousEventModalOpen, setPreviousEventModalOpen] = useState(false);
	const [batchModalOpen, setBatchModalOpen] = useState(false);
	const [detailModalOpen, setDetailModalOpen] = useState(false);

	return (
		<div>
			<MyCompetitionHeader title="대회마스터" />
			<div className={styles.container}>
				<div className={styles.left_section}>
					<div className={styles.sub_title}>
						<h1>신규대회 등록</h1>
						<p>
							아래의 순서에 따라
							<br />
							개최하고 싶은 대회의
							<br />
							정보를 입력하시면
							<br />
							해당 대회를 등록하실 수 있습니다.
						</p>
					</div>
					<SportRegistrationStep step={2} />
				</div>
				<div className={styles.right_section}>
					<div className={styles.title}>
						<h1>2. 세부종목 설정</h1>
						<span onClick={() => setPreviousEventModalOpen(true)}>
							이전대회 세부종목 불러오기
						</span>
					</div>
					<p className={styles.description}>
						개최되는 대회의 세부종목을 추가해주세요. 여러개의 종목을 동시에
						추가하실 수 있습니다.<br></br> 지금 단계에서 복잡한 경우에는 대회
						등록완료 후 다시설정하셔도 됩니다.
					</p>
					<div className={styles.sub_title_row}>
						<p className={styles.sub_title_text}>배드민턴 경기구분</p>
						<p
							className={styles.sub_title_action}
							onClick={() => setBatchModalOpen(true)}
						>
							경기구분 일괄 생성하기
						</p>
					</div>
					<div className={styles.field_row}>
						<div className={styles.field}>
							<p className={clsx(styles.field_type, styles.bold)}>필수항목</p>
							<p className={styles.field_value}>
								: 참가연령, 성별은 사용자 신청 시 자동으로 체크됩니다.
							</p>
						</div>
					</div>
					<div className={styles.field_row}>
						<div className={styles.field}>
							<p className={styles.field_type}>세부종목명</p>
							<Input className={styles.field_value}></Input>
						</div>
						<div className={styles.field}>
							<p className={styles.field_type}>최소 참가인원</p>
							<Input className={styles.field_value}></Input>
							<div className={styles.wave_divider}>~</div>
							<p className={styles.field_type}>최대 참가인원</p>
							<Input className={styles.field_value}></Input>
						</div>
					</div>
					<div className={styles.field_row}>
						<div className={styles.field}>
							<p className={styles.field_type}>참가구분</p>
							<select
								name=""
								id=""
								className={styles.field_value}
							>
								<option value="1">선택</option>
							</select>
						</div>
						<div className={styles.field}>
							<p className={styles.field_type}>경기방식</p>
							<select
								name=""
								id=""
								className={styles.field_value}
							>
								<option value="1">선택</option>
							</select>
						</div>
					</div>
					<div className={styles.field_row}>
						<div className={styles.field}>
							<p className={styles.field_type}>세부종목명</p>
							<select
								name=""
								id=""
								className={styles.field_value}
							>
								<option value="1">선택</option>
							</select>
							<div className={styles.wave_divider}>~</div>
							<select
								name=""
								id=""
								className={styles.field_value}
							>
								<option value="1">선택</option>
							</select>
						</div>
						<div className={styles.field}>
							<p className={styles.field_type}>급수설정</p>
							<select
								name=""
								id=""
								className={styles.field_value}
							>
								<option value="1">선택</option>
							</select>
						</div>
					</div>
					<div className={styles.divider}></div>
					<div className={styles.field_row}>
						<div className={styles.field}>
							<p className={clsx(styles.field_type, styles.bold)}>추가항목</p>
							<p className={styles.field_value}>
								: 대회관리자가 직접 필요한 항목을 생성하여 추가할 수 있습니다.
							</p>
						</div>
						<div className={styles.detail_add_button}>
							<span
								className={styles.sub_title_action}
								onClick={() => setDetailModalOpen(true)}
							>
								경기구분 항목 추가하기
							</span>
						</div>
					</div>
					<div className={styles.field_row}>
						<div className={styles.field}>
							<p className={styles.field_type}>장애인여부</p>
							<select
								name=""
								id=""
								className={styles.field_value}
							>
								<option value="1">선택</option>
							</select>
						</div>
						<div className={styles.field}>
							<p className={styles.field_type}>옷사이즈</p>
							<Input className={styles.field_value}></Input>
						</div>
					</div>
					<div className={styles.divider}></div>
					<div className={styles.select_item_add_container}>
						선택종목
						<div className={styles.select_item_add}>
							<svg
								width="31"
								height="35"
								viewBox="0 0 31 35"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M15.4275 2.45331e-06C14.6567 2.38592e-06 13.9175 0.299006 13.3725 0.831244C12.8275 1.36348 12.5213 2.08534 12.5213 2.83804L12.5213 25.3153L4.8875 17.8607C4.33658 17.3594 3.6079 17.0865 2.85498 17.0995C2.10207 17.1124 1.3837 17.4103 0.851224 17.9303C0.31875 18.4503 0.0137343 19.1518 0.000451455 19.887C-0.0128333 20.6223 0.266649 21.3338 0.780008 21.8718L13.3738 34.17C13.9187 34.7015 14.6573 35 15.4275 35C16.1977 35 16.9363 34.7015 17.4813 34.17L30.075 21.8718C30.3605 21.612 30.5896 21.2987 30.7484 20.9506C30.9073 20.6024 30.9927 20.2266 30.9995 19.8456C31.0064 19.4645 30.9347 19.086 30.7885 18.7326C30.6423 18.3792 30.4248 18.0582 30.1488 17.7887C29.8728 17.5192 29.5441 17.3067 29.1822 17.164C28.8203 17.0213 28.4327 16.9512 28.0425 16.9579C27.6523 16.9646 27.2674 17.048 26.9109 17.2031C26.5544 17.3583 26.2336 17.5819 25.9675 17.8607L18.3338 25.3153L18.3338 2.83804C18.3338 1.27145 17.0318 2.59355e-06 15.4275 2.45331e-06Z"
									fill="white"
								/>
							</svg>
						</div>
						추가하기
					</div>
					<div className={styles.table_header}>
						<p className={styles.table_title}>세부종목 32건</p>
						<div className={styles.select_group}>
							<select
								name=""
								id=""
								className={styles.select_item}
							>
								<option value="1">구분</option>
							</select>
							<select
								name=""
								id=""
								className={styles.select_item}
							>
								<option value="1">연령</option>
							</select>
							<select
								name=""
								id=""
								className={styles.select_item}
							>
								<option value="1">급수</option>
							</select>
						</div>
					</div>
					<Table>
						<TableHeader>
							<TableRow>
								<TableHead className={styles.checkbox}>
									<Checkbox></Checkbox>
								</TableHead>
								<TableHead className={styles.no}>No.</TableHead>
								<TableHead className={styles.category}>구분</TableHead>
								<TableHead className={styles.age}>연령</TableHead>
								<TableHead className={styles.rank}>급수</TableHead>
								<TableHead className={styles.applicants}>신청인원</TableHead>
								<TableHead className={styles.edit}></TableHead>
							</TableRow>
						</TableHeader>
						<TableBody className={styles.table_body}>
							{detailGames.map((item, index) => (
								<TableRow
									className={styles.table_row}
									key={index}
								>
									<TableCell className={styles.checkbox}>
										<Checkbox></Checkbox>
									</TableCell>
									<TableCell className={styles.no}>{item.no}</TableCell>
									<TableCell className={styles.category}>
										{item.edit ? (
											<select
												name=""
												id=""
												className={styles.select}
												onChange={(e) => {
													setDetailGames(
														detailGames.map((_item) => {
															if (_item.no === item.no) {
																return { ..._item, category: e.target.value };
															}
															return _item;
														}),
													);
												}}
												defaultValue={item.category}
											>
												<option value="혼합복식">혼합복식</option>
												<option value="남자복식">남자복식</option>
												<option value="여자복식">여자복식</option>
											</select>
										) : (
											item.category
										)}
									</TableCell>
									<TableCell className={styles.age}>
										{item.edit ? (
											<div className={styles.select_container}>
												<select
													name=""
													id=""
													defaultValue={item.age_start}
													className={styles.select}
													onChange={(e) => {
														setDetailGames(
															detailGames.map((_item) => {
																if (_item.no === item.no) {
																	return {
																		..._item,
																		age_start: parseInt(e.target.value),
																	};
																}
																return _item;
															}),
														);
													}}
												>
													{years.map((year) => (
														<option value={year}>{year}</option>
													))}
												</select>
												<div className={styles.wave_divider}>~</div>
												<select
													name=""
													id=""
													defaultValue={item.age_end}
													className={styles.select}
													onChange={(e) => {
														setDetailGames(
															detailGames.map((_item) => {
																if (_item.no === item.no) {
																	return {
																		..._item,
																		age_end: parseInt(e.target.value),
																	};
																}
																return _item;
															}),
														);
													}}
												>
													{years.map((year) => (
														<option value={year}>{year}</option>
													))}
												</select>
											</div>
										) : (
											`${item.age_start} ~ ${item.age_end}`
										)}
									</TableCell>
									<TableCell className={styles.rank}>
										{item.edit ? (
											<select
												name=""
												id=""
												className={styles.select}
												defaultValue={item.rank}
												onChange={(e) => {
													setDetailGames(
														detailGames.map((_item) => {
															if (_item.no === item.no) {
																return { ..._item, rank: e.target.value };
															}
															return _item;
														}),
													);
												}}
											>
												<option value="A">A</option>
												<option value="B">B</option>
												<option value="C">C</option>
											</select>
										) : (
											item.rank
										)}
									</TableCell>
									<TableCell className={styles.applicants}>
										{item.applicants}
									</TableCell>
									<TableCell className={styles.edit}>
										<span
											onClick={() => {
												if (item.edit) {
													setDetailGames(
														detailGames.map((_item) => {
															if (_item.no === item.no) {
																return { ..._item, edit: false };
															}
															return _item;
														}),
													);
													return;
												}
												setDetailGames(
													detailGames.map((_item) => {
														if (_item.no === item.no) {
															return { ..._item, edit: true };
														}
														return _item;
													}),
												);
											}}
										>
											{item.edit ? '완료' : '수정'}
										</span>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<div className={styles.table_footer}>
						<Button weight="bold">선택항목 삭제</Button>
						<div className={styles.pagination}>
							<span className={styles.pagination_button}>
								<ArrowLeft
									width={24}
									height={24}
								/>
								이전단계로 이동
							</span>
							<div className={styles.footer_progress}>
								<strong>2</strong>
								<span> / </span>
								<span>5</span>
							</div>
						</div>
						<Button
							weight="bold"
							suffixIcon={<ArrowRight />}
						>
							저장 및 다음단계로 이동
						</Button>
					</div>
				</div>
			</div>
			<PreviousEventModal
				open={previousEventModalOpen}
				onClose={() => setPreviousEventModalOpen(false)}
			/>
			<BatchModal
				open={batchModalOpen}
				onClose={() => setBatchModalOpen(false)}
			/>
			<DetailModal
				open={detailModalOpen}
				onClose={() => setDetailModalOpen(false)}
			/>
		</div>
	);
};

export default MngCmptLRegGame;
