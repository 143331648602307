import React, { useEffect } from "react";

const KakaoMap = ({ apiKey, latitude, longitude }) => {
    useEffect(() => {
        if (!apiKey) {
            console.error("❌ Kakao API Key가 제공되지 않음");
            return;
        }

        if (window.kakao && window.kakao.maps) {
            loadMap();
            return;
        }

        const script = document.createElement("script");
        script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${apiKey}&autoload=false`;
        script.async = true;
        script.onload = () => {
            window.kakao.maps.load(loadMap);
        };
        script.onerror = () => {
            console.error("❌ Kakao Map 스크립트 로드 실패");
        };

        document.head.appendChild(script);
    }, [apiKey, latitude, longitude]);

    const loadMap = () => {
        if (!window.kakao || !window.kakao.maps) {
            console.error("❌ Kakao Map API가 정상적으로 로드되지 않음");
            return;
        }

        const container = document.getElementById("map");
        if (!container) {
            console.error("❌ 지도 컨테이너를 찾을 수 없음");
            return;
        }

        const options = {
            center: new window.kakao.maps.LatLng(latitude, longitude),
            level: 3,
        };

        const map = new window.kakao.maps.Map(container, options);
        const marker = new window.kakao.maps.Marker({
            position: new window.kakao.maps.LatLng(latitude, longitude),
        });

        marker.setMap(map);
        console.log("🗺️ 지도 및 마커 생성 완료");
    };

    return <div id="map" style={{ width: "100%", height: "400px" }}></div>;
};

export default KakaoMap;
