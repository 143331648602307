import React from 'react';
import styles from './SportRegistrationStep.module.css';
import clsx from 'clsx';

const stepList = [
	{
		step: 1,
		title: '대회정보 입력',
	},
	{
		step: 2,
		title: '세부목록 설정',
	},
	{
		step: 3,
		title: '경품/기념품 등록',
	},
	{
		step: 4,
		title: '배너광고 신청',
	},
	{
		step: 5,
		title: '대회 등록 완료',
	},
] as const;

interface SportRegistrationStepProps {
	step?: (typeof stepList)[number]['step'];
}

const SportRegistrationStep = ({ step }: SportRegistrationStepProps) => {
	return (
		<ul className={styles.step_list}>
			{stepList.map((item) => (
				<li
					key={item.step}
					className={clsx(
						styles.step_item,
						item.step === step && styles.current_step_item,
					)}
				>
					<p className={clsx(item.step === step && styles.current_step_item)}>
						{item.step}. {item.title}
					</p>
				</li>
			))}
		</ul>
	);
};

export default SportRegistrationStep;
