import React, { useState } from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import MasterSearch from '../../../common/MasterSearch/MasterSearch.tsx';
import styles from './MngCmptLRegBanner.module.css';
import Input from '../../../common/Input/Input.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';
import SportRegistrationStep from '../../../common/SportRegistrationStep/SportRegistrationStep.tsx';
import clsx from 'clsx';
import Checkbox from '../../../common/Checkbox/Checkbox.tsx';

import HTMLEditor from '../../../common/HTMLEditor/HTMLEditor.tsx';
import FileInput from '../../../common/FileInput/FileInput.tsx';
import Button from '../../../common/Button/Button.tsx';
import ArrowRight from '../../../common/Icons/ArrowRight.tsx';
import Modal from '../../../common/Modal/Modal.tsx';
import ArrowLeft from '../../../common/Icons/ArrowLeft.tsx';

const MngCmptLRegBanner = () => {
	const [mainBanner, setMainBanner] = useState<File | null>(null);
	const [subBanner, setSubBanner] = useState<File | null>(null);

	return (
		<div>
			<MyCompetitionHeader title="대회마스터" />
			<div className={styles.container}>
				<div className={styles.left_section}>
					<div className={styles.sub_title}>
						<h1>신규대회 등록</h1>
						<p>
							아래의 순서에 따라
							<br />
							개최하고 싶은 대회의
							<br />
							정보를 입력하시면
							<br />
							해당 대회를 등록하실 수 있습니다.
						</p>
					</div>
					<SportRegistrationStep step={4} />
				</div>
				<div className={styles.right_section}>
					<div className={styles.title}>
						<h1>4. 배너 광고 등록</h1>
					</div>
					<p className={styles.description}>
						경품 및 기념품 이미지 + 내용글의 순서로 구성된 기본 모듈을 추가하며
						전체 내용을 등록하실 수 있습니다.
					</p>
					<div className={styles.divider}></div>
					<div className={styles.prize_type_container}>
						<div>
							<p className={styles.label}>게시 기간</p>
						</div>
						<Input type="date" />
					</div>
					<div className={styles.banner_row}>
						<div className={styles.banner_row_item}>
							<p className={styles.label}>메인 배너</p>
							{mainBanner ? (
								<img
									src={URL.createObjectURL(mainBanner)}
									alt="main banner"
									className={styles.thumbnail}
								/>
							) : (
								<div className={styles.default_thumbnail}>
									메인 배너 이미지를 올려주세요
								</div>
							)}
							<FileInput
								className={styles.prize_file_input}
								onChange={(e) => {
									setMainBanner(e.target.files?.[0] || null);
								}}
							/>
						</div>
						<div className={styles.banner_row_item}>
							<p className={styles.label}>서브 배너</p>
							{subBanner ? (
								<img
									src={URL.createObjectURL(subBanner)}
									alt="sub banner"
									className={styles.thumbnail}
								/>
							) : (
								<div className={styles.default_thumbnail}>
									서브 배너 이미지를 올려주세요
								</div>
							)}
							<FileInput
								className={styles.prize_file_input}
								onChange={(e) => {
									setSubBanner(e.target.files?.[0] || null);
								}}
							/>
						</div>
					</div>
					<div className={styles.divider} />
					<div className={styles.table_footer}>
						<span className={styles.pagination_button}>
							<ArrowLeft
								width={24}
								height={24}
							/>
							이전단계로 이동
						</span>
						<div className={styles.pagination}>
							<div className={styles.footer_progress}>
								<strong>3</strong>
								<span> / </span>
								<span>5</span>
							</div>
						</div>
						<Button
							weight="bold"
							suffixIcon={<ArrowRight />}
						>
							저장 및 다음단계로 이동
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MngCmptLRegBanner;
