import React, { useState } from 'react';
import styles from './Modal.module.css';
import clsx from 'clsx';

interface ModalProps {
	children: React.ReactNode;
	open: boolean;
	title?: string;
	description?: React.ReactNode;
	onClose: () => void;
	className?: string;
}

const Modal = ({
	children,
	open,
	onClose,
	title,
	description,
	className,
}: ModalProps) => {
	if (!open) return null;

	return (
		<div
			className={styles.backdrop}
			onClick={onClose}
		>
			<div
				className={clsx(styles.modal, className)}
				onClick={(e) => e.stopPropagation()}
			>
				{title && <div className={styles.modal_title}>{title}</div>}
				{description && (
					<div className={styles.modal_description}>{description}</div>
				)}
				{children}
				<button
					className={styles.close_button}
					onClick={onClose}
				>
					<svg
						width="18"
						height="17"
						viewBox="0 0 18 17"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							x="1"
							y="14.8044"
							width="20.9366"
							height="3"
							transform="rotate(-45 1 14.8044)"
							fill="#777777"
						/>
						<rect
							x="3.12109"
							width="20.9366"
							height="3"
							transform="rotate(45 3.12109 0)"
							fill="#777777"
						/>
					</svg>
				</button>
			</div>
		</div>
	);
};

export default Modal;
