import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { API } from '../../config/config.tsx';

import AdminLyout from '../../layout/_admin/AdminLayout.tsx';
import {
	CONSTANTS,
	fn_axios,
	fn_post_fileUpload_axios,
	isUnderNineteen,
} from '../../common/common.tsx';
import LareaAddr from '../../common/LareaAddr.tsx';
import GameItemProvider from '../../common/GameItemButton.tsx';
import DaumPostcode from 'react-daum-postcode';
import Modal from 'react-modal'; // 추가

function UserManageDetail() {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const userId = searchParams.get('userId'); // userId 추출
	//console.log(userId);
	// FORM
	const [userEmail, setUserEmail] = useState('');
	const [userNickname, setUserNickname] = useState('');
	const [userName, setUserName] = useState('');
	const [userProfileFileId, setUserProfileFileId] = useState('');
	const [userPhone, setUserPhone] = useState('');
	const [userAddr, setUserAddr] = useState('');
	const [userDtlAddr, setUserDtlAddr] = useState('');
	const [lareaAddr, setLareaAddr] = useState([]);
	const [userBirth, setUserBirth] = useState('');
	const [gameItems, setGameItems] = useState([]);
	const [userInfo, setUserInfo] = useState(null);
	const [userGndr, setUserGndr] = useState('');
	const [prttPhone, setPrttPhone] = useState('');
	const [profile, setProfile] = useState('');
	const [userGameItems, setUserGameItems] = useState([]);
	const [userStateDivsCd, setUserStateDivsCd] = useState('');

	// 계정 상태 옵션 리스트
	const userStateDivsList = [
		{ value: "USER_STATE_DIVS_CD_01", label: "정상 회원" },
		{ value: "USER_STATE_DIVS_CD_02", label: "탈퇴 회원" }
	]

	//값 체크용
	const [profileImage, setProfileImage] = useState('');
	const [isOpen, setIsOpen] = useState<boolean>(false); //추가
	const [isInit, setIsInit] = useState<boolean>(true); //추가
	const [userAuthList, setUserAuthList] = useState([]);
	const [originalUserInfo, setOriginUserInfo] = useState({
		userNickname: '',
		userPhone: '',
		userAddr: '',
		userDtlAddr: '',
		userBirth: '',
		userGndr: '',
		userStateDivsCd: ''
	});
	const getUserDetail = () => {
		const param = {
			method: 'GET',
			url: `${API.BACKEND}/${API.VERSION}/admin/getUserManageDetail`,
			params: { userId: userId },
		};
		fn_axios(param, callbackGetUserDetail, null);
	};
	const callbackGetUserDetail = (res) => {
		const userVo = res.data.object;
		console.log(userVo);
		setUserEmail(userVo.userEmail);
		setUserNickname(userVo.userNickname);
		setUserName(userVo.userNm);
		setUserProfileFileId(userVo.userProfileFileId);
		setUserPhone(userVo.userPhone);
		setUserAddr(userVo.userAddr);
		setUserDtlAddr(userVo.userDtlAddr);
		setUserBirth(
			userVo.userBirth.substring(0, 4) +
			'-' +
			userVo.userBirth.substring(4, 6) +
			'-' +
			userVo.userBirth.substring(6, 8),
		);
		setUserGndr(userVo.userGndr);
		setPrttPhone(userVo.prttPhone);
		setProfileImage(userVo.userProfileFileUrl);
		setUserInfo(userVo);
		setUserStateDivsCd(userVo.userStateDivsCd);

		// originalUserInfo 초기화
		setOriginUserInfo({
			userNickname: userVo.userNickname,
			userPhone: userVo.userPhone,
			userAddr: userVo.userAddr,
			userDtlAddr: userVo.userDtlAddr,
			userBirth:
				userVo.userBirth.substring(0, 4) +
				'-' +
				userVo.userBirth.substring(4, 6) +
				'-' +
				userVo.userBirth.substring(6, 8),
			userGndr: userVo.userGndr,
			userStateDivsCd: userVo.userStateDivsCd
		});
	};

	// 사용자 권한
	const getUserAuthList = () => {
		const param = {
			method: 'GET',
			url: `${API.BACKEND}/${API.VERSION}/admin/getUserAuthList`,
			params: { userId: userId },
		};
		fn_axios(param, callBackAuthList, null);
	};
	const callBackAuthList = (res) => {
		setUserAuthList(res.data.map.userAuthList);
	};

	// 관심 지역
	const getUserItrstLarea = () => {
		const param = {
			method: 'GET',
			url: `${API.BACKEND}/v1/user/getUserItrstLarea`,
			params: { userId: null },
		};
		fn_axios(param, callbackGetUserItrstLarea, null);
	};
	const callbackGetUserItrstLarea = (res) => {
		var testlist = [];
		res.data.object.map((userLareaAddr, index) =>
			// setGameItems([...gameItems ,userGameItem.gameKindCd])
			testlist.push(userLareaAddr.lareaAddrId),
		);
		if (lareaAddr == null || lareaAddr.length == 0) {
			setLareaAddr(testlist);
		}
	};

	// 관심종목
	const getUSerItrsItem = () => {
		const param = {
			method: 'GET',
			url: `${API.BACKEND}/${API.VERSION}/user/getUSerItrsItem`,
			params: { userId: userId },
		};
		fn_axios(param, callbackGetUserItrstItem, null);
	};
	const callbackGetUserItrstItem = (res) => {
		var testlist = [];
		res.data.object.map((userGameItem, index) =>
			// setGameItems([...gameItems ,userGameItem.gameKindCd])
			testlist.push(userGameItem.gameKindCd),
		);
		if (gameItems == null || gameItems.length == 0) {
			setGameItems(testlist);
		}
		setUserGameItems(res.data.object);
	};

	const onSelectedLareaAddr = (selectedValues) => {
		setLareaAddr(selectedValues);
	};
	const onSelectGameItemChange = (selectedValues) => {
		setGameItems(selectedValues);
	};
	const clickDaumAddr = () => {
		setIsOpen(!isOpen); //추가
	};

	const completeDaumAddr = (data: any) => {
		// setZipcode(data.zonecode);
		setUserAddr(data.roadAddress);
		setIsOpen(false); //추가
		if (data.roadAddress !== null) {
			setCheckMap({ ...checkMap, userAddr: true });
		}
	};

	const [checkMap, setCheckMap] = useState({
		userEmail: true,
		userNickname: true,
		userPhone: true,
		userProfileFileId: true,
		userAddr: true,
		userDtlAddr: true,
	});

	const handleUserNickname = (e) => {
		setUserNickname(e.target.value);
	};
	const handleProfile = (e) => {
		setProfile(e.target.files);
		sendFile(e.target.files);
	};
	const handleUserAddr = (e) => {
		setCheckMap({ ...checkMap, userAddr: false });
		setUserAddr(e.target.value);
	};
	const handleUserDtlAddr = (e) => {
		setUserDtlAddr(e.target.value);
		if (e.target.value !== null) {
			setCheckMap({ ...checkMap, userDtlAddr: true });
		}
	};
	const handlerUserStateDivs = (e) => {
		const newState = e.target.value;
		setUserStateDivsCd(newState);
	}

	const checkNickname = () => {
		const nicknameRegEx = /^[가-힣a-zA-Z0-9]+$/;
		const nicknameRegEx2 = /^[0-9]+$/;
		setCheckMap({ ...checkMap, userNickname: false });

		if (userNickname.length < 2) {
			alert('두글자 이상 입력바랍니다.');
			return false;
		} else if (userNickname.substring(0, 1).match(nicknameRegEx2) !== null) {
			alert('닉네임은 영어 혹은 한글로 시작해야 합니다.');
			return false;
		} else if (userNickname.match(nicknameRegEx) === null) {
			alert('닉네임은 영어, 숫자, 한글만 사용 가능합니다.');
			return false;
		}

		return true;
	};

	const sendFile = (file) => {
		if (
			!'image/png'.match(file[0].type) &&
			!'image/jpeg'.match(file[0].type) &&
			!'image/jpg'.match(file[0].type)
		) {
			alert('.png, .jpeg, .jpg 파일만 업로드 가능합니다.');
			return false;
		}
		fn_post_fileUpload_axios(file, fileTestCallBack, null);
	};
	const fileTestCallBack = (res) => {
		if (res.data.status) {
			alert('파일이 정상적으로 업로드 되었습니다.');
			setProfileImage(res.data.object[0].atchFileUrl);
			setUserProfileFileId(res.data.object[0].atchFileId);
			setCheckMap({ ...checkMap, userProfileFileId: true });
		} else {
			alert('파일 업로드에 실했습니다.');
			setCheckMap({ ...checkMap, userProfileFileId: false });
		}
	};

	const onCheckNickname = () => {
		if (!checkNickname()) {
			return;
		}
		const param = {
			method: 'GET',
			url: `${API.BACKEND}/${API.VERSION}/admin/checkNickname`,
			params: { userNickname: userNickname },
		};
		fn_axios(param, callBackOnCheckNickname, null);
	};

	const callBackOnCheckNickname = (res) => {
		if (!res.data.status) {
			alert(res.data.message);
			setCheckMap({ ...checkMap, userNickname: false });
		} else {
			alert(res.data.message);
			setCheckMap({ ...checkMap, userNickname: true });
		}
	};

	const isUserInfoChange = () => {
		return (
			userNickname !== originalUserInfo?.userNickname ||
			userPhone !== originalUserInfo?.userPhone ||
			userAddr !== originalUserInfo?.userAddr ||
			userDtlAddr !== originalUserInfo?.userDtlAddr ||
			userBirth !== originalUserInfo?.userBirth ||
			userGndr !== originalUserInfo?.userGndr
		);
	};
	const clickUpdate = () => {
		// if (!isUserInfoChange()) {
		//     alert("변경된 내용이 없습니다.");
		//     return;
		// }

		// Validation Check
		if (!userNickname) {
			alert('닉네임을 입력해주세요.');
			return;
		}
		if (!checkMap.userNickname) {
			alert('닉네임이 올바르게 입력되지 않았습니다.');
			return;
		}
		if (!userPhone) {
			alert('휴대폰 번호를 입력해주세요.');
			return;
		}
		if (!checkMap.userPhone) {
			alert('전화 인증이 완료되지 않았습니다.');
			return;
		}
		if (!userAddr) {
			alert('주소를 입력해주세요.');
			return;
		}
		if (!checkMap.userAddr) {
			alert('잘못된 주소입니다.');
			return;
		}
		if (!userDtlAddr) {
			alert('상세 주소를 입력해주세요.');
			return;
		}
		if (lareaAddr == null || lareaAddr.length == 0) {
			alert('관심지역을 선택해주세요.');
			return;
		}
		if (gameItems === null || gameItems.length === 0) {
			alert('하나 이상의 관심 종목을 선택해주세요.');
			return;
		}

		const param = {
			method: 'POST',
			url: `${API.BACKEND}/${API.VERSION}/admin/adminUserUpdate`,
			params: {
				userNickname: userNickname,
				userId: userId,
				userProfileFileId: userProfileFileId,
				userPhone: userPhone,
				// , userGndr : userGndr
				// userAddr: userAddr,
				// userDtlAddr: userDtlAddr,
				// lareaAddrList: lareaAddr,
				// gameItems: gameItems,
				// // , userNm : userName
				// , userBirth : userBirth
				userStateDivsCd: userStateDivsCd
			},
		};
		fn_axios(param, callBackUpdate, null);
		console.log(param);
	};
	const callBackUpdate = (res) => {
		alert(res.data.message);
		if (res.data.status) {
			window.location.href = '/admin/userMng';
		}
	};

	const backMain = () => {
		window.location.href = '/admin/userMng';
	};

	useEffect(() => {
		getUserDetail();
		getUSerItrsItem();
		getUserItrstLarea();
		getUserAuthList();
	}, []);

	return (
		<AdminLyout>
			<div className="admin_detail_contents">
				<h1 className="admin_title">사용자 상세 화면</h1>
				<section className="admin_user_info">
					<div className="admin_sub_title">사용자 정보</div>
					<div className="admin_user_info">
						<div className="admin_form">
							<div className="id">
								<input
									type="text"
									value={userEmail}
									readOnly={true}
									placeholder="이메일"
								/>
							</div>
							<div className="nickname">
								<input
									type="text"
									value={userNickname}
									onChange={handleUserNickname}
									placeholder="닉네임"
								/>
							</div>
						</div>
						<label
							htmlFor="user_nickname"
							className="checkRedu"
							onClick={onCheckNickname}
						>
							중복확인
						</label>
					</div>
				</section>
				<section className="admin_user_pro_info">
					<div className="admin_sub_title">프로필 정보</div>
					<div className="admin_user_pro">
						<dl className="amdin_user_photo">
							<dt>
								<img
									src={profileImage}
									alt="profile"
								/>
							</dt>
							<dd>
								<b>프로필사진</b>
								<br />
								가로크기 : 1,000 픽셀 <br />
								세로크기 : 1,000 픽셀 <br />
								파일형식 : JPG / PNG
								<label htmlFor="file">
									<div className="admin_profile_btn"> 이미지 파일 등록</div>
								</label>
								<React.Fragment>
									<input
										type="file"
										id="file"
										onChange={handleProfile}
										style={{ display: 'none' }}
										multiple={true}
									/>
								</React.Fragment>
							</dd>
						</dl>
						<br />
						<div className="admin_form">
							<span>성별 : </span>
							<input
								type="radio"
								name="gender"
								value="MAN"
								checked={userGndr == 'MAN' ? true : false}
								required
							/>
							<label htmlFor="gender_m">남</label>
							<input
								type="radio"
								name="gender"
								value="WOMAN"
								checked={userGndr == 'WOMAN' ? true : false}
								required
							/>
							<label htmlFor="gender_f">여</label>
						</div>
					</div>
					<div className="admin_form">
						<input
							type="text"
							name="name"
							value={userName}
							readOnly
							placeholder="이름"
						/>
					</div>
					<div className="admin_form">
						<input
							type="date"
							name="user_birthday"
							value={userBirth}
							readOnly
							required
							aria-required="true"
							data-placeholder="생년월일"
							max="9999-12-31"
						/>
					</div>
					<div className="admin_form">
						<input
							type="text"
							value={userPhone}
							readOnly
							placeholder="휴대전화"
						/>
					</div>
					<div className="admin_form address_form">
						<input
							type="text"
							name="address"
							value={userAddr}
							onChange={handleUserAddr}
							readOnly={true}
							placeholder="주소"
						/>
						<label
							htmlFor="address1"
							className="address_label"
							onClick={clickDaumAddr}
						>
							주소찾기
						</label>
						<Modal
							isOpen={isOpen}
							ariaHideApp={false}
						>
							<DaumPostcode onComplete={completeDaumAddr} />
						</Modal>
						<br />
						<br />
						<input
							type="text"
							name="userDtlAddr"
							value={userDtlAddr}
							onChange={handleUserDtlAddr}
							placeholder="상세주소"
						/>
					</div>
					<div className='admin_form'>
						<label htmlFor='accountStatus'>계정 상태 : </label>
						<select
							value={userStateDivsCd}
							onChange={handlerUserStateDivs}
						>
							{userStateDivsList.map((option) => (
								<option
									key={option.value}
									value={option.value}
								>
									{option.label}
								</option>
							))}
						</select>
					</div>
				</section>
				<section
					className="admin_form_event"
					defaultValue={''}
				>
					<div className="admin_sub_title">관심지역 및 종목</div>
					<div className="admin_area">
						{lareaAddr != null ? (
							<LareaAddr
								onSelectedLareaAddr={onSelectedLareaAddr}
								userSelectLareaAddr={lareaAddr}
							></LareaAddr>
						) : null}
						<div className="admin_event">
							{gameItems != null ? (
								<GameItemProvider
									onSelectedValuesChange={onSelectGameItemChange}
									userSelectItem={gameItems}
								></GameItemProvider>
							) : null}
						</div>
					</div>
				</section>
				{/*
                 사용자가 가지고 있는 권한 리스트 
                 권한종류, 승인상태, 권한시작일, 권한종료일
                 해당 권한을 선택하면 권한관리 상세 화면으로 이동
                 */}
				<section className="admin_user_auth_list">
					<div className="admin_sub_title">사용자 권한 목록</div>
					<div
						className="admin_table type1"
						style={{ overflow: 'auto' }}
					>
						<table
							style={{
								borderCollapse: 'collapse',
								width: '100%',
								tableLayout: 'auto',
							}}
						>
							<thead>
								<tr>
									<th>권한종류</th>
									<th>승인상태</th>
									<th>권한시작일</th>
									<th>권한종료일</th>
								</tr>
							</thead>
							<tbody>
								{userAuthList.length === 0 ? (
									<tr>
										<td
											style={{ textAlign: 'center' }}
											colSpan={4}
										>
											권한이 없습니다.
										</td>
									</tr>
								) : (
									userAuthList.map((item, index) => (
										<tr style={{ cursor: 'pointer' }}>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.authKindCd}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.apprvStateCd}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.authStrDt}
											</td>
											<td style={{ whiteSpace: 'nowrap', paddingLeft: '5px' }}>
												{item.authEndDt}
											</td>
										</tr>
									))
								)}
							</tbody>
						</table>
					</div>
				</section>
				<div className="admin_detail_buttons">
					<button
						className="btn back"
						onClick={backMain}
					>
						목록으로
					</button>
					<button
						className="btn update"
						onClick={clickUpdate}
					>
						회원정보수정
					</button>
				</div>
			</div>
		</AdminLyout>
	);
}

export default UserManageDetail;
