import React from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import MasterSearch from '../../../common/MasterSearch/MasterSearch.tsx';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';
import data from './dummy.json';
import styles from './MngCmptLList.module.css';
import Checkbox from '../../../common/Checkbox/Checkbox.tsx';
import Button from '../../../common/Button/Button.tsx';
import Pagination from '../../../common/Pagination/Pagination.tsx';

const MngCmptLList = () => {
	return (
		<div>
			<MyCompetitionHeader title="대회관리" />
			<MasterSearch />
			<Table>
				<TableHeader>
					<TableRow>
						<TableHead className={styles.check_box}>
							<Checkbox />
						</TableHead>
						<TableHead className={styles.number}>No.</TableHead>
						<TableHead className={styles.title}>마스터 대회명</TableHead>
						<TableHead className={styles.date}>대회기간</TableHead>
						<TableHead className={styles.date}>접수기간</TableHead>
						<TableHead className={styles.location}>대회장소</TableHead>
						<TableHead className={styles.status}>상태</TableHead>
						<TableHead className={styles.participants}>모집인원</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody className={styles.table_body}>
					{data.object.map((item) => (
						<TableRow className={styles.table_row}>
							<TableCell className={styles.check_box}>
								<Checkbox />
							</TableCell>
							<TableCell className={styles.number}>{item.number}</TableCell>
							<TableCell className={styles.title}>{item.title}</TableCell>
							<TableCell className={styles.date}>
								{`${item.start_date} ~ ${item.end_date}`}
							</TableCell>
							<TableCell className={styles.date}>
								{`${item.start_date} ~ ${item.end_date}`}
							</TableCell>
							<TableCell className={styles.location}>{item.location}</TableCell>
							<TableCell className={styles.status}>{item.status}</TableCell>
							<TableCell className={styles.participants}>
								{item.participants ?? '-'}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<div className={styles.table_footer}>
				<Button
					weight="bold"
					disabled
				>
					선택항목 삭제
				</Button>
				<Pagination />
				<div className={styles.blank_div}></div>
			</div>
		</div>
	);
};

export default MngCmptLList;
