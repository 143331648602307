import React from 'react';
import Input from '../Input/Input.tsx';
import Button from '../Button/Button.tsx';
import styles from './AssociationSearch.module.css';
import excelIcon from '../../assets/images/common/excel.png';

interface AssociationSearchProps {
	onClickSaveButton?: () => void;
}

const AssociationSearch = ({ onClickSaveButton }: AssociationSearchProps) => {
	return (
		<div className={styles.search_box}>
			<select
				name=""
				id=""
				className={styles.filter_select}
			>
				<option value="1">전체유형</option>
			</select>
			<Input
				type="text"
				placeholder="이름 검색"
				className={styles.filter_search_input}
			/>
			<Button
				weight="bold"
				className={styles.search_button}
			>
				검색
			</Button>
			<button
				className={styles.save_button}
				onClick={onClickSaveButton}
			>
				현재 리스트 엑셀파일로 저장
				<img
					src={excelIcon}
					alt="excel"
					className={styles.save_button_icon}
				/>
			</button>
			<button className={styles.register_button}>
				기관/단체/업체 등록하기
			</button>
		</div>
	);
};

export default AssociationSearch;
