import React from 'react';
import MyCompetitionHeader from '../../../common/MyCompetitionHeader/MyCompetitionHeader.tsx';
import MasterSearch from '../../../common/MasterSearch/MasterSearch.tsx';
import styles from './MngCmptMContent.module.css';
import {
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '../../../common/Table/Table.tsx';

import data from './dummy.json';
import Button from '../../../common/Button/Button.tsx';
import Pagination from '../../../common/Pagination/Pagination.tsx';
const MngCmptMContent = () => {
	return (
		<div>
			<MyCompetitionHeader title="대회마스터" />
			<MasterSearch />
			<div className={styles.container}>
				<div className={styles.left_section}>
					<h1>대회마스터 내용</h1>
					<p>
						대회등록을 위해서는 <br />
						대회마스터가 반드시 사전에
						<br /> 등록되어 있어야 합니다. <br />
						<br />
						등록된 대회마스터가 없으신
						<br />
						회원님은 대회마스터를
						<br />
						먼저 등록해주시기 바랍니다.
					</p>
				</div>
				<div className={styles.right_section}>
					<div className={styles.back_button_container}>
						<div className={styles.back_button}>
							<p>목록으로 돌아가기</p>
							<MenuIcon />
						</div>
					</div>
					<div className={styles.competition_info}>
						<p className={styles.competition_views}>2024.03.12 / 171회 적용</p>
						<div className={styles.competition_info_item}>
							<div className={styles.competition_info_type}>대회명</div>
							<div className={styles.competition_info_value}>
								: 제31회 국민생활체육전국배드민턴대회
							</div>
						</div>
						<div className={styles.competition_info_item}>
							<div className={styles.competition_info_type}>경기종목</div>
							<div className={styles.competition_info_value}>: 배드민턴</div>
						</div>
						<div className={styles.competition_info_item}>
							<div className={styles.competition_info_type}>주요 개최지역</div>
							<div className={styles.competition_info_value}>
								: 경상북도 경산시 일원
							</div>
						</div>
						<div className={styles.competition_info_item}>
							<div className={styles.competition_info_type}>대회 URL</div>
							<div className={styles.competition_info_value}>
								: www.korbadminton.or.kr
							</div>
						</div>
					</div>
					<Table>
						<TableHeader>
							<TableRow>
								<TableHead className={styles.competition_date}>
									개최년일
								</TableHead>
								<TableHead className={styles.competition_name}>
									대회명
								</TableHead>
								<TableHead className={styles.competition_people}>
									참가인원
								</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{data.object.map((item) => (
								<TableRow>
									<TableCell className={styles.competition_date}>
										{item.date}
									</TableCell>
									<TableCell className={styles.competition_name}>
										{item.name}
									</TableCell>
									<TableCell className={styles.competition_people}>
										{item.people}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<div className={styles.competition_table_footer}>
						<div className={styles.competition_table_footer_button_group}>
							<Button weight="bold">수정하기</Button>
							<Button weight="bold">삭제하기</Button>
						</div>
						<Pagination />
						<div className={styles.blank_div}></div>
					</div>
				</div>
			</div>
		</div>
	);
};

function MenuIcon() {
	return (
		<svg
			width="19"
			height="17"
			viewBox="0 0 19 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="19"
				height="3"
				fill="#777777"
			/>
			<rect
				y="7"
				width="19"
				height="3"
				fill="#777777"
			/>
			<rect
				y="14"
				width="19"
				height="3"
				fill="#777777"
			/>
		</svg>
	);
}

export default MngCmptMContent;
