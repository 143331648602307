import React, { useRef, useState } from 'react';
import styles from './FileInput.module.css';
import clsx from 'clsx';

interface FileInputProps {
	className?: string;
	fileName?: string;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const FileInput = ({
	className,
	fileName: defaultFileName,
	onChange,
}: FileInputProps) => {
	const ref = useRef<HTMLInputElement>(null);
	const [fileName, setFileName] = useState<string>(defaultFileName ?? '');

	return (
		<div
			className={clsx(styles.file_input_container, className)}
			onClick={() => {
				ref.current?.click();
			}}
		>
			<div className={styles.file_input}>{fileName}</div>
			<button className={styles.file_input_button}>파일찾기</button>
			<input
				type="file"
				className={styles.real_input}
				ref={ref}
				onChange={(e) => {
					onChange?.(e);
					if (e.target.files && e.target.files.length > 0) {
						setFileName(e.target.files[0].name);
					}
				}}
			/>
		</div>
	);
};

export default FileInput;
