import React, { useState, useEffect, useRef } from 'react';
import AdminLayout from '../../layout/_admin/AdminLayout.tsx';
import { useLocation } from 'react-router-dom';
import { API } from '../../config/config.tsx';
import { fn_axios } from '../../common/common.tsx';

function CodeManageUpdate() {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const codeMId = searchParams.get('codeMCd');

	//form
	const [codeInfo, setCodeInfo] = useState(null);
	const [codeMCd, setCodeMCd] = useState('');
	const [codeMNm, setCodeMNm] = useState('');
	const [codeMDesc, setCodeMDesc] = useState('');

	const getCodeDetail = () => {
		const param = {
			method: 'GET',
			url: `${API.BACKEND}/${API.VERSION}/admin/getCodeM`,
			params: { codeMCd: codeMId },
		};
		fn_axios(param, callBackCodeM, null);
	};

	const callBackCodeM = (res) => {
		const codeVo = res.data.object;
		setCodeMCd(codeVo.codeMCd);
		setCodeMNm(codeVo.codeMNm);
		setCodeMDesc(codeVo.codeMDesc);
		setCodeInfo(codeVo);
	};

	// 입력 값
	const handleCodeMCd = (e) => {
		setCodeMCd(e.target.value);
	};
	const handleCodeMNm = (e) => {
		setCodeMNm(e.target.value);
	};
	const handleCodeMDesc = (e) => {
		setCodeMDesc(e.target.value);
	};

	const clickUpdate = () => {
		if (!codeMNm) {
			alert('코드기본명을 입력해주세요.');
			return;
		}
		if (!codeMDesc) {
			alert('코드기본설명을 입력해주세요');
			return;
		}

		const param = {
			method: 'POST',
			url: `${API.BACKEND}/${API.VERSION}/admin/getCodeMUpdate`,
			params: {
				codeMCd: codeMId,
				codeMNm: codeMNm,
				codeMDesc: codeMDesc,
			},
		};
		fn_axios(param, callBackCodeMUpdate, null);
	};

	const callBackCodeMUpdate = (res) => {
		console.log(res);
		if (res.status === 200) {
			alert('정상적으로 수정 되었습니다.');
			window.location.href = '/admin/codeMng';
		}
	};

	const backMain = () => {
		window.location.href = '/admin/codeMng';
	};

	useEffect(() => {
		getCodeDetail();
	}, []);

	return (
		<AdminLayout>
			<div className="admin_detail_contents">
				<h1>코드 수정 화면</h1>
				<section className="admin_user_info">
					<div className="admin_user_info">
						<div className="admin_form">
							<span>코드기본CD : </span>
							<input
								type="text"
								value={codeMCd}
								onChange={handleCodeMCd}
							/>
							<span>코드기본명 : </span>
							<input
								type="text"
								value={codeMNm}
								onChange={handleCodeMNm}
							/>
							<span>코드기본설명 : </span>
							<input
								type="text"
								value={codeMDesc}
								onChange={handleCodeMDesc}
							/>
						</div>
					</div>
				</section>
				<div className="admin_detail_buttons">
					<button
						className="btn back"
						onClick={backMain}
					>
						목록으로
					</button>
					<button
						className="btn update"
						onClick={clickUpdate}
					>
						기본 코드 수정
					</button>
				</div>
			</div>
		</AdminLayout>
	);
}

export default CodeManageUpdate;
