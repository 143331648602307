import React from 'react';

function ArrowLeft(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="7"
			height="12"
			viewBox="0 0 7 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M0.59375 6L6.59375 0.803848L6.59375 11.1962L0.59375 6Z"
				fill={props.fill || '#111111'}
			/>
		</svg>
	);
}

export default ArrowLeft;
