import React, { useState, useEffect, useRef } from 'react';
import AdminLayout from '../../layout/_admin/AdminLayout.tsx';
import { useLocation } from 'react-router-dom';
import { API } from '../../config/config.tsx';
import { fn_axios, getCookieUserId } from '../../common/common.tsx';

function CodeManageDetailUpdate() {
	const userId = getCookieUserId();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const codeMId = searchParams.get('codeMCd');
	const codeLId = searchParams.get('codeLCd');

	const [codeInfo, setCodeInfo] = useState(null);
	const [codeLCd, setCodeLCd] = useState('');
	const [codeLNm, setCodeLNm] = useState('');
	const [codeLDesc, setCodeLDesc] = useState('');

	const getCodeDetail = () => {
		const param = {
			method: 'GET',
			url: `${API.BACKEND}/${API.VERSION}/admin/getCodeL`,
			params: { codeMCd: codeMId, codeLCd: codeLId },
		};
		fn_axios(param, callBackCodeL, null);
	};
	const callBackCodeL = (res) => {
		const codeVo = res.data.object;
		setCodeLCd(codeVo.codeLCd);
		setCodeLNm(codeVo.codeLNm);
		setCodeLDesc(codeVo.codeLDesc);
		setCodeInfo(codeVo);
	};

	const handleCodeLCd = (e) => {
		setCodeLCd(e.target.value);
	};
	const handleCodeLNm = (e) => {
		setCodeLNm(e.target.value);
	};
	const handleCodeLDesc = (e) => {
		setCodeLDesc(e.target.value);
	};

	const clickUpdate = () => {
		if (!codeLCd) {
			alert('코드내역CD를 입력해주세요.');
			return;
		}
		if (!codeLNm) {
			alert('코드내역명을 입력해주세요.');
			return;
		}
		if (!codeLDesc) {
			alert('코드내역설명을 입력해주세요.');
			return;
		}

		const param = {
			method: 'POST',
			url: `${API.BACKEND}/${API.VERSION}/admin/getUpdateCodeL`,
			params: {
				codeMCd: codeMId,
				codeLCd: codeLCd,
				codeLNm: codeLNm,
				codeLDesc: codeLDesc,
				userId: userId,
			},
		};
		fn_axios(param, callBackCodeLUpdate, null);
	};
	const callBackCodeLUpdate = (res) => {
		console.log(res);
		if (res.status === 200) {
			alert('정상적으로 수정 되었습니다.');
			window.location.href = `/admin/codeMngDetail?codeMCd=${codeMId}`;
		}
	};

	const backMain = () => {
		window.location.href = `/admin/codeMngDetail?codeMCd=${codeMId}`;
	};

	useEffect(() => {
		getCodeDetail();
	}, []);

	return (
		<AdminLayout>
			<div className="admin_detail_contents">
				<h1>코드 내역 수정 화면</h1>
				<section className="admin_user_info">
					<div className="admin_user_info">
						<div className="admin_form">
							<span>코드기본CD</span>
							<input
								type="text"
								value={codeMId || ''}
								readOnly
							/>
							<span>코드내역CD</span>
							<input
								type="text"
								value={codeLCd}
								readOnly
							/>
							<span>코드내역명</span>
							<input
								type="text"
								value={codeLNm}
								onChange={handleCodeLNm}
							/>
							<span>코드내역설명</span>
							<input
								type="text"
								value={codeLDesc}
								onChange={handleCodeLDesc}
							/>
						</div>
					</div>
				</section>
				<div className="admin_detail_buttons">
					<button
						className="btn back"
						onClick={backMain}
					>
						목록으로
					</button>
					<button
						className="btn update"
						onClick={clickUpdate}
					>
						코드 내역 수정
					</button>
				</div>
			</div>
		</AdminLayout>
	);
}

export default CodeManageDetailUpdate;
